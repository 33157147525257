<script>
export default {
  props: {
    value: Array,
    items: Array,
    allow_edit: Boolean,
  },
  data() {
    return {
      selected_labels: this.value || [],
      edit_dialog: false,
      create_new: false,
      edit_label: false,
      errors: [],
      new_label: { name: "", color: this.randomColor() }
    }
  },
  watch: {
    value(newValue) {
      this.selected_labels = newValue
    }
  },
  computed: {
    selected_labels_for_checkboxes: {
      get() {
        if (!this.selected_labels) return []
        return this.selected_labels.map(label => (label.name))
      },
      set(new_labels) {
        const result = []
        new_labels.forEach(label_name => {
          let old_label = this.selected_labels?.find(label => (label.name === label_name))

          if(old_label) {
            result.push(old_label)
          } else {
            let new_label = this.labels.find(label => (label_name === label.name))
            result.push({label_id: new_label.id, color: new_label.color, name: new_label.name})
          }
        });

        this.selected_labels = result
        this.onChange()
      }
    },
    labels() {
      return this.items.filter(l => !l.deleted)
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.selected_labels)
    },
    async addNewLabel() {
      try {
        const response = await this.axios.post(`/admin/labels`, this.new_label)
        this.new_label = response.data

        this.labels.push(this.new_label)

        this.create_new = false
        this.new_label = { name: "", color: this.randomColor() }
      } catch (error) {
        this.errors = error.response?.data
      }
    },
    async updateLabel() {
      try {
        const response = await this.axios.patch(`/admin/labels/${this.new_label.id}`, this.new_label)
        this.new_label = response.data

        let idx = this.selected_labels.findIndex((l) => (l.label_id === this.new_label.id))
        if(idx !== -1) {
          this.selected_labels[idx].color = this.new_label.color
          this.selected_labels[idx].name = this.new_label.name
          this.selected_labels[idx].updated = true
        }

        idx = this.labels.findIndex((l) => (l.id === this.new_label.id))

        this.labels[idx].color = this.new_label.color
        this.labels[idx].name = this.new_label.name

        this.edit_label = false
        this.selected_labels

        this.new_label = { name: "", color: this.randomColor() }
      } catch(error) {
        this.errors = error.response?.data
      }
    },
    randomColor() {
      return Math.floor(Math.random() * 0xFFFFFF).toString(16);
    },
    removeLabel (item) {
      let idx = this.selected_labels.indexOf(item)
      this.selected_labels.splice(idx, 1)
    },
    async deleteLabel(label) {
      try {
        await this.axios.delete(`/admin/labels/${label.id}`)
        this.$emit('deleteLabel', label)
        // Force a reload of selected labels
        this.selected_labels.splice(0, 0)
      } catch (error) {
        console.log(error)
      }
    },
    edit(label) {
      this.edit_label = true
      this.new_label = label
    }
  },    
}
</script>

<template lang="pug">
div
  div.d-flex
    h2.align-self-center {{ $t('work_items.work_item_labels')}}
    v-btn.ml-2.align-self-center(@click="edit_dialog = true" elevation="1" x-small fab)
      v-icon mdi-plus

  div.d-flex.flex-wrap
    div(v-for="label in selected_labels")
      v-chip.mr-1.mt-1(@click="removeLabel(label)" @click:close="removeLabel(label)" :color="label.color" dark close)
        | {{ label.name }}
  v-dialog(v-if="edit_dialog" v-model="edit_dialog" width="400px")
    v-card#edit_labels_dialog(v-if="!create_new && !edit_label")
      v-card-title.headline.grey.lighten-2
        | {{ $t('work_items.work_item_labels')}} 
      v-card-text
        div.d-flex(v-for="label in labels")
          v-checkbox(v-model="selected_labels_for_checkboxes" :value="label.name")
            template(v-slot:label)
              v-chip(:color="label.color" dark)
                | {{ label.name }}
          v-btn.ml-2(v-if="allow_edit" @click="edit(label)" elevation="1" x-small fab)
            v-icon mdi-pencil
          v-btn.ml-2(v-if="allow_edit" @click="deleteLabel(label)" elevation="1" color="red" x-small fab dark)
            v-icon mdi-trash-can
            
        v-btn(v-if="allow_edit" color='primary' @click="create_new = true")
          | {{ $t('general.add_new') }}
      v-card-actions
        v-btn(color='primary' @click="edit_dialog = false")
          | {{ $t('general.save') }}
    v-card#edit_labels_dialog(v-else)
      v-card-title.headline.grey.lighten-2
        | {{ $t('work_items.new_label') }}

      v-card-text
        v-text-field(v-model="new_label.name" id="label_name" :label="$t('activerecord.attributes.label.name')" :errorMessages="this.errors['name']")
        | {{ $t('activerecord.attributes.label.color') }}
        v-color-picker(v-model="new_label.color" :errorMessages="this.errors['color']" hide-inputs)

      v-card-actions
        v-btn(v-if="edit_label" color='primary' @click="updateLabel")
          | {{ $t('general.save') }}
        v-btn(v-else="edit_label" color='primary' @click="addNewLabel")
          | {{ $t('general.save') }}
        v-btn(color='gray' @click="edit_label = false; create_new = false")
          | {{ $t('general.back') }}
        v-spacer
        v-btn(color='gray' @click="edit_dialog = false")
          | {{ $t('general.close') }}
</template>
