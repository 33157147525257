<script>
import DialogShow from './show.vue'
import Form from './form.vue'

export default {
  components: {
    DialogShow,
    Form
  },
  props: {
    value: Object,
    tags: Array,
    is_admin: Boolean
  },
  data() {
    return {
      show_dialog: false,
      show_dialog_edit: false,
      product: this.value,
      show_full_description: true,
    }
  },
  methods:{
    async delete_product(){
      if(confirm(this.$t('marketplace.confirmation'))) {
        await this.axios.delete(`/admin/marketplace_products/${this.product.id}`)
        this.$emit('remove_product', this.product)
      }
    },
    async order_product(){
      if(confirm(this.$t('marketplace.confirmation'))) {
        await this.axios.post(`/marketplace_products/${this.product.id}/order`)
      }
    }
  },
  watch: {
    product(product) {
      this.show_dialog_edit = false
      this.$emit('input', product)
      this.$emit('change_tags', product.tags)
    }
  },
}
</script>

<template lang="pug">
  v-card(class="mx-auto" max-width="344")
    div(@click="show_dialog = true")
      v-img(:src="product.image_url" height="200px")
      v-card-title {{product.title}}

    v-card-text( class="pb-0" @click="show_full_description = !show_full_description" :class="show_full_description ? 'short-description' : 'long-description'") {{product.card_description}}

    v-card-actions
      v-btn(color="light-blue" text @click="order_product") {{ $t("marketplace.add_now") }}
      v-spacer
      v-btn(icon @click="show_dialog_edit = true" v-if="is_admin")
        v-icon mdi-pencil
      v-btn(icon @click="delete_product" v-if="is_admin")
        v-icon mdi-delete

    v-dialog(v-model="show_dialog" max-width="700px")
      DialogShow(:product="product" @closeDialog="show_dialog = false")
    v-dialog(v-model="show_dialog_edit" width="800" height="1000px")
      Form(v-model="product" type="edit", :tags="tags")
</template>
