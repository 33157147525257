<script>
import FallLease from './fall_lease.vue'
import FeedInTariff from './feed_in_tariff.vue'
import Hedging from './hedging.vue'
import SelfConsumption from './self_consumption.vue'
import VDatePickerInput from '../../components/v_date_picker_input_enhanced.vue'
import CounterpartDialog from '../fall_leases/counterpart.vue'

const FALL_LEASE = "Contract::FallLease"
const FEED_IN_TARIFF = "Contract::FeedInTariff"
const HEDGING = "Contract::Hedging"
const SELF_CONSUMPTION = "Contract::SelfConsumption"

export default {
  components: {
    FallLease,
    FeedInTariff,
    Hedging,
    SelfConsumption,
    VDatePickerInput,
    CounterpartDialog
  },
  props: {
    value: Object,
    contractTypes: Array,
    powerPlantOptions: Array,

    fallLeaseOptions: Object,
    hedgingOptions: Object,
    feedInTariffOptions: Object,
    SelfConsumptionOptions: Object,
  },
  created() {
    const params = new URLSearchParams(window.location.search)
    const power_plant_id = params.get("power_plant");
    if (power_plant_id !== null && !isNaN(power_plant_id)) {
      this.power_plant_id = parseInt(power_plant_id);
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search)
    if(!this.value.id) {
      this.updateContractType(params.get("type"))

      this.contract.active = true

      const power_plant_id = params.get("power_plant");
      if (power_plant_id !== null && !isNaN(power_plant_id))
        this.contract.power_plant_id = parseInt(power_plant_id);

      const start_date = params.get("start_date");
      if (start_date !== null)
        this.$set(this.contract, 'start_date', start_date);

      const end_date = params.get("end_date");
      if (end_date !== null)
        this.$set(this.contract, 'end_date', end_date);
    }

    this.backOverload = params.has("back") ? params.get("back") : null;
  },
  data() {
    return {
      FALL_LEASE: FALL_LEASE,
      HEDGING: HEDGING,
      FEED_IN_TARIFF: FEED_IN_TARIFF,
      SELF_CONSUMPTION: SELF_CONSUMPTION,

      contract: { ...this.value, fall_lease_landowners: this.value.fall_lease_landowners || [] },
      backOverload: null,
      errorMessages: [],
      saveSuccessful: null,
      contract_years_duration: null,
      power_plant_id: null,
      compute_all: false,
      counterpartDialog: false,
      saving: false,
    }
  },
  computed: {
    returnUrl() {
      return this.backOverload ? this.backOverload : '/admin/contracts'
    },
    fallLeaseLandowners() {
      if (this.contract.fall_lease_landowners === undefined)
        return ''

      return this.contract.fall_lease_landowners.filter(l => !l._destroy).map(l => ({text: (this.fallLeaseOptions.landowners.find(u => u.value == l.user_id)?.text || "User not found..") + `(${l.share}%)`, value: l.user_id }))
    },
    landownersTotalShare() {
      if (this.contract.fall_lease_landowners === undefined)
        return 0

      return this.contract.fall_lease_landowners.filter(l => !l._destroy).map(l => l.share).reduce((a, b) => a + b, 0)
    }
  },
  methods: {
    build_submit_object() {
      let contract = {}

      if (this.contract.id !== null)
        contract.id = this.contract.id

      contract.type = this.contract.type
      contract.start_date = this.contract.start_date
      contract.end_date = this.contract.end_date
      contract.active = this.contract.active
      contract.counterpart = this.contract.counterpart
      contract.power_plant_ids = this.contract.type === FALL_LEASE ? [this.contract.power_plant_id] : this.contract.power_plant_ids
      contract.open_ended = this.contract.type === FALL_LEASE ? false : this.contract.open_ended

      switch (contract.type) {
        case FALL_LEASE:
          contract.fall_lease_attributes = this.contract.fall_lease
          contract.fall_lease_landowners_attributes = this.contract.fall_lease_landowners.filter(l => l.new || (l.id && l._destroy))
          break;
        case FEED_IN_TARIFF:
          contract.feed_in_tariff_data_attributes = this.contract.feed_in_tariff_data
          break;
        case HEDGING:
          contract.hedging_data_attributes = this.contract.hedging_data
          break;
        case SELF_CONSUMPTION:
          contract.self_consumption_data_attributes = this.contract.self_consumption_data
          break;
      }

      return { contract: contract }
    },
    async submit() {
      try {
        let result = null;

        this.saving = true

        if(!this.contract.id) {
          result = await this.axios.post("/admin/contracts", this.build_submit_object())
          if(this.contract.type === FALL_LEASE) {
            const ref_contract_id = result.data.id
            if (this.compute_all) {
              await this.axios.post(`/admin/fall_leases/${ref_contract_id}/compute_all`)
            }
            this.window.location = `/admin/fall_leases/${ref_contract_id}`
          } else {
            this.contract = result.data
          }
        }
        else {
          result = await this.axios.patch(`/admin/contracts/${this.contract.id}`, this.build_submit_object())
          this.contract = result.data
        }
        this.saveSuccessful = true
        this.errorMessages = []
      } catch(error) {
        console.log(error)
        this.saveSuccessful = false
        this.errorMessages = error.response.data || ["An unexpected error occurred."]
      }

      this.saving = false
    },
    async submit_compute() {
      this.compute_all = true
      await this.submit();
    },
    async fetchDefaultValuesForContractType(contractType) {
      let power_plant_id = this.contract.power_plant_id;
      if (power_plant_id === undefined) {
        power_plant_id = this.power_plant_id;
      }
      const response = await this.axios.get(
        `/admin/contracts/fetch_default_values?contract_type=${contractType}&power_plant_id=${power_plant_id}`
      )
      return response.data
    },
    async updateContractType(newType) {
      const data = await this.fetchDefaultValuesForContractType(newType)

      if(newType === FALL_LEASE)
        this.contract.open_ended = false

      switch(newType) {
        case FALL_LEASE:
          this.contract.fall_lease = data
          break
        case FEED_IN_TARIFF:
          this.contract.feed_in_tariff_data = {}
          break
        case HEDGING:
          this.contract.hedging_data = {}
          break
        case SELF_CONSUMPTION:
          this.contract.self_consumption_data = {
            currency_code: 'CHF',
            grid_injection_spot: false,
            grid_injection_price_mwh: 0.0,
            self_consumption_price_mwh: 0.0,
            generator_auxiliary_consumption_price_mwh: 0.0
          }
          break
      }

      this.contract = { ...this.contract, type: newType }
    },
    async updatePowerPlant(powerPlant) {
      switch(this.contract.type) {
        case FALL_LEASE:
          this.contract.fall_lease = await this.fetchDefaultValuesForContractType(this.contract.type)
          break
      }
    },
    sharedProps(field) {
      return {
        hideDetails: 'auto',
        label: this.$t(`activerecord.attributes.${field}`),
        class: "mb-2",
        readonly: false,
        errorMessages: this.errorMessages[field.replace("contract.", "")],
        filled: true,
      }
    },
    isEmpty(value) {
      return value === null || value === '' || value === undefined
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    addLandowner(landowner) {
      if(this.contract.fall_lease_landowners === undefined)
        this.contract.fall_lease_landowners = []

      this.contract.fall_lease_landowners.push({ new: true, _destroy: false, ...landowner })
      this.$set(this.contract, 'fall_lease_landowners', this.contract.fall_lease_landowners)
    },
    markFallLeaseLandownerForDestruction(user_id) {
      let index = this.contract.fall_lease_landowners.findIndex(l => l.user_id === user_id)

      this.contract.fall_lease_landowners[index]._destroy = true
      this.$set(this.contract, 'fall_lease_landowners', this.contract.fall_lease_landowners)
    }
  }
}

</script>

<template lang="pug">
  v-card
    v-card-title 
      | {{ this.$t('general.contract_details') }}
    v-card-text(id="main_contracts_form")
      v-alert(
        v-if="saveSuccessful === true"
        type="success"
        dense
        text
        dismissible
      )
        | {{ this.$t('general.save_successful') }}
      v-alert(
        v-if="errorMessages.length > 0"
        type="error"
        dense
        text
        dismissible
      )
        | {{ errorMessages.slice(0, 250) }}{{ errorMessages.length > 250 ? '...' : '' }}
      v-row
        v-col(cols="12" sm="6")
          v-select(:value="contract.type" @input="updateContractType" :items="contractTypes" v-bind="sharedProps('contract.type')" :disabled="contract.id != null")
          div(v-if="contract.type === FALL_LEASE")
            v-select.ignore-readonly-styles(
              @click.prevent="counterpartDialog = true"
              :value="fallLeaseLandowners"
              :items="fallLeaseLandowners"
              v-bind="sharedProps('contract.counterpart')"
              chips readonly multiple
            )
              // Add remove button
              template(v-slot:selection="{ item, index }")
                v-chip(
                  :key="index"
                  @click:close="markFallLeaseLandownerForDestruction(item.value)"
                  @click="markFallLeaseLandownerForDestruction(item.value)"
                  color="primary"
                  label close
                ) {{ item.text }}
            CounterpartDialog(
              v-model="counterpartDialog"
              :landowner-users="fallLeaseOptions.landowners"
              @add="addLandowner"
              :current-share="landownersTotalShare"
            )
          div(v-else)
            v-text-field(v-model="contract.counterpart" v-bind="sharedProps('contract.counterpart')")
          div(v-if="contract.type === FALL_LEASE")
            v-autocomplete(
              v-model="contract.power_plant_id"
              :items="powerPlantOptions"
              v-bind="sharedProps('contract.power_plant')"
              @input="updatePowerPlant"
            )
          div(v-else)
            v-autocomplete(v-model="contract.power_plant_ids" :items="powerPlantOptions" v-bind="sharedProps('contract.power_plants')" multiple)

        v-col(cols="12" sm="6")
          v-row
            v-col(v-if="contract.type !== FALL_LEASE")
              v-checkbox(v-model="contract.open_ended" v-bind="sharedProps('contract.open_ended')")
            v-col
              v-checkbox(name="contract_active" v-model="contract.active" v-bind="sharedProps('contract.active')")

          div(v-if="!contract.open_ended")
            v-date-picker-input(
              v-model="contract.start_date"
              :name="$t('activerecord.attributes.contract.start_date')"
              v-bind="sharedProps('contract.start_date')"
            )

            v-date-picker-input(
              v-model="contract.end_date"
              :name="$t('activerecord.attributes.contract.end_date')"
              v-bind="sharedProps('contract.end_date')"
            )


    div(v-if="contract.type")
      v-divider
      v-card-text(id="sub_contracts_form")
        div(v-if="contract.type === FALL_LEASE")
          FallLease(v-model="contract.fall_lease" :sharedPropsBase="sharedProps" v-bind="fallLeaseOptions")
        div(v-if="contract.type === FEED_IN_TARIFF")
          FeedInTariff(v-model="contract.feed_in_tariff_data" :sharedProps="sharedProps" v-bind="feedInTariffOptions")
        div(v-if="contract.type === HEDGING")
          Hedging(v-model="contract.hedging_data" :sharedProps="sharedProps" v-bind="hedgingOptions")
        div(v-if="contract.type === SELF_CONSUMPTION")
          SelfConsumption(v-model="contract.self_consumption_data" :sharedProps="sharedProps" v-bind="SelfConsumptionOptions")

    v-card-actions
      v-btn(:href="returnUrl") {{ this.$t('general.back') }}
      v-spacer
      v-btn(@click="submit" color="primary") 
        div(v-if="!saving") {{ this.$t('general.save') }}
        v-progress-circular(v-else indeterminate color="white")
      v-btn(v-if="contract.type === FALL_LEASE" @click="submit_compute" color="primary") {{ this.$t('fall_lease.save_and_compute') }}
</template>
