import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from './plugins/vuetify.js'
import I18n from './plugins/vue_i18n.js.erb'
import Toast from 'vue-toastification'
import Rails from '@rails/ujs'
import router from './router/index'

Rails.start()

import './plugins/axios'
import './plugins/highcharts'
import './plugins/windy'
import './plugins/sentry'

// Components that are shared or need to be available in the Rails HAML templates
import * as global from './components/global.js'
for (const [key, value] of Object.entries(global.default)) {
  Vue.component(key, value)
}

Vue.use(VueRouter)
Vue.use(Toast)

// Make the window object available in Vue templates
Vue.prototype.window = window

Vue.config.devtools = process.env.NODE_ENV === 'development' // https://devtools.vuejs.org/
Vue.config.productionTip = false
Vue.config.warnHandler = function (err, vm, info) {
  alert(`${err}\n${info}\n${vm}`)
  console.log(err)
  console.log(info)
  console.log(vm)
}

new Vue({
  vuetify: Vuetify,
  i18n: I18n,
  router,
  el: '#vue-element'
})
