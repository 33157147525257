<script>
import ResponsiblesDialog from './responsibles_dialog.vue'
import {sortBy, chunk} from 'lodash'

export default {
  components: {
    ResponsiblesDialog
  },
  props: {
    existing_responsibles: Array,
    value: Array,
    users: Array,
    company_id: Number,
    company_name: String,
    compact: { type: Boolean, default: false }
  },
  data() {
    return {
      responsibles: this.value,
      responsible_to_edit: null,
      show_edit: false,
      companyResponsibleSearch: "",
      companyResponsiblePage: 1,
    }
  },
  watch: {
    responsibles: {
      handler(new_value) {
        this.$emit("input", new_value)
      },
      deep: true
    }
  },
  computed: {
    responsiblesFiltered() {
      this.companyResponsiblePage = 1

      return sortBy(this.responsibles, responsible => responsible.responsible.display_name).filter(responsible => responsible.responsible.display_name.toLowerCase().includes(this.companyResponsibleSearch?.toLowerCase() || ""))
    },
    companyResponsiblePages() {
      const pageSize = 10
      return chunk(this.responsiblesFiltered, pageSize)
    },
    currentCompanyResponsiblePage() {
      return this.companyResponsiblePages[this.companyResponsiblePage - 1]
    },
    xlsx_path() {
      return window.location.href.split('?')[0] + ".xlsx"
    }
  },
  methods: {
    addNewResponsible() {
      this.errors = []
      this.responsible_to_edit = { email: null }
      this.show_edit = true
    },
    setEditDialog(responsible) {
      this.errors = []
      this.responsible_to_edit = responsible.responsible
      this.show_edit = true
    },
    async deleteResponsible(responsible) {
      if(!confirm("Are you sure?")) {
        return;
      }

      try {
        await this.axios.delete(`/admin/company_responsibles/${responsible.id}`)
        this.responsibles.splice(this.responsibles.indexOf(responsible), 1)
      } catch(error) {
        console.log(error.response)
        this.errors = error.response.data.errors
      }
    },
    async setQmsResponsible(company_responsible) {
      const formData = new FormData()
      formData.append('company_responsible[id]', company_responsible.id)
      formData.append('company_responsible[task_responsible]', company_responsible.task_responsible)
      try {
        const result = await this.axios.patch(`/admin/company_responsibles/${company_responsible.id}`, formData)
        this.responsibles[this.responsibles.indexOf(company_responsible)] = result.data
      } catch(error) {
        console.log(error.response)
        this.errors = error.response.data.errors
      }
    },
  }
}
</script>

<template lang="pug">
div
  h1.mb-4.d-flex.align-center
    | {{ company_name }}
    v-btn.ml-2(@click="addNewResponsible")
      v-icon(left) mdi-plus
      | {{ $t('general.new') }}
    div.flex-grow-1
    v-btn(color="green" :href="xlsx_path" small dark)
      v-icon(left) mdi-file-excel
      | Export to xlsx
  div.mb-4
    v-text-field.my-2(v-model="companyResponsibleSearch" :label="$t('general.search')" hideDetails="auto" dense outlined clearable)
  v-simple-table
    template(v-slot:default)
      thead
        tr
          th {{ $t('responsible.task_responsible') }}
          th {{ $t('activerecord.attributes.responsible.name') }}
          th(v-if="!compact") {{ $t('activerecord.attributes.company_responsible.power_plants') }}
          th {{ $t('activerecord.attributes.responsible.email') }}
          th {{ $t('activerecord.attributes.responsible.phone') }}
          th
      tbody
        tr(v-for="company_responsible in currentCompanyResponsiblePage")
          td
            v-checkbox.text-no-wrap(
              v-model="company_responsible.task_responsible"
              @change="x => setQmsResponsible(company_responsible, x)"
              :label="$t('responsible.task_responsible')"
              hideDetails="auto"
              )

          td {{ company_responsible.responsible.display_name }}
          td(v-if="!compact")
            v-chip.mr-2(v-for="power_plant_responsible in company_responsible.power_plant_responsibles" :key="power_plant_responsible.id" small)
              | {{ power_plant_responsible.power_plant_name }}
          td
            v-tooltip(v-if="company_responsible.responsible.user" bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon(v-bind="attrs" v-on="on") mdi-account
              span {{ $t('responsible.linked_to_user') }}
            | {{ company_responsible.responsible.email }}
          td {{ company_responsible.responsible.phone }}
          td.d-flex.justify-end.align-center
            v-btn.ml-2( @click="setEditDialog(company_responsible)" elevation="1" x-small fab)
              v-icon mdi-pencil
            v-btn.ml-2( @click="deleteResponsible(company_responsible)" color="error" elevation="1" x-small fab)
              v-icon mdi-delete

  v-pagination(v-if="companyResponsiblePages.length > 1" v-model="companyResponsiblePage" :length="companyResponsiblePages.length" :total-visible="10")

  ResponsiblesDialog(
    v-model="show_edit"
    :responsible="responsible_to_edit"
    :company_responsibles="responsibles"
    :existing_responsibles="existing_responsibles"
    :users="users"
    :company_id="company_id"
    @responsibles="x => this.responsibles = x"
    )

</template>
