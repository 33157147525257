<script>
export default {
  props: {
    p50_prod: Array,
    ref_price_zone_prod: Array,
    price: Array,
  },
  methods: {
    chart_options() {
      return {
        title: { text: this.$t('ppa_pricing.charts.portfolio_reference_title') },
        credits: {
          enabled: false
        },
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            labels: {
              formatter: function() {
                return this.value + '%'
              }
            },
            title: { text: '% of annual production' }
          },
          {
            title: { text: 'Power price [EUR/MWh]'},
            opposite: true
          }
        ],
        tooltip: {
          split: true
        },
        plotOptions: {
          series: {
            marker: {
              radius: 1
            }
          }
        },
        series: [
          {
            name: 'P50',
            type: 'column',
            data: this.p50_prod,
            yAxis: 0,
            color: 'darkblue',
            tooltip: {
              pointFormat: '{series.name} {point.y} %',
              valueDecimals: 1,
            },
          },
          {
            name: 'Ref NO2',
            type: 'column',
            data: this.ref_price_zone_prod,
            yAxis: 0,
            color: 'lightblue',
            tooltip: {
              pointFormat: '{series.name} {point.y} %',
              valueDecimals: 1,
            },
          },
          {
            name: 'NO2 Price',
            type: 'line',
            data: this.price,
            yAxis: 1,
            color: 'red',
            tooltip: {
              pointFormat: '{series.name} {point.y} EUR/MWh',
              valueDecimals: 1,
            },
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options()")
</template>
