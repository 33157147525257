<script>
export default {
  props: {
    series: Array,
    title: String,
    units: Array,
    decimals: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
    }
  },
  computed: {
    valid_decimals() {
      return parseInt(this.decimals, 10)
    },
    global_max() {
      const allValues = this.series.reduce((acc, series) => {
        if(series.data && Array.isArray(series.data)) {
          return acc.concat(series.data)
        }
        return acc
      }, [])
      return allValues.length > 0 ? Math.max(...allValues) : 0
    },
    global_divider() {
      if(this.global_max > 1000000) {
        return 1000000
      } else if(this.global_max > 1000) {
        return 1000
      } else {
        return 1
      }
    },
    global_unit() {
      switch (this.global_divider) {
        case 1000000: return this.units[2]
        case 1000: return this.units[1]
        default: return this.units[0]
      }
    },
    chart_options() {
      const decimals = this.valid_decimals
      const unit = this.global_unit
      const divider = this.global_divider

      return {
        chart: {
          type: 'column'
        },
        title: {
          text: this.title
        },
        xAxis: {
          categories: this.$t("date.abbr_month_names").slice(1),
          crosshair: true,
        },
        yAxis: [{
          min: 0,
          title: {
            text: null
          },
          labels: {
            formatter() {
              const value = this.value / divider
              return `${value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: decimals,
                useGrouping: true
              })} ${unit}`
            }
          },
          opposite: true
        }],
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormatter: function () {
            const value = this.y / divider
            const formattedValue = parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
              useGrouping: true
            })
            return `<tr><td style="color:${this.series.color};padding:0;text-align:left;">${this.series.name}:</td>` +
                `<td style='padding:0; text-align: right;'><b>${formattedValue}</b></td>` +
                `<td style='padding:0; text-align: right;'>${unit}</td></tr>`
          },
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          series: {
            animation: false,
            visible: true
          },
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            grouping: true
          }
        },
        credits: {
          enabled: false
        },
        series: this.series
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
