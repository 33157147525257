<script>
export default {
  props: {
    items: Array,
    selectedItem: String,
    paramName: String,
    title: String,
    hideAll: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    showSingleOption: { type: Boolean, default: false }
  },
  data() {
    let items = [...this.items]
    if (!this.hideAll) {
      items.unshift({ value: "ALL", text: this.$t('general.all') })
    }

    return {
      itemsData: items,
      selectedItemIndex: items.findIndex(item => item.value === this.selectedItem) || 0
    }
  },
  computed: {
    shouldShowMenu() {
      return this.itemsData.length > 1 || !this.hideAll
    },
    selectedItemData() {
      return this.itemsData[this.selectedItemIndex] || this.itemsData[0]
    }
  },
  methods: {
    appendUrl(item) {
      const url = new URL(window.location.href)
      url.searchParams.set(this.paramName, item)
      return url.href
    },
  }
}
</script>

<template lang="pug">
v-menu(v-if="shouldShowMenu", :disabled="disabled")
  template(v-slot:activator="{ on }")
    v-chip.ma-2(v-on="!disabled && shouldShowMenu && on" :class="{ 'pointer': !disabled && shouldShowMenu, 'non-pointer': disabled || !shouldShowMenu }" color="light-blue" text-color="white")
      span.mr-1#title {{ title }}:
      span {{ selectedItemData.text }}
  v-list(dense)
    v-list-item-group(v-model="selectedItemIndex", :disabled="!shouldShowMenu")
      v-list-item(v-for="item in itemsData" :key="item.value" :href="shouldShowMenu ? appendUrl(item.value) : '#'")
        v-list-item-title(v-text="item.text")

v-chip(v-else-if="itemsData.length === 1 && showSingleOption", :disabled="true" ma-2 color="grey lighten-1" text-color="black")
  span.mr-1#title {{ title }}:
  span {{ itemsData[0].text }}
</template>
