<script>
import Highcharts from 'highcharts'

export default {
  props: {
    options: Object,
  },
  methods: {
    syncXAxis(chart) {
      const currentLen = chart.xAxis[0].len
      const currentLeft = chart.xAxis[0].left

      let globalMinLen = currentLen
      let globalMaxLeft = currentLeft
      let lenCorrection = 18 //FIXME: magic number here. It should be possible to calculate this value dynamically too.
      let updateRequired = false

      Highcharts.charts.forEach(otherChart => {
        if(otherChart === undefined) return

        const otherChartLen = otherChart.xAxis[0].len
        const otherChartLeft = otherChart.xAxis[0].left

        if(otherChartLen < globalMinLen) {
          globalMinLen = otherChartLen
          updateRequired = true
        }

        if(otherChartLeft > globalMaxLeft) {
          globalMaxLeft = otherChartLeft
          updateRequired = true
        }
      })

      if(!updateRequired) return

      const updatedAttributes = {
        width: globalMinLen - lenCorrection,
        left: globalMaxLeft,
      }

      Highcharts.charts.forEach(otherChart => {
        if(otherChart === undefined) return

        otherChart.xAxis[0].update(updatedAttributes)
      })
    },
  },
}
</script>
<template lang="pug">
v-container(fill-height)
  v-highcharts(:options="options.volume_and_price_options" :callback="syncXAxis" style="height: 34vh; width: 100vw;")
  v-highcharts(:options="options.reservoir_energy_options" :callback="syncXAxis" style="height: 18vh; width: 100vw;")
  v-highcharts(:options="options.inflow_energy_options"  :callback="syncXAxis" style="height: 18vh; width: 100vw;")
</template>
