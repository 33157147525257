<script>
import OperationalStatusHours from './opstat_hours.vue'
import AlarmTable from './alarm_table.vue'
import CaptivaNestAlarms from './hydro/captiva_nest_alarms.vue'
import AlarmFrequency from './alarm_frequency.vue'
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
export default {
  components: {
    AlarmTable,
    CaptivaNestAlarms,
    AlarmFrequency,
    OperationalStatusHours
  },
  props: {
    tech: String,
    currentRange: Array
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [this.tech === 'hydro' ? 'starts_at' : 'operational_status'],
        sortDesc: [true],
        search: ''
      },
      events: [],
      alarmFrequency: [],
      opStatFrequency: [],
      itemsPerPage: 15,
      currentAlarmPage: 1,
      totalPages: null,
      totalEvents: 0,
      headers: [
        {
          text: 'Event status',
          value: 'event_status'
        },
        {
          text: this.$t('om_dashboard.alarm.operational_status'),
          value: 'operational_status',
          sort: (a, b) => a.severity - b.severity
        },
        {
          text: this.$t('om_dashboard.alarm.alarm_category'),
          value: 'alarm_category',
          sort: (a, b) => a.severity - b.severity
        },
        { text: '', value: 'expand' },
        { text: this.$t(`om_dashboard.alarm.${this.tech}_component_name`), value: 'component_name' },
        { text: this.$t('om_dashboard.alarm.start'), value: 'starts_at' },
        { text: this.$t('om_dashboard.alarm.end'), value: 'ends_at' },
        { text: this.$t('om_dashboard.alarm.energy_losses'), value: 'energy_loss_mwh' },
        { text: this.$t('om_dashboard.alarm.duration'), value: 'duration' }
      ],
      power_plant_id: '',
      loading: false,
      loadingEvents: true,
      bindUpdateListener: false
    }
  },
  async created() {
    let path = window.location.pathname
    let parts = path.split('/')
    this.power_plant_id = parts[parts.length - 1]

    await this.load()
  },
  computed: {
    isWind() {
      return this.tech === 'wind'
    },
    displayTimeRange() {
      const start_time = new Date(this.currentRange[0])
      const end_time = new Date(this.currentRange[1])
      return `${start_time.toDateString()} - ${end_time.toDateString()}`
    },
    totalFrequentHours() {
      if (this.alarmFrequency.length > 0)
        return parseInt(
          Object.values(this.alarmFrequency.filter((i) => i[3] > 10).map((i) => i[1])).reduce(
            (a, c) => a + c,
            0
          )
        )
    }
  },
  methods: {
    async load() {
      this.loading = true
      try {
        await this.getEvents()
        await this.getOpStatHours()
        await this.getAlarmFrequency()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.bindUpdateListener = true
      }
    },
    async getEvents() {
      this.loadingEvents = true
      const { sortBy, sortDesc, page, itemsPerPage, search } = this.options
      let sortParam = sortBy.length > 0 ? `${sortBy[0]}:${sortDesc[0] ? 'desc' : 'asc'}` : ''

      let params = {
        page: page,
        per_page: itemsPerPage,
        start_time: this.currentRange[0],
        end_time: this.currentRange[1],
        sort: sortParam
      }

      if (search && search.trim() !== '') {
        params.search = search.trim()
      }

      try {
        let response = await this.axios.get(`/om_dashboard/events/${this.power_plant_id}`, {
          params: params
        })
        this.events = response.data.data
        this.totalEvents = response.data.pagy.count
        this.totalPages = response.data.pagy.pages
        this.currentPage = response.data.pagy.page
      } catch (error) {
        console.log('Error fetching events:', error)
      } finally {
        this.loadingEvents = false
      }
    },
    async getAlarmFrequency() {
      let response = await this.axios.get(
        `/om_dashboard/alarm_frequency/${this.power_plant_id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.alarmFrequency = response.data
    },
    async getOpStatHours() {
      let response = await this.axios.get(
        `/om_dashboard/operational_status_frequency/${this.power_plant_id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.opStatFrequency = response.data
    },
    handleOptionsUpdate(newOptions) {
      this.options = { ...this.options, ...newOptions }
      this.getEvents()
    }
  }
}
</script>

<template lang="pug">
div.alarms
  v-container
    div.header
      | {{ $t('om_dashboard.alarm.recent_alarms_and_events') }}
    //- div.sub-header
    //-   | {{ $t('om_dashboard.alarm.alarms_as_of', {num: alarms.length || 0, critical: '', from: formatDate(currentRange[0]), to: formatDate(currentRange[1]) }) }}
    AlarmTable(
      v-if="bindUpdateListener && tech !== 'hydro'"
      :headers="headers"
      :loading="loadingEvents"
      :items="events"
      :tech="tech"
      :options="options"
      :totalEvents="totalEvents"
      :numberOfPages="totalPages"
      @update:options="handleOptionsUpdate"
    )
    CaptivaNestAlarms(
      v-if="bindUpdateListener && tech === 'hydro'"
      :loading="loadingEvents"
      :items="events"
      :tech="tech"
      :options="options"
      :totalEvents="totalEvents"
      :numberOfPages="totalPages"
      @update:options="handleOptionsUpdate"
    )

  v-container(v-if="alarmFrequency.length > 0")
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.most_frequent_alarms') }}
    v-row
      v-col.graph_subtitle {{  $t('om_dashboard.drilldown.most_frequent_alarms_subtitle', { number: totalFrequentHours, time_period: displayTimeRange }) }}
    v-row
      v-col
        AlarmFrequency(:values="alarmFrequency" :tech="tech")
  v-container(v-if="opStatFrequency.length > 0")
    v-row
      v-col.graph_title {{  $t('om_dashboard.drilldown.accumulated_hours_of_operational_statuses') }}
    v-row
      v-col
        OperationalStatusHours(:values="opStatFrequency" :tech="tech")
</template>
