<script>
export default {

    name: 'PortalDownloadButton',
    
    props: {
        plant_ids: {
            type: Array,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },
    
    methods: {
        async downloadXlsx() {
            const formData = new FormData()
            this.errors = []
            formData.append('data_export_form[year]', this.year)
            this.plant_ids.forEach((plant_id) =>
                formData.append('data_export_form[power_plant_ids][]', plant_id)
            )
            try {
                const spreadsheet = await this.axios.post('/commercial_scada/export', formData, {
                    responseType: 'blob'
                })
                const url = window.URL.createObjectURL(new Blob([spreadsheet.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'data_export.xlsx')
                document.body.appendChild(link)
                link.click()
            } catch (error) {
                this.errors.push(error.response.statusText)
            }
        }
    }
}
</script>

<template>
    <button class="download-button" @click="downloadXlsx">
        <slot></slot>
    </button>
</template>
