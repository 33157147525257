<script>
export default {
  props: {
    values: Array,
    prepend: Object,
    column_names: Array,
    grouping_headers: Array,
  },
  methods: {
    cell_style(divider) {
      let style = 'border-bottom: 1px solid LightGray;'

      if(divider)
        style += 'border-right: 2px solid #78909C;'

      return style
    },
    item_class(index, row_index, item, header) {
      let finalClass = [this.item_color(item, header)]

      finalClass.push((index == 0 ? 'text-left ' : 'text-center'))

      if(row_index == this.values.length - 1)
        finalClass.push('font-weight-bold')

      return finalClass.join(' ')
    },
    item_color(item, header) {
      const item_field = item[header]
      if(item_field == null)
        return "grey--text text--darken-3"

      return item[header].color
    },
    item_formatted(item, header) {
      const item_field = item[header]
      if(item_field == null)
        return "N/A"

      return item[header].formatted
    }
  },
  computed: {
    headers() {
      return this.column_names
    }
  },
}
</script>

<template lang="pug">
  v-simple-table(dense)
    thead
      tr(v-if="grouping_headers.length > 0")
        th.text-center.text-subtitle-1.font-weight-black.black--text(v-for="gh in grouping_headers" :colspan="gh.colspan" :style="cell_style(gh.divider) + gh.class")
          | {{ gh.text }}
      tr
        th.text-no-wrap.font-weight-bold.black--text.text-center(v-for="h in headers" :style="cell_style(h.divider)") {{ h.text }}
      tr
        th.font-italic(v-for="p, v, i in prepend" :class="(i == 0 ? 'text-left ' : 'text-center ')" :style="cell_style(headers.find(h => h.value == v).divider)") {{ p }}

    tbody
      tr(v-for="(item, row_index) in values")
        td(v-for="(h, index) in headers" :style="cell_style(h.divider) + (index % 2 == 1 ? 'background: #f0f0f0f0;' : '')")
          div.text-no-wrap(:class="item_class(index, row_index, item, h.value)") {{ item_formatted(item, h.value) }}

</template>
