<script>
import Load from './elements/load.vue'
import Losses from './elements/losses.vue'
import Weather from './elements/weather.vue'
import OmDrilldownHydro from './hydro/plant.vue'
import OmDrilldownSolar from './solar/plant.vue'
import OmDrilldownWind from './wind/plant.vue'
import OmSolarInverters from './solar/inverters.vue'
import OmSolarMppts from './solar/mppts.vue'
import OmWindTurbines from './wind/turbines.vue'
import OmAlarms from './alarms.vue'
import TimeScope from './elements/time_scope.vue'
import AlarmIcon from './elements/alarm_icon.vue'
import Revenue from './elements/revenue.vue'
import ComponentStatus from './elements/component_status.vue'
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'
import OmWeatherForecast from './hydro/weather_forecast.vue'

export default {
  name: 'OmDrilldownCommon',
  mixins: [OMDashboardHelpers],
  components: {
    OmDrilldownHydro,
    OmDrilldownSolar,
    OmDrilldownWind,
    TimeScope,
    OmSolarInverters,
    OmSolarMppts,
    OmAlarms,
    OmWindTurbines,
    AlarmIcon,
    Load,
    Losses,
    Weather,
    Revenue,
    ComponentStatus,
    OmWeatherForecast
  },
  data() {
    return {
      powerPlant: this.power_plant,
      drilldownFacade: this.drilldown_facade,
      start_time: this.current_range[0],
      end_time: this.current_range[1],
      Scope: this.scope,
      rangedKpis: this.kpis,
      loadingData: false,
      availableTimescopes: ['week', 'month', 'quarter', 'year'],
      activeComponent: this.power_plant.components[0],
      comparingComponents: [],
      comparingMppts: [],
      activeMppt: this.power_plant.solar_inverters.length
        ? this.power_plant.solar_inverters[0].solar_mppts[0]
        : null,
      selectableMppts: this.power_plant.solar_inverters.length
        ? this.power_plant.solar_inverters[0].solar_mppts
        : [],
      initialActiveTab: 0
    }
  },
  props: {
    currency_code: String,
    power_plant: Object,
    current_market_prices: Object,
    scope: String,
    current_range: Array,
    kpis: Object,
    current_company: String,
    drilldown_facade: Object,
    sensor_types: Array
  },
  created() {
    let qp = new URLSearchParams(window.location.search)
    let savedTab = qp.get('activeTab')
    if (savedTab) this.initialActiveTab = parseInt(savedTab)
    let start_time = qp.get('start_time')
    let end_time = qp.get('end_time')
    let Scope = qp.get('scope')
    let comparingComponents = qp.get('comparingComponents')
    let comparingMppts = qp.get('comparingMppts')
    if (Scope) this.Scope = Scope
    if (start_time) this.start_time = start_time
    if (end_time) this.end_time = end_time
    if (comparingComponents)
      this.comparingComponents = comparingComponents
        .replace(/^\[|\]$/g, '')
        .split(/\,/)
        .map(Number)
    if (comparingMppts)
      this.comparingMppts = comparingMppts
        .replace(/^\[|\]$/g, '')
        .split(/\,/)
        .map(Number)
  },
  computed: {
    displayLocation() {
      return this.current_company
        ? `${this.current_company}, ${this.power_plant.country_name}`
        : this.power_plant.country_name
    },
    visibleTab: {
      get() {
        return this.initialActiveTab
      },
      set(newValue) {
        let qp = new URLSearchParams(window.location.search)

        qp.set('activeTab', newValue)
        history.replaceState(null, null, '?' + qp.toString())
        this.initialActiveTab = newValue
      }
    },
    breadcrumbsItems() {
      let assetName = this.powerPlant.asset_type ? this.powerPlant.asset_type : ''

      return [
        {
          text: this.$t('om_dashboard.operations'),
          href: '/om_dashboard'
        },
        {
          text: assetName,
          href: `/om_dashboard?asset_filter=${assetName}`
        },
        {
          text: this.powerPlant.name,
          disabled: true
        }
      ]
    },
    currentRange() {
      return [this.start_time, this.end_time]
    },
    tech() {
      return this.powerPlant.asset_type.toLowerCase()
    },
    menuForMobile() {
      const components = () => {
        if (this.powerPlant.asset_type === 'Wind')
          return [{ name: this.$t('om_dashboard.drilldown.turbine'), index: 1 }]
        else
          return [
            { name: this.$t('om_dashboard.drilldown.inverter'), index: 1 },
            { name: this.$t('om_dashboard.drilldown.mppt'), index: 2 }
          ]
      }

      return [
        { name: this.$t('om_dashboard.power_plant'), index: 0 },
        components(),
        { name: this.$t('om_dashboard.alarms'), index: 3 }
      ].flat()
    }
  },
  methods: {
    async changeTimeScope(e) {
      let newUrl = `/om_dashboard/drilldown/${this.powerPlant.id}?start_time=${e[0]}&end_time=${e[1]}`
      if (e.length === 3) {
        newUrl = `/om_dashboard/drilldown/${this.powerPlant.id}?start_time=${e[0]}&end_time=${e[1]}&scope=${e[2]}`
      }
      this.loadingData = true
      const newData = await this.axios.get(newUrl)
      this.powerPlant = newData.data.power_plant
      this.start_time = newData.data.start_time
      this.end_time = newData.data.end_time
      this.Scope = newData.data.scope
      this.rangedKpis = newData.data.kpis
      this.drilldownFacade = newData.data.drilldown_facade

      //  update location.href query params to persist across reloads
      let qp = new URLSearchParams(window.location.search)
      qp.set('start_time', newData.data.start_time)
      qp.set('end_time', newData.data.end_time)
      qp.set('scope', newData.data.scope)

      history.replaceState(null, null, '?' + qp.toString())
      this.loadingData = false
    },
    changeComponent(e) {
      this.activeComponent = this.power_plant.components.find((i) => i.id === e)
      let qp = new URLSearchParams(window.location.search)
      this.comparingMppts = []
      qp.delete('comparingMppts')
      history.replaceState(null, null, '?' + qp.toString())
      if (this.power_plant.solar_inverters.length) {
        this.selectableMppts = this.activeComponent.solar_mppts
        this.activeMppt = this.activeComponent.solar_mppts[0]
      }
    },
    changeMppt(e) {
      this.activeMppt = this.activeComponent.solar_mppts.find((i) => i.id === e)
    },
    updateComparisonUrls(newComparisons) {
      let qp = new URLSearchParams(window.location.search)
      let unique = [...new Set(newComparisons)]
      qp.set('comparingComponents', `[${unique.toString()}]`)
      history.replaceState(null, null, '?' + qp.toString())
      this.comparingComponents = unique
    },
    updateComparedMpptUrls(newComparedMppts) {
      let qp = new URLSearchParams(window.location.search)
      let unique = [...new Set(newComparedMppts)]
      qp.set('comparingMppts', `[${unique.toString()}]`)
      history.replaceState(null, null, '?' + qp.toString())
      this.comparingMppts = unique
    },
    refresh() {
      window.location.reload()
    }
  }
}
</script>

<template lang="pug">
v-container
  v-sheet
    .breadcrumb_home
      a(href="/" class="breadcrumb_home")
        v-img(:src="'/om_dashboard/breadcrumb_home.svg'")
      v-icon mdi-chevron-right
    v-breadcrumbs(:items="breadcrumbsItems")
      template(v-slot:divider)
        v-icon mdi-chevron-right

  v-sheet
    v-row
      v-col.pt-0
        h1
          | {{ power_plant.name }}
          span
            v-tooltip(right)
              template(v-slot:activator="{ on, attrs }")
                v-icon.ml-1(
                  :class="power_plant.live_data ? 'green--text text--accent-4' : ''"
                  v-bind="attrs"
                  v-on="on"
                  style="display: inline-block;"
                )
                  | {{ power_plant.live_data ? 'mdi-wifi' : 'mdi-wifi-off' }}
              | {{ `${this.$t('om_dashboard.overview.last_update')}:` }} {{ formatDateTime(powerPlant.latest_calculation_timestamp) }}

      v-col(v-if="powerPlant.asset_type !== 'Solar'" class="d-flex justify-end align-center")
        div
          v-menu(offset-y left)
            template(v-slot:activator="{ on, attrs }")
              v-card
                v-icon(v-bind="attrs", v-on="on") mdi-dots-vertical
            v-list
              om-sensor-mapping(:power_plant_id="powerPlant.id.toString()" :sensor_types="sensor_types" @refresh="refresh")
              v-list-item(:href="`/admin/tyde?tyde_id=${powerPlant.tyde_id}`" target="_blank") Tyde data drilldown
              v-list-item(:href="`/admin/power_plants/${powerPlant.id}/edit`" target="_blank") Power plant settings

    v-row.ma-0.pa-0
      v-col.subheading.ma-0.pa-0
        img.flag(:src="power_plant.flag_url" width="22" height="20")
        .country_name
          | {{ displayLocation }}
    v-row.portfolio_name.ma-0.pb-2
      v-col.ma-0.pa-0.capacity
        img.asset_class(:src="`/om_dashboard/${powerPlant.asset_type}.svg`")
        | {{ powerPlant.asset_type }}, {{ powerPlant.rated_power_mw.toFixed(2) }} MW


  v-sheet.drilldown_wrapper
    #om-dashboard-drilldown
      v-row.asset_plant_header.hidden-sm-and-down.pt-2(:class="`background_${powerPlant.operational_status.severity}`")
        v-col.heading_name
          | {{  $t(`om_dashboard.status.${tech}.${operationalStatusTypes[powerPlant.operational_status.severity].title}`) }}
          .last_updated {{ $t('om_dashboard.drilldown.time_ago', { duration: powerPlant.last_update_words }) }}
        v-col.heading_name(style="text-align: left") {{ $t('om_dashboard.component_status') }}
        v-col.heading_name(style="text-align: left") {{ $t('om_dashboard.overview.load') }}
        v-col.heading_name(v-if="tech !== 'hydro'" style="text-align: left") {{ $t('om_dashboard.overview.losses') }}
        v-col.heading_name {{ $t('om_dashboard.overview.forecast_now') }}
        v-col.heading_name {{ $t('om_dashboard.overview.in_48h') }}
        v-col.heading_name(style="text-align: left") {{ $t('om_dashboard.overview.only_revenue') }}
        v-col.heading_name(v-if="tech !== 'hydro'" style="text-align: left") {{ $t('om_dashboard.overview.loss') }}
      v-row.asset_plant_items
        v-col.header_item.operational_status
          AlarmIcon(
            :operational_status_severity="powerPlant.operational_status.severity",
            :alarm_category="powerPlant.operational_status.category"
            :duration="powerPlant.operational_status.duration"
            :tech="powerPlant.asset_type.toLowerCase()"
            :last_updated="powerPlant.latest_calculation_timestamp"
            :mobile_title="$t(`om_dashboard.status.${tech}.${operationalStatusTypes[powerPlant.operational_status.severity].title}`)"
          )
        v-col.header_item
          ComponentStatus(:status_of_components="powerPlant.status_of_components" :tech="tech")
        v-col.header_item.load
          Load(:item="powerPlant")
        v-col.header_item.losses(v-if="tech !== 'hydro'")
          Losses(:item="powerPlant")
        v-col.header_item
          Weather(:weather="powerPlant.weather_now" v-if="powerPlant.weather_now" :tag="'div'")
        v-col.header_item.hidden-sm-and-down
          Weather(:weather="powerPlant.weather_48h" v-if="powerPlant.weather_48h" :tag="'div'")
        v-col.header_item
          Revenue(:item="powerPlant" tag="div" :unit="currency_code + '/h'" textAlign="left")
        v-col.header_item(v-if="tech !== 'hydro'")
          Revenue(:item="powerPlant" tag="div" :unit="currency_code + '/h'" :isLoss="true" textAlign="left")

      v-row.asset_header
        v-col.component_selector.hidden-sm-and-up
          TimeScope(:availableRanges="availableTimescopes" :scope="Scope" :currentRange="currentRange" :plantId="powerPlant.id" @changedScope="changeTimeScope"  :allDisabled="false" )
          br(clear="both")
          v-select.main_menu(:items="menuForMobile" item-text="name" item-value="index" v-model="visibleTab")
          br(clear="both")
          v-select.component(v-if="visibleTab === 1" :items="powerPlant.components" item-text="name" item-value="id" v-model="activeComponent" @change="changeComponent")
          br(clear="both")
          v-select.component.inverter_for_mppt(v-if="visibleTab === 2" :items="powerPlant.components" item-text="name" item-value="id" v-model="activeComponent" @change="changeComponent")
          v-select.component.mppt_selector(v-if="visibleTab === 2" :items="selectableMppts" v-model="activeMppt" item-text="input_number" item-value="id" @change="changeMppt" :label="$t('om_dashboard.drilldown.choose_mppt')")
        v-col(cols="8").hidden-xs-only.pl-0
          v-row
            v-col.d-flex.component_selector
              v-btn-toggle(mandatory)
                v-btn(@click="visibleTab = 0")
                  span(class="hide_mobile") {{ $t('om_dashboard.power_plant')}}
                  span(class="show_mobile") {{ $t('om_dashboard.power_plant_short')}}

                v-select(v-if="visibleTab === 1" :items="powerPlant.components" v-model="activeComponent" item-text="name" item-value="id" @change="changeComponent")
                  template(slot="selection" slot-scope="data")
                    span(v-if="powerPlant.asset_type === 'Wind'") {{ $t('om_dashboard.drilldown.turbine_single', { name: data.item.name }) }}
                    span(v-else-if="powerPlant.asset_type === 'Solar'") {{ data.item.name }}
                v-btn(v-else-if="powerPlant.asset_type != 'Hydro'" @click="visibleTab = 1")
                  span(v-if="powerPlant.asset_type === 'Solar'") {{ $t('om_dashboard.drilldown.inverter') }}
                  span(v-else-if="powerPlant.asset_type === 'Wind'") {{ $t('om_dashboard.drilldown.turbine') }}

                div.mppt_selector_wrapper(v-if="visibleTab === 2")
                  v-select.inverter(:items="powerPlant.components" v-model="activeComponent" item-text="name" item-value="id" @change="changeComponent")
                    template(slot="selection" slot-scope="data")
                      span {{ data.item.name }}
                  v-select.mppt(:items="selectableMppts" v-model="activeMppt" item-text="input_number" item-value="id" @change="changeMppt" :label="$t('om_dashboard.drilldown.choose_mppt')")
                    template(slot="selection" slot-scope="mppt")
                      span {{  $t('om_dashboard.drilldown.mppt_single', { name: mppt.item.input_number}) }}
                v-btn(v-else-if="powerPlant.asset_type === 'Solar'" @click="visibleTab = 2") {{  $t('om_dashboard.drilldown.mppt')}}
                v-btn(@click="visibleTab = 3")  {{ $t('om_dashboard.alarms')}}
                v-btn(v-if="powerPlant.asset_type === 'Hydro'" @click="visibleTab = 4")  {{ $t('om_dashboard.drilldown.weather_forecast')}}
        v-col.pr-0.hidden-xs-only
          TimeScope(:availableRanges="availableTimescopes" :scope="Scope" :currentRange="currentRange" :plantId="powerPlant.id" @changedScope="changeTimeScope" :allDisabled="false" )
      div.drilldown_lower_wrapper
        v-progress-circular(v-if="loadingData" indeterminate color="primary")
        div(v-else)
          OmDrilldownHydro(v-show="visibleTab === 0" v-if="powerPlant.asset_type === 'Hydro'" :powerPlant="powerPlant" :kpis="rangedKpis" :current_market_prices="current_market_prices" :currentRange="currentRange" :drilldown_facade="drilldownFacade")
          OmDrilldownSolar(v-show="visibleTab === 0" v-if="powerPlant.asset_type === 'Solar'" :powerPlant="powerPlant" :kpis="rangedKpis" :current_market_prices="current_market_prices" :currentRange="currentRange")
          OmDrilldownWind(v-show="visibleTab === 0" v-if="powerPlant.asset_type === 'Wind'" :powerPlant="powerPlant" :kpis="rangedKpis" :current_market_prices="current_market_prices" :currentRange="currentRange")
          OmSolarInverters(v-show="visibleTab === 1" v-if="powerPlant.asset_type === 'Solar'" :inverters="powerPlant.solar_inverters" :currentRange="currentRange" :activeComponent="activeComponent" :comparingFromUrl="comparingComponents" @changedComparisons="updateComparisonUrls")
          OmWindTurbines(v-show="visibleTab === 1" v-if="powerPlant.asset_type === 'Wind'" :turbines="powerPlant.wind_turbines" :currentRange="currentRange" :powerPlant="powerPlant" :activeComponent="activeComponent" :comparingFromUrl="comparingComponents" @changedComparisons="updateComparisonUrls")
          OmSolarMppts(v-show="visibleTab === 2" v-if="powerPlant.asset_type === 'Solar'" :plantId="powerPlant.id" :inverters="powerPlant.solar_inverters" :currentRange="currentRange" :selectedInverter="activeComponent" :activeMpptProp="activeMppt" :comparingFromUrl="comparingMppts" @changedComparisons="updateComparedMpptUrls")
          OmAlarms(v-show="visibleTab === 3" :tech="tech" :currentRange="currentRange")
          OmWeatherForecast(v-show="visibleTab === 4" v-if="powerPlant.asset_type === 'Hydro'" :powerPlant="powerPlant" :currentRange="currentRange")
</template>
