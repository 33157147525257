<script>
import EditWorkItemDialog from '../edit_work_item.vue'
import humanizeNumberMixin from '../../../mixins/humanize_number_mixin.js'

export default {
  components: {
    EditWorkItemDialog,
  },
  mixins: [humanizeNumberMixin],
  props: {
    labels: Array,
    work_items: Array,
    components: Array,
    power_plants: Array,
    users: Array,
    allow_label_edit: Boolean,
    currency_code: String,
  },
  data() {
    return {
      currentWorkItem: null,
      showEditWorkItemDialog: false,
      showClosedTasks: false,
      showOnlyClosedTasks: false,
      showOnHoldTasks: false,
      showOnlyOnHoldTasks: false,
    }
  },
  methods: {
    customParseFloat(str) {
      if (typeof str === 'number') return str;
      if (typeof str !== 'string') return 0;

      const cleanStr = str.replace(/\s+/g, '').replace(',', '.');
      return parseFloat(cleanStr) || 0;
    },
    sumCost(type, items = this.filtered_work_items) {
      return items.reduce((total, item) => {
        const cost = this.customParseFloat(item[type]);
        return total + cost;
      }, 0);
    },
    truncate(string, maxLength) {
      return string.length > maxLength
          ? `${string.substring(0, maxLength)}…`
          : string
    },
    edit(item) {
      this.currentWorkItem = item
      this.showEditWorkItemDialog = true
    }
  },
  computed: {
    xlsx_path() {
      return window.location.href.split('?')[0] + "/export.xlsx"
    },
    headers() {
      return [
        {text: this.$t('activerecord.attributes.work_item.id'), value: "id"},
        {text: this.$t('activerecord.attributes.work_item.title'), value: "title"},
        {text: this.$t('activerecord.attributes.work_item.power_plant'), value: "power_plant"},
        {text: this.$t('activerecord.attributes.work_item.status'), value: "status"},
        {text: this.$t('activerecord.attributes.work_item.budget_cost'), value: "budget_cost"},
        {text: this.$t('activerecord.attributes.work_item.elapsed_cost'), value: "elapsed_cost"},
        {text: this.$t('activerecord.attributes.work_item.forecast_cost'), value: "forecast_cost"},
        {text: this.$t('activerecord.attributes.work_item.valuta'), value: "converted_unit"},
        {text: this.$t('activerecord.attributes.work_item.due_date'), value: "due_date"},
        {text: this.$t('activerecord.attributes.work_item.description'), value: "description"},
        {text: this.$t('activerecord.attributes.work_item.last_comment'), value: "last_comment"},
        {text: this.$t('activerecord.attributes.work_item.labels'), value: "work_item_labels"},
        {text: '', value: "edit"},
      ]
    },
    filtered_work_items() {
      return this.work_items.filter(w => {
        if (this.showOnlyOnHoldTasks) return w.status === 'on_hold';
        if (!this.showOnHoldTasks && w.status === 'on_hold') return false;
        if (this.showOnlyClosedTasks) return w.status === 'closed';
        if (!this.showClosedTasks) return w.status !== 'closed';
        return true;
      });
    },
    totalBudgetCost() {
      return this.sumCost('budget_cost');
    },
    totalElapsedCost() {
      return this.sumCost('elapsed_cost');
    },
    totalForecastCost() {
      return this.sumCost('forecast_cost');
    }
  },
}
</script>

<template lang="pug">
div
  v-row.pl-2.pr-3
    v-col.left
      v-switch.pa-2(v-model="showOnHoldTasks" :label="$t('work_items.show_on_hold_tasks')")
    v-col.left
      v-switch.pa-2(v-model="showClosedTasks" :label="$t('work_items.show_closed_tasks')")
    v-col.left
      v-switch.pa-2(v-model="showOnlyOnHoldTasks" :label="$t('work_items.show_only_on_hold_tasks')")
    v-col.left
      v-switch.pa-2(v-model="showOnlyClosedTasks" :label="$t('work_items.show_only_closed_tasks')")
    v-col.right
      v-btn(color="green" :href="xlsx_path" small dark)
        v-icon(left) mdi-file-excel
        | Export to xlsx
  v-data-table(:items="filtered_work_items" :headers="headers")
    template(v-slot:item.budget_cost="{item}")
      div(style="text-align: right")
        span.text-no-wrap
          | {{ humanizeNumber(customParseFloat(item.budget_cost).toFixed(0)) }}
    template(v-slot:item.elapsed_cost="{item}")
      div(style="text-align: right")
        span.text-no-wrap
          | {{ humanizeNumber(customParseFloat(item.elapsed_cost).toFixed(0)) }}
    template(v-slot:item.forecast_cost="{item}")
      div(style="text-align: right")
        span.text-no-wrap
          | {{ humanizeNumber(customParseFloat(item.forecast_cost).toFixed(0)) }}
    template(v-slot:item.status="{item}")
      | {{ $t(`work_items.status.${item.status}`) }}
    template(v-slot:item.work_item_labels="{item}")
      v-chip(v-for="label in item.work_item_labels" :key="label.id" :color="label.color" dark)
        | {{ label.name }}
    template(v-slot:item.description="{item}")
      div(v-if="item.description")
        v-tooltip(color="primary" bottom)
          template(v-slot:activator="{on, attrs}")
            div(v-bind="attrs" v-on="on")
              span.text-no-wrap {{ truncate(item.description, 25) }}
          | {{ item.description }}
    template(v-slot:item.last_comment="{item}")
      div(v-if="item.last_comment")
        v-tooltip(color="primary" bottom)
          template(v-slot:activator="{on, attrs}")
            div(v-bind="attrs" v-on="on")
              span.text-no-wrap {{ truncate(item.last_comment, 25) }}
          | {{ item.last_comment }}
    template(v-slot:item.edit="{item}")
      v-btn(@click="edit(item)" x-small fab)
        v-icon mdi-pencil
    template(v-slot:body.append)
      tr
        td(colspan="1")
        td(colspan="1")
          strong {{ $t('work_items.sum_total_cost') }}
        td(colspan="2")
        td(colspan="1" class="text-no-wrap" style="font-weight: bold; text-align: right;")
          | {{ humanizeNumber(totalBudgetCost.toFixed(0)) }}
        td(colspan="1" class="text-no-wrap" style="font-weight: bold; text-align: right;")
          | {{ humanizeNumber(totalElapsedCost.toFixed(0)) }}
        td(colspan="1" class="text-no-wrap" style="font-weight: bold; text-align: right;")
          | {{ humanizeNumber(totalForecastCost.toFixed(0)) }}
        td(colspan="5")

  EditWorkItemDialog(
    v-model="showEditWorkItemDialog"
    :workItem="currentWorkItem"
    :components="components"
    :powerPlants="power_plants"
    :users="users"
    :labels="labels"
    :allow_label_edit="allow_label_edit"
    returnUrl="/work_items/statistics"
  )
</template>
