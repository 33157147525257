<script>
export default {
  data() {
    return {
      query: '',
      allOpen: true,
    }
  },
  computed: {
    largeDataSet () { return this.$attrs.items.length > 15 },
    btnTxt () { return this.allOpen ? 'Collapse' : 'Expand'},
    filter () {
      return (item, query) => {
        const qry = query.toLowerCase()
        if(item.extra_info) {
          return item.name.toLowerCase().indexOf(qry.toLowerCase()) > -1
        } else {
          return item.name.toLowerCase().indexOf(qry.toLowerCase()) > -1 //|| item.extra_info.toLowerCase().indexOf(qry.toLowerCase()) > -1
        }
      }
    },
  },
  methods: {
    toggle () {
      this.allOpen = !this.allOpen
      this.$refs.tree.updateAll(this.allOpen)
    },
  }
}
</script>

<template lang="pug">
div
  v-text-field(v-if="largeDataSet" v-model="query" :placeholder="$t('general.search')")
  v-btn(v-if="largeDataSet" @click="toggle" x-small) {{ this.btnTxt }}
  v-treeview(v-bind="$attrs" :search="query" :filter="filter" ref="tree")
    template(v-slot:label="{ item }")
      a(:href="item.path") {{ item.name }}
      | 
      span(v-if="item.extra_info") {{ item.extra_info }}
</template>
