<script>
export default {
  props: {
    data: Object,
    y_title: String,
  },
  data() {
    const months = this.$t("date.abbr_month_names").slice(1, 13);
    const high = this.$t("market_outlook.high");
    const upperq = this.$t("market_outlook.upperq");
    const median = this.$t("market_outlook.median");
    const lowerq = this.$t("market_outlook.lowerq");
    const minimum = this.$t("market_outlook.minimum");
    const actual = this.$t("general.actual");
    const nasim = this.$t("market_outlook.nasim");

    return {
      chart_options: {
        chart: {
          type: "boxplot",
        },

        credits: {
          enabled: false,
        },

        title: {
          text: null,
        },

        legend: {
          enabled: true,
        },

        tooltip: {
          valueDecimals: 2,
        },

        xAxis: {
          categories: months.slice(
            12 -
              (typeof this.data.SYS !== "undefined" ? this.data.SYS.length : 0),
            12
          ),
        },

        yAxis: {
          title: {
            text: this.y_title,
            enabled: true,
          },
        },

        plotOptions: {
          series: {
            tooltip: {
              pointFormatter: function () {
                if (!this.options.sim) {
                  return `<span style="color:${this.color}">●</span> <b> ${this.name}</b><br/>Median: ${this.median} EUR/MWh`;
                } else {
                  if (!this.options.valid) {
                    return `<span style="color:orange">${nasim}</span>`;
                  } else {
                    return `<span style="color:${this.color}">●</span> <b> ${this.name}</b><br/>${high}: ${this.high} EUR/MWh<br/>${upperq} (P75): ${this.q3} EUR/MWh<br/>${median} (P50): ${this.median} EUR/MWh<br/>${lowerq} (P25): ${this.q1} EUR/MWh<br/>${minimum}: ${this.low} EUR/MWh<br/>`;
                  }
                }
              },
            },
            events: {
              legendItemClick: function () {
                this.chart.series.forEach(function (s) {
                  if (s !== this && s.visible) {
                    s.hide();
                  }
                });

                let yL = this.yData.length;
                let categories = months.slice(12 - yL, 12);
                this.chart.update({ xAxis: { categories: categories } });
                return !this.visible ? true : false;
              },
            },
          },
        },

        series: Object.keys(this.data).map((key, index) => {
          const altName = key == "SP1" ? "SYS" : key
          return {
            id: key,
            name: altName,
            // data: this.data[key],
            data: this.data[key].map((values, ix) => {
              if (values[0]) {
                return {
                  x: ix,
                  type: "boxplot",
                  medianWidth: 4,
                  low: values[3],
                  q1: values[3],
                  median: values[3],
                  q3: values[3],
                  high: values[3],
                  name: values[0] ? `${altName} (${actual})` : altName,
                  color: values[0] ? "green" : null,
                  sim: false,
                  valid: true
                };
              } else {
                if (values.slice(1).every((val) => val === 0)) {
                  return {
                    x: ix,
                    type: "boxplot",
                    low: values[1],
                    q1: values[2],
                    median: values[3],
                    q3: values[4],
                    high: values[5],
                    name: values[0] ? `${altName} (${actual})` : altName,
                    color: "orange",
                    sim: true,
                    valid: false,
                  };
                } else {
                  return {
                    x: ix,
                    type: "boxplot",
                    low: values[1],
                    q1: values[2],
                    median: values[3],
                    q3: values[4],
                    high: values[5],
                    name: values[0] ? `${altName} (${actual})` : altName,
                    color: values[0] ? "green" : null,
                    sim: true,
                    valid: true
                  };
                }
              }
            }),
            visible: key == "SP1" ? true : false,
          };
        }),
      },
    };
  },
};
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
