<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object,
  },
  data () {
    return {
      solar_fields: this.value,
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find(a => a.asset_class_attributes).asset_class_attributes.find(a => a.solar_fields_attributes).solar_fields_attributes
    },
    mounting_modes() {
      return this.form_data.solar_mounting_modes.map(mm => ({ value: mm, text: this.$t(`activerecord.attributes.solar/field.mounting_modes.${mm}`) }))
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][solar_fields_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_solar_fields_attributes_${field}`,
        errorMessages: this.errors[`asset_class.solar_fields.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? 'mr-1 mb-2' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/field.${field}`)
    },
    addNew() {
      this.solar_fields.push({
      })
    },
    destroy(field) {
      if(field.id) {
        field._destroy = !field._destroy
      } else {
        this.solar_fields.splice(this.solar_fields.indexOf(field), 1)
      }
    },
  },
}
</script>
<template lang="pug">
#solar-fields
  .mb-2
    .overline.d-inline-block Fields
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add

  .mb-4(v-for="field in solar_fields" :key="field.id" :class="{'opacity-50': field._destroy}")
    .d-flex.flex-wrap
      v-text-field(v-model="field.name" v-bind="sharedProps('name')")
      v-select(v-model="field.mounting_mode" v-bind="sharedProps('mounting_mode')" :items="mounting_modes")
      v-text-field(v-model="field.installed_power_kwp" v-bind="sharedProps('installed_power_kwp')" suffix="kWp")
      v-text-field(v-model="field.orientation" v-bind="sharedProps('orientation')" suffix="deg (S=180)")
      v-text-field(v-model="field.tilt" v-bind="sharedProps('tilt')" suffix="deg")
      v-text-field(v-model="field.module_efficiency_percent" v-bind="sharedProps('module_efficiency_percent')" suffix="%")

    v-btn(color="error" @click="destroy(field)" small) {{ $t('general.delete') }}
</template>
