<script>
export default {
  props: {
    id: String,
    name: String,
    label: String,
    showNoneAllButtons: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    items: Array,
    errorMessages: Array
  },
  data() {
    return {
      selected_items: this.items.filter((item) => this.value && this.value.includes(item.value))
    }
  },
  mounted() {
    this.selected_items = this.value;
  },
  computed: {
    filtered_items() {
      return this.items.filter((item) => item.value > 0)
    }
  },
  methods: {
    changeHandler() {
      this.$refs.searchBox.lazySearch = ''
    },
    selectAll() {
      this.selected_items = this.items
    },
    clearAll() {
      this.selected_items = []
    }
  },
  watch: {
    value(value) {
      this.selected_items = this.value
    },
    selected_items(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template lang="pug">
.searchable-multi-select
  v-combobox(
    v-model="selected_items"
    :items="filtered_items"
    :error-messages="errorMessages"
    :multiple="true"
    :chips="true"
    :hide-details="errorMessages.length === 0"
    :label='label'
    @change="changeHandler"
    ref="searchBox"
  )
    template(v-slot:append)
      v-btn(
        v-if="showNoneAllButtons && selected_items.length > 0"
        @click="clearAll"
        title="Clear selection"
        small
        depressed
      )
        | {{ $t('general.none') }}
      v-btn(
        v-if="showNoneAllButtons && selected_items != items"
        @click="selectAll"
        title="Select all"
        small
        depressed
      )
        | {{ $t('general.all') }}
  input(v-for="item in selected_items" :value="item.value" type="hidden" :name="`${name}[]`")
  input(v-if="selected_items.length == 0" type="hidden" :name="`${name}[]`")
</template>
