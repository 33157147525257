<script>
export default {
  props: {
    value: Boolean,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      contractual_availability_energy_based: this.value,
      time_based_availability: this.label('time_based_availability'),
      energy_based_availability: this.label('energy_based_availability'),
      availability_options: [],
      contractual_availability_selector: ''
    }
  },
  created() {
    this.availability_options = [this.time_based_availability, this.energy_based_availability];
    this.contractual_availability_selector = this.value ? this.energy_based_availability : this.time_based_availability;
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find((a) => a.asset_class_attributes).asset_class_attributes
    }
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_${field}`,
        errorMessages: this.errors[`asset_class.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`
      }
    },
    updateAvailabilitySelectedValue(val) {
      this.contractual_availability_energy_based = (val === this.energy_based_availability);
      this.$emit('input', this.contractual_availability_energy_based);
    },
    label(field) {
      return this.$t(`activerecord.attributes.wind/availability_calculation.${field}`)
    }
  }
}
</script>
<template lang="pug">
#wind-contractual-availability-selector
  .overline.d-inline-block {{ $t('activerecord.attributes.wind/availability_calculation.contractual_availability_calculation') }}
  v-row
    v-col(cols="2")
      v-select(v-model="contractual_availability_selector" v-bind="sharedProps('contractual_availability_energy_based')" :items="availability_options" @change="updateAvailabilitySelectedValue")
</template>
