<script>
import UserCard from '../common/user_card.vue'

export default {
  components: {
    UserCard
  },
  props: {
    product: Object,
  },
  data() {
    return {
      user: {
        name: this.product.supplier_name,
        phone: this.product.supplier_contact_phone,
        email: this.product.supplier_contact_email,
        job_title: ""
      }
    }
  },
  methods: {
    async order_product() {
      if(confirm(this.$t('marketplace.confirmation'))) {
        await this.axios.post(`/marketplace_products/${this.product.id}/order`)
      }
    },
    closeDialog() {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<template lang="pug">
  v-card(class="mx-auto")
    v-img(:src="product.image_url" height="200px")
      div(class="text-right")
        v-btn(icon @click="closeDialog")
          v-icon mdi-close

    v-card-text(v-html="product.body_html")

    v-divider(class="pb-2")
    v-card-actions(style="padding-left: 24px !important; padding-right: 24px !important;")
      UserCard(:user="user")
        v-chip.pointer(pill outlined)
            v-icon(left) mdi-account-circle-outline
            | Contact: {{ user.name }}
      v-spacer
      v-btn(color="light-blue" text @click="order_product") {{ $t("marketplace.add_now") }}
</template>
