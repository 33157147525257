<script>
import ProductCard from './card.vue'
import Form from './form.vue'

export default {
  components: {
    ProductCard,
    Form
  },
  props: {
    products: Array,
    product: Object,
    is_admin: Boolean,
    tags: Array
  },
  data() {
    return {
      show_dialog_create: false,
      products_data: this.products,
      product_data: this.product,
      chips: [],
      tags_data: this.tags,
    }
  },
  methods: {
    remove_product(product) {
      const index = this.products_data.findIndex(e => e.id == product.id)
      this.products_data.splice(index, 1);
    },
    remove_tag(tag) {
      this.chips.splice(this.chips.indexOf(tag), 1)
    },
    add_tags(tags) {
      const new_tags = tags.filter(tag => !this.tags_data.find(item => item.name == tag.name))
      this.tags_data.push(...new_tags)
    },
    changeTag (tags) {
      const new_tags = tags.map(tag => {
        if(typeof(tag) == "string") {
          const existing_tag = this.tags_data.find(item => item.name == tag)
          if(existing_tag)
            return existing_tag
          else
            return {name: tag}
        } else
          return tag
      })
      this.chips = new_tags
    },
  },
  watch: {
    product_data(product) {
      this.products_data.push(product)
      this.show_dialog_create = false
      this.add_tags(product.tags)
    },
    chips(chips) {
      if( chips.length == 0) {
        this.products_data = this.products_data.map(product => {
          return {...product, show: true}
        })
      } else {
        this.products_data = this.products_data.map(product => {
          const product_has_tags = product.tags.some( tag => chips.some(chip => chip.name == tag.name))

          if(product_has_tags)
            return product = {...product, show: true}
          else
            return product = {...product, show: false}
        })
      }
    }
  },
}
</script>

<template lang="pug">
    v-container(class="mx-auto marketplace" style="max-width: 960px")
      v-row
        v-col
          v-combobox(
            :value="chips"
            @input="changeTag"
            :items="tags_data"
            item-value="name"
            item-text="name"
            chips
            clearable
            :label="$t('marketplace.filter')"
            multiple
            solo
          )
            template( v-slot:selection="{ attrs, item, select, selected }")
              v-chip(
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove_tag(item)"
              )
                strong {{ item.name }} &nbsp;
      v-row
        v-col(v-for="(product, index) in products_data" v-if="product.show" :key="product.id" cols="12" sm="4")
          ProductCard(v-model="products_data[index]" @change_tags="add_tags" @remove_product="remove_product" :tags="tags_data" :is_admin="is_admin")

      v-dialog(v-model="show_dialog_create" width="800" height="1000px")
        Form(v-model="product_data" type="create", :tags="tags_data")

      v-btn(@click="show_dialog_create = true" id="product_create_dialog" color="light-blue" title="Create product" v-if="is_admin" fixed dark fab right bottom)
        v-icon mdi-plus
</template>
