<script>
export default {
  props: {
    value: Object,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      wind_asset: this.value
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find((a) => a.asset_class_attributes).asset_class_attributes
    }
  },
  filters: {
    pretty: (val, indent = 2) => {
      if (val === 'null') return ''
      else if (typeof val !== 'object') {
        try {
          val = JSON.parse(val)
        } catch (err) {
          console.warn('value is not JSON')
          return val
        }

        return JSON.stringify(val, null, indent)
      }
    }
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_${field}`,
        errorMessages: this.errors[`asset_class.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/asset.${field}`)
    }
  }
}
</script>
<template lang="pug">
#wind-asset
  v-textarea(@input="value => wind_asset.data_source_settings_raw = value" :value="wind_asset.data_source_settings_raw | pretty(2)" v-bind="sharedProps('data_source_settings_raw')")
  div
    p(v-html="$t('activerecord.attributes.wind/asset.data_source_settings_help')")
    pre
      | {
      |   "type": "tyde",
      |   "tyde_power_plant_alias": "HA",
      |   "api_endpoint": "https://tyde.captiva.no/dataapi",
      |   "sensor_aliases": 
      |     {
      |       "active_power_kw": "10mindata_Grd_Prod_Pwr_Avg",
      |       "wind_speed_ms": "xxx",
      |       "wind_dir_deg": "xxx",
      |       "nacelle_dir_deg": "xxx",
      |       "ambient_temp_degc": "xxx",
      |       "turbine_event_id": "xxx",
      | ...
      |     }
      | }
    br

</template>
