<script>

export default {
  name: 'tooltip',
  props: ['tip'],
}
</script>

<template lang="pug">
  v-tooltip(v-bind="{...$props, ...$attrs}")
    template(v-slot:activator="{ on }")
      span("v-on"="on")
        slot
    span(v-html="tip")
</template>
