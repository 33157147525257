<script>
export default {
  props: {
    work_items_percentage_by_component: Array,
    title: String
  },
  data() {
    return {
      options: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: this.$t('work_items.work_items_by_component')
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            size: '70%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              alignTo: 'connectors',
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            }
          },
        },
        credits: {
          enabled: false
        },
        series: [
          {
            colorByPoint: true,
            data: this.work_items_percentage_by_component
          }
        ]
      }
    }
  },
}
</script>

<template lang="pug">
v-highcharts(:options="options")
</template>
