<script>
import pareto from 'highcharts/modules/pareto'
import Highcharts from 'highcharts'

pareto(Highcharts);

export default {
  props: {
    series: Array,
    title: String,
  },
  data() {
    return {
      chart_options: {
        chart: {
          renderTo: 'container',
          type: 'column'
        },
        title: {
          text: this.title
        },
        tooltip: {
          shared: true
        },
        xAxis: {
          categories: this.series[1].names,
          crosshair: true
        },
        yAxis: [
          {
          title: {
            text: this.$t('power_plants.power_plant_age')
          },
          labels: {
            formatter: function () {
              return this.value
            }
          },
        },
        {
          title: {
            text: this.$t('general.pareto')
          },
          opposite: true,
          minPadding: 0,
          maxPadding:0,
          max: 100,
          min: 0,
          labels: {
            formatter: function () {
              return this.value + "%"
            }
          }
        }
        ],
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: this.series
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
