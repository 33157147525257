<script>
export default {
  props: {
    dateRange: Array
  },
  data() {
    return {
      menu: false,
      dates: this.dateRange,
      dateRangeDisplay: '',
    }
  },
  computed: {
    dateRangeText() {
      return `${this.dates[0]} - ${this.dates[1]}`
    },
  },
  methods: {
    onDateRangeChange() {
      const formattedDates = [this.dates[0], this.dates[1]]
      this.$emit("change", formattedDates)
    },
  },
  watch: {
    dateRange(newRange) {
      this.dates = newRange
    }
  }
}
</script>

<template lang="pug">
  v-menu(
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    style="min-width: 200px;"
  )
    template(v-slot:activator="{ on, attrs }")
      v-text-field(
        v-model="dateRangeText"
        v-bind="attrs"
        v-on="on"
        style="min-width: 200px;"
      )
    v-date-picker(
      v-model="dates"
      range
      no-title
      @change="onDateRangeChange"
    )
</template>
