<template>
    <vhighcharts :options="chartOptions" ref="prodChart" />
</template>

<script>
import getChartOptions from './chart_options.js';
import vhighcharts from '../../v_highcharts.vue';

const seriesData = (primary_series, secondary_series) => {
    if (Object.keys(primary_series).length === 0) {
        return [];
    }
    const additonalSeries = secondary_series.map(obj => ({ ...obj, type: 'spline' }));
    const series_data = [{ type: 'areaspline', ...primary_series }].concat(additonalSeries)

    return series_data;
}

export default {
    name: 'PortalChart',
    components: {
        vhighcharts,
    },
    props: {
        unit: {
            type: String,
            required: false,
        },
        primary_series: {
            type: Object,
            required: false,
            default: () => ({})
        },
        secondary_series: {
            type: Array,
            required: false,
            default: () => []
        },
        loading_timeout: {
            type: Number,
            required: false,
            default: 1500
        },
    },
    data() {
        return {
            is_loading: false,
            loading_timeout_id: null,
        };
    },
    computed: {
        isEmpty() {
            return Object.keys(this.primary_series).length === 0;
        },
        chartOptions() {
            // Returns initial empty options or basic configuration
            return {
                ...getChartOptions(this.unit),
                series: seriesData(this.primary_series, this.secondary_series)
            }
        }
    },
    watch: {
        primary_series: {
            immediate: true,
            handler() {
                this.updateChartData();
                this.updateLoadingState();
            }
        },
        secondary_series: {
            immediate: true,
            handler() {
                this.updateChartData();
            }
        }
    },
    methods: {
        updateLoadingState() {
            if (this.isEmpty && !this.loading_timeout_id) {
                // Start the loading timeout
                this.loading_timeout_id = setTimeout(() => {
                    if (this.isEmpty) {
                        this.is_loading = true;
                        this.triggerLoadingIndicator();
                    }
                }, this.loading_timeout);
            } else {
                this.is_loading = false;
                if (this.loading_timeout_id) {
                    clearTimeout(this.loading_timeout_id);
                }
                this.triggerLoadingIndicator();
            }
        },
        updateChartData() {
            if (this.$refs.prodChart && this.$refs.prodChart.ready) {
                // Assuming seriesData function updates the series in chartOptions
                this.$refs.prodChart.redraw();
            }
        },
        triggerLoadingIndicator() {
            this.$nextTick(() => {
                if (this.$refs.prodChart && this.$refs.prodChart.ready) {
                    if (this.is_loading) {
                        // Manually trigger Highcharts loading
                        this.$refs.prodChart.$children[0].chart.showLoading('Loading...');
                    } else {
                        this.$refs.prodChart.$children[0].chart.hideLoading();
                    }
                }
            });
        }
    },
    beforeDestroy() {
        if (this.loading_timeout_id) {
            clearTimeout(this.loading_timeout_id);
        }
    }
}

</script>