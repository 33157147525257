<script>
import CommentCard from '../common/comment_card.vue'
import CommentForm from '../common/comment_form.vue'
import EditLabels from './edit_labels.vue'
import { DateTime } from 'luxon'

export default {
  props: {
    labels: Array,
    workItem: Object,
    value: Boolean,
    components: Array,
    powerPlants: Array,
    allow_label_edit: Boolean,
    returnUrl: String, 
    currency_code: String,
  },
  components: {
    CommentCard,
    CommentForm,
    EditLabels,
  },
  data() {
    return {
      label_items: this.labels,
      starting_labels: [],
      loading: false,
      delete_loading: false,
      workItemData: null,
      errors: {},
      comments: [],
      history: [],
      users: [],
      menu: false,
      menu2: false,
      date: [],
    }
  },
  computed: {
    workItemLabels: {
      get() {
        return this.workItemData.work_item_labels
      },
      set(value){
        this.workItemData = {...this.workItemData, work_item_labels: value }
      }
    },
    formattedBudgetCost: {
      get() {
        return this.workItemData.budget_cost ? this.workItemData.budget_cost.toLocaleString('no-NO', {maximumFractionDigits: 0}) : '';
      },
      set(value) {
        this.workItemData.budget_cost = parseFloat(this.customParseFloat(value).toFixed(4));
      }
    },
    formattedElapsedCost: {
      get() {
        return this.workItemData.elapsed_cost ? this.workItemData.elapsed_cost.toLocaleString('no-NO', {maximumFractionDigits: 0}) : '';
      },
      set(value) {
        this.workItemData.elapsed_cost = parseFloat(this.customParseFloat(value).toFixed(4));
      }
    },
    formattedForecastCost: {
      get() {
        return this.workItemData.forecast_cost ? this.workItemData.forecast_cost.toLocaleString('no-NO', {maximumFractionDigits: 0}) : '';
      },
      set(value) {
        this.workItemData.forecast_cost = parseFloat(this.customParseFloat(value).toFixed(4));
      }
    },
    pluralizeComments() { 
      return `${this.comments.length.toString()} ${(this.comments.length === 1 ? this.$t('activerecord.models.comment.one').toLowerCase() : this.$t('activerecord.models.comment.other').toLowerCase())}`
    },
    sortedComments() {
      return this.comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    attachments: {
      get() {
        return this.workItemData.attachments || []
      },
      set(value) {
        this.workItemData = {...this.workItemData, attachments: value }
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.setWorkItemData()
        this.$emit('input', value)
      }
    },
    components_of_selected_power_plant() {
      if (this.workItemData.power_plant_id) {
        const powerPlant = this.powerPlants.find(plant => plant.id === this.workItemData.power_plant_id)
        if (powerPlant) {
          return powerPlant.components_translation
        }
      }
      return this.components
    },
  },
  methods: {
    setToday(menuName) {
      this.workItemData[menuName] = new Date(Date.now()).toISOString().split('T')[0]
    },
    formatDate(timestamp){
      return DateTime.fromISO(timestamp).toFormat('yyyy-MM-dd HH:mm')
    },
    customParseFloat(str) {
      if (typeof str === 'number') return str;
      if (typeof str !== 'string') return 0;

      const cleanStr = str.replace(/\s+/g, '').replace(',', '.');
      return parseFloat(cleanStr) || 0;
    },
    onClickResponsible(e) {
      e.preventDefault()
      if (!(this.workItemData.power_plant_id || this.powerPlants[0].id)) {
        alert(this.$t('work_items.validations.select_power_plant_first'))
      }
    },
    async getUsers(power_plant_id) {
      if(power_plant_id) {
        const url = `/work_items/users_for_power_plant?power_plant_id=${power_plant_id}`
        const response = await this.axios.get(url)

        // Try and find a matching responsible
        if(this.users.length > 0) {
          const oldUser = this.users.find(user => user.id === this.workItemData.company_responsible_id)
          const newUser = response.data.find(user => user.responsible_id === oldUser?.responsible_id)
          if(newUser) {
            this.workItemData.company_responsible_id = newUser.id
          } else {
            this.workItemData.company_responsible_id = null
          }
        }

        this.users = response.data
      }
    },
    async loadComments() {
      if(this.workItem.id) {
        const url = `/comments?commented_on_type=WorkItem&commented_on_id=${this.workItem.id}`
        const response = await this.axios.get(url)
        this.comments = response.data.filter(comment => !comment.soft_deleted)
      }
    },
    async loadHistory() {
      if(this.workItem.id) {
        const response = await this.axios.get(`/work_items/${this.workItem.id}/history`)
        this.history = response.data.history
      } else {
        this.history = []
      }
    },
    removeAttachment(id) {
      const attachmentIndex = this.attachments.findIndex(itm => itm.id === id)
      const copy = this.attachments.slice()
      copy.splice(attachmentIndex, 1)
      this.attachments = copy
    },
    openFileBrowser() {
      document.getElementById("file_input").click()
    },
    onChangeFileInput(files) {
      if(!files)
        return;

      files.forEach(file => {
        if(file)
          this.attachments = [...this.attachments, { file, name: file.name, url: null }]
      });
    },
    setWorkItemData() {
      this.starting_labels = this.workItem.work_item_labels != null ? this.workItem.work_item_labels.slice() : []
      this.workItemData = {...this.workItem, work_item_labels: this.workItem.work_item_labels?.slice() }
      if (this.powerPlants.length === 1)
      this.workItemData.power_plant_id = this.powerPlants[0].id
       
    },
    downloadAttachment(url) {
      window.open(url)
    },
    async deleteAttachment(id) {
      if(id == null) {
        this.removeAttachment(id)
        return;
      }
      let attachment = this.attachments.find(itm => itm.id === id)

      // set deleting on attachment at index
      attachment.deleting = !attachment.deleting
      this.attachments = [...this.attachments]
    },
    async update() {
      this.loading = true

      const formData = new FormData()
      const url = `/work_items/${this.workItem.id || ''}`
      const method = this.workItem.id ? 'patch' : 'post'

      this.attachments.filter(itm => itm.file || itm.deleting).forEach((itm) => {
        if(itm.file) {
          formData.append('work_item[attachments_attributes][][file]', itm.file)
        } else if(itm.deleting) {
          formData.append(`work_item[attachments_attributes][][file]`, "")
          formData.append(`work_item[attachments_attributes][][id]`, itm.id)
          formData.append(`work_item[attachments_attributes][][_destroy]`, itm.deleting ? 1 : 0)
        }
      })

      formData.append("work_item[title]", this.workItemData.title || '')
      formData.append("work_item[power_plant_id]", this.workItemData.power_plant_id || '')
      formData.append("work_item[company_responsible_id]", this.workItemData.company_responsible_id || '')
      formData.append("work_item[component]", this.workItemData.component || '')
      formData.append("work_item[budget_cost]", this.workItemData.budget_cost === '' ? '' : this.workItemData.budget_cost)
      formData.append("work_item[elapsed_cost]", this.workItemData.elapsed_cost === '' ? 0 : this.workItemData.elapsed_cost)
      formData.append("work_item[forecast_cost]", this.workItemData.forecast_cost === '' ? 0 : this.workItemData.forecast_cost)
      formData.append("work_item[started_on]", this.workItemData.started_on || '')
      formData.append("work_item[due_date]", this.workItemData.due_date || '')
      formData.append("work_item[unit]", this.workItemData.unit || this.currency_code )

      if(this.workItemData.description)
        formData.append("work_item[description]", this.workItemData.description || '')

      if(this.workItemData.work_item_labels) {
        const addedLabels = this.workItemData.work_item_labels.filter(label => !this.starting_labels.find(itm => itm.label_id === label.label_id))
        const deletedLabels = this.starting_labels.filter(label => !this.workItemData.work_item_labels.find(itm => itm.label_id === label.label_id))

        deletedLabels.forEach(label => {
          formData.append(`work_item[work_item_labels_attributes][][id]`, label.id)
          formData.append(`work_item[work_item_labels_attributes][][_destroy]`, 1)
        })
        addedLabels.forEach(label => {
          formData.append(`work_item[work_item_labels_attributes][][label_id]`, label.label_id)
        })
      }

      try {
        await this.axios[method](url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        const newUrl = new URL(window.location)
        newUrl.searchParams.delete('work_item_id')
        window.location = newUrl
      } catch (error) {
        this.errors = error.response?.data
        console.error(error.response?.data)
        this.loading = false
      }
    },
    async deleteWorkItem() {
      if(!confirm(`${this.$t('general.are_you_sure') }?`))
        return;

      this.delete_loading = true

      let currentUrlParams = new URLSearchParams(window.location.search)
      let powerPlantId = currentUrlParams.get("power_plant_id")

      const url = powerPlantId ? `/work_items/${this.workItem.id}?power_plant_id=${powerPlantId}` : `/work_items/${this.workItem.id}`

      try {
        let response = await this.axios.delete(url)
        let redirectUrl = response.data.redirect_url

        this.show = false
        this.delete_loading = false
        window.location = redirectUrl
      } catch (error) {
        if(error.response.status === 403)
          alert(this.$t('work_items.not_allowed_to_delete'))
        else
          alert(this.$t('work_items.error_deleting'))

        this.delete_loading = false
      }
    },
    attachmentClass(attachment) {
      return attachment.deleting ? 'attachment-deleting' : ''
    },
    sharedProps(field) {
      return {
        name: `work_item[${field}]`,
        id: `work_item_${field}`,
        errorMessages: this.errors[field],
        readonly: false,
        label: this.label(field),
        filled: true,
        hideDetails: 'auto',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.work_item.${field}`)
    },
    deleteLabel(label) {
      const idx = this.label_items.indexOf(label)
      label.deleted = true
      this.label_items.splice(idx, 1, label)
    }
  },
  watch: {
    workItem() {
      this.setWorkItemData()
    },
    async show () {
      await this.getUsers(this.workItem.power_plant_id || this.powerPlants[0].id)
      await this.loadComments();
      await this.loadHistory();

      const url = new URL(window.location)
      url.searchParams.set('work_item_id', this.workItem.id)
      window.history.pushState({}, null, url)

      if(!this.show) {
        const url = new URL(window.location)
        url.searchParams.delete('work_item_id')
        window.history.pushState({}, null, url)

        this.comments = []
        this.users = []
      }
    }
  }
}
</script>

<template lang="pug">
v-dialog(v-model="show" max-width="1200px")
  v-card.work_item_dialog(v-if="workItemData")
    v-card-title(class="headline grey lighten-2")
      span(v-if="workItem.id") {{ $t('general.edit') }}
      span(v-else) {{ $t('general.create') }}
      span.lowercase &nbsp;{{ $t('work_items.model_name') }}

    .edit-workitem-container
      .flex-grow-1
        v-form(ref="form" lazy-validation class="pl-4 pr-4")
          v-row
            v-col
              v-text-field(ref="name" v-model="workItemData.title" v-bind="sharedProps('title')" rows="2")

          v-row
            v-col
              v-textarea(ref="description" v-model="workItemData.description" v-bind="sharedProps('description')" rows="3" :auto-grow="true")

          v-row
            v-col
              v-autocomplete(v-model="workItemData.power_plant_id" v-bind="sharedProps('power_plant')" :items="powerPlants" item-value="id" item-text="name" v-on:change="getUsers")
            v-col
              v-select(v-model="workItemData.component" :items="components_of_selected_power_plant" v-bind="sharedProps('component')")

          v-row
            v-col
              v-text-field(v-model="formattedBudgetCost" v-bind="sharedProps('budget_cost')" :suffix="this.currency_code")

            v-col
              v-autocomplete(v-model="workItemData.company_responsible_id" v-bind="sharedProps('company_responsible')" :items="this.users" item-text="name" item-value="id" v-on:click="onClickResponsible" ref="responsiblePersonSelect")

          v-row
            v-col
              v-text-field(v-model="formattedElapsedCost" v-bind="sharedProps('elapsed_cost')" :suffix="this.currency_code")
            v-col
              v-text-field(v-model="formattedForecastCost" v-bind="sharedProps('forecast_cost')" :suffix="this.currency_code")

          v-row
            v-col
              div
                v-menu(
                  v-model="menu"
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  )
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field.vTextFilled_dateFix(
                      v-model="workItemData.started_on"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      filled
                      clearable
                      :label="$t('work_items.started_on')"
                      )
                  v-date-picker(
                    v-model="workItemData.started_on"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    show-week
                  )
                    v-spacer
                    v-btn(@click="menu = false") OK
                    v-btn(@click="setToday('started_on')") {{ $t('work_items.today') }}
            v-col
              div
                v-menu(
                  v-model="menu2"
                  ref="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                )
                  template(v-slot:activator="{ on, attrs }")
                    v-text-field.vTextFilled_dateFix(
                      v-model="workItemData.due_date"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      filled
                      clearable
                      :label="$t('work_items.due_date')"
                      )
                  v-date-picker(
                    v-model="workItemData.due_date"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                    show-week
                  )
                    v-spacer
                    v-btn(@click="menu2 = false") OK
                    v-btn(@click="setToday('due_date')") {{ $t('work_items.today') }}

          v-row
            v-col
              .d-flex.flex-column
                .d-flex.mb-2
                  p.flex-grow-1(v-if="this.attachments.length") {{ $t('work_items.attachments') }}
                  p.flex-grow-1(v-if="!this.attachments.length") {{ $t('work_items.no_attachments') }}
                  div(style="{position: 'relative'}")
                    v-file-input(name="attachment_input" class="d-none" v-bind="{ id: 'file_input'}" v-on:change="onChangeFileInput" accept="image/*,.pdf,.docx,.doc,.xlsx,.txt" multiple)
                    v-btn(color="primary" elevation="2" v-on:click="openFileBrowser") {{ $t('work_items.add_attachment') }}

                .d-flex(v-for="attachment in attachments" :class="attachmentClass(attachment)" style="display: grid ; grid-template-columns: repeat(2, minmax(0, 1fr))")
                  v-icon mdi-paperclip
                  .d-flex.align-center
                    p.flex-grow-1.one-line-clamp.mb-0 {{ attachment.name.trim() }}

                  .d-flex.flex-grow-1(style="justify-content: end")
                    v-icon(large class="mr-2" v-if="attachment.url && !attachment.deleting" v-on:click="downloadAttachment(attachment.url)") mdi-cloud-download-outline
                    v-btn(icon name="delete-attachment" v-on:click="deleteAttachment(attachment.id)")
                      v-icon(v-if="attachment.deleting" color="white" large) mdi-undo
                      v-icon(v-else color="red" large) mdi-delete-circle

      .flex-grow-1.mx-4
        .labels
          EditLabels(v-model="workItemLabels" :items="this.label_items", :allow_edit="allow_label_edit", v-on:deleteLabel="deleteLabel")


        div.mt-4(v-if="this.workItemData.id")
          .comments
            h2 {{ pluralizeComments }}
            br
            comment-card(v-for="comment in sortedComments" v-bind="comment" v-on:comments-updated="loadComments" :key="comment.id")

          comment-form(class="mt-4" :item_id="workItemData.id" item_type="WorkItem" v-on:comments-updated="loadComments")

        .mt-4(v-if="this.history.length")
          h4.mb-2 {{ this.$t('work_items.history')}}
            div.work-item-history
              div( v-for="history_item in history ")
                div(v-if="history_item.event === 'update-status'")
                  p.mb-0
                    span(class="font-weight-medium") {{ history_item.made_by }}
                    span {{ ` ${$t('work_items.moved_work_item_from')} ` }}
                    span(class="font-weight-medium") {{ history_item.initial_state }}
                    span {{ ` ${$t('general.to')} ` }}
                    span(class="font-weight-medium") {{ history_item.final_state }}
                  p.text-caption {{ formatDate(history_item.timestamp)  }}

                div(v-if="history_item.event === 'update-responsible_id'")
                  p.mb-0
                    span(class="font-weight-medium") {{ history_item.made_by }}
                    span {{ ` ${$t('work_items.reassigned_work_item_from')} ` }}
                    span(class="font-weight-medium") {{ history_item.assigned_from }}
                    span {{ ` ${$t('general.to')} ` }}
                    span(class="font-weight-medium") {{ history_item.assigned_to }}
                  p.text-caption {{ formatDate(history_item.timestamp)  }}

                div(v-if="history_item.event === 'create'")
                  p.mb-0
                    span.font-weight-medium {{ history_item.made_by }}
                    span {{ ` ${$t('work_items.opened_work_item')} ` }}
                  p.text-caption {{ formatDate(history_item.timestamp)  }}

    div
      v-card-actions
        v-btn(color="primary" :loading="loading" @click="update")
          | {{ $t('general.save') }}
        v-btn(v-if="this.workItem.id" :loading="delete_loading" color="red" class="white--text" @click="deleteWorkItem()")
          | {{ $t('general.delete') }}
        v-spacer
        v-btn(@click="show = false") {{ $t('general.cancel') }}
</template>
