<script>
export default {
    name: 'PortalMetrics',
}
</script>

<template lang="pug">
    div(style="display: flex; flex-wrap: wrap; padding: 0.625rem; align-items: flex-start; gap: 0.625rem; align-self: stretch; width: 90%; margin: 1em auto; padding-top: 0rem; padding-left: 5%; padding-right: 5%; background-color: #fafbfc;")
        slot
</template>
