<script>
import AlarmIcon from '../elements/alarm_icon.vue'
import OMDashboardHelpers from '../../../mixins/om_dashboard_helpers'
import { debounce } from '../../../lib/debounce.js'

export default {
  components: {
    AlarmIcon
  },
  props: [
    'items',
    'loading',
    'options',
    'totalEvents',
    'numberOfPages'
  ],
  mixins: [OMDashboardHelpers],
  data() {
    return {
      expanded: [],
      headers: [
        { text: this.$t('om_dashboard.alarm.alarm_category'), value: 'alarm_category' },
        { text: this.$t('om_dashboard.alarm.sensor_name'), value: 'sensor' },
        { text: '', value: 'expand' },
        { text: this.$t('om_dashboard.alarm.start'), value: 'starts_at' },
        { text: this.$t('om_dashboard.alarm.end'), value: 'ends_at' },
        { text: this.$t('om_dashboard.alarm.duration'), value: 'duration' }
      ],
      alarmCategoryIcons: {
        'alarm': { icon: 'mdi-alert-circle', color: 'red' },
        'warning': { icon: 'mdi-alert', color: 'orange' },
        'status': { icon: 'mdi-information', color: 'blue' },
        'command': { icon: 'mdi-cog', color: 'brown' }
      },
      searchInput: this.options.search || '',
      debouncedEmitOptions: null
    }
  },
  watch: {
    searchInput(newVal) {
      this.debouncedEmitOptions(newVal)
    },
    'options.search'(newVal) {
      if (newVal !== this.searchInput) {
        this.searchInput = newVal
      }
    }
  },
  created() {
    this.debouncedEmitOptions = debounce(this.emitOptions, 250)
  },
  beforeDestroy() {
    if (this.debouncedEmitOptions && this.debouncedEmitOptions.cancel) {
      this.debouncedEmitOptions.cancel()
    }
  },
  methods: {
    emitOptions(val) {
      this.$emit('update:options', { ...this.options, search: val, page: 1 })
    },
    clearSearch() {
      this.searchInput = ''
    },
    onOptionsChange(newOptions) {
      this.$emit('update:options', newOptions)
    },
    getAlarmIcon(alarmCategory) {
      return this.alarmCategoryIcons[alarmCategory]?.icon || 'mdi-alert-outline'
    },
    getAlarmColor(alarmCategory) {
      return this.alarmCategoryIcons[alarmCategory]?.color || 'grey'
    }
  }
}
</script>

<template lang="pug">
v-data-table(
  :headers="headers"
  :items="items"
  :options="options"
  :server-items-length="totalEvents"
  :loading="loading"
  :expanded.sync="expanded"
  :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
  @update:options="onOptionsChange"
)
  template(v-slot:top)
    div(class="d-flex align-center")
      v-text-field.ma-4(
        v-model="searchInput"
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-close-circle"
        label="Search"
        single-line
        hide-details
        clearable
        @click:append="clearSearch"
        dense
      )
  template(v-slot:item.alarm_category="{ item }")
    div.d-flex.align-center
      v-icon(:color="getAlarmColor(item.alarm_category)") {{ getAlarmIcon(item.alarm_category) }}
      span.ml-2 {{ $t(`om_dashboard.alarm.${item.alarm_category}`) }}
  template(v-slot:item.starts_at="{ item }")
    div
      | {{ formatDateTime(item.starts_at)}}
  template(v-slot:item.ends_at="{ item }")
    div
      | {{ item.ends_at === null ? $t('om_dashboard.alarm_status.still_active') : formatDateTime(item.ends_at) }}
  template(v-slot:item.duration="{ item }")
    div
      | {{ formatDuration(item.duration) }}
</template>
