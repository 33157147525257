<script>
import RequestCard from './card.vue'

export default {
  components: {
    RequestCard
  },
  props: {
    has_user_upvoted: Boolean,
    request: Object,
  },
  data() {
    return {
      dialog: false,
    }
  },

  methods: {
    votesChanged(request, amount, has_user_upvoted) {
      this.request.upvotes = amount
      this.request.has_current_user_upvoted = has_user_upvoted
      this.$emit("votesChanged", request, amount, has_user_upvoted)
    }
  }
}
</script>

<template lang="pug">
v-dialog(v-model='dialog' max-width="800px")
  template(v-slot:activator='{ on }')
    div(v-on='on')
      slot

  request-card(v-on:votesChanged="votesChanged" :request="request")
    v-divider
    v-card-actions
      .flex-grow-1
      v-btn(color='grey' @click='dialog = false' text)
        | {{ $t('general.close') }}
</template>
