<script>
export default {
  props: {
    profile_risk: Array
  },
  methods: {
    chart_options() {
      return {
        title: { text: this.$t('ppa_pricing.charts.profile_risk') },
        credits: {
          enabled: false
        },
        xAxis: { type: 'datetime' },
        yAxis: {
          labels: {
            formatter: function() {
              return this.value + '%'
            }
          },
          title: { text: '' }
        },
        plotOptions: {
          series: {
            marker: {
              radius: 1
            }
          }
        },
        series: [
          {
            name: 'Profile risk',
            type: 'column',
            data: this.profile_risk,
            yAxis: 0,
            color: 'darkblue',
            tooltip: {
              pointFormat: '{series.name} {point.y} %',
              valueDecimals: 1,
            },
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options()")
</template>
