<script>
import UserCard from '../common/user_card.vue'
import CommentCard from '../common/comment_card.vue'
import CommentForm from "../common/comment_form.vue"

export default {
  components: {
    UserCard,
    CommentCard,
    CommentForm,
  },
  props: {
    request: Object,
  },
  data() {
    return {
      upvotes: this.request.upvotes,
      upvoted: this.request.has_current_user_upvoted,
      comments: []
    }
  },
  computed: {
    pluralize_comments() { return this.comments.length.toString() + ' ' + (this.comments.length == 1 ? this.$t("activerecord.models.comment.one") : this.$t("activerecord.models.comment.other")) }
  },
  methods: {
    async upvote() {
      const url = `/feature_requests/${this.request.id}/upvote`
      const response = await this.axios.post(url)
      if(response.status == 200) {
        this.upvotes = response.data.upvotes
        this.upvoted = response.data.has_user_upvoted
        this.$emit("votesChanged", this.request, this.upvotes, this.upvoted)
      } else {
        alert('Something went wrong. Please try again or notify the staff if the problem persists.')
      }
    },
    async loadComments() {
      const url = `/comments?commented_on_type=FeatureRequest&commented_on_id=${this.request.id}`
      const response = await this.axios.get(url)
      this.comments = response.data
    },
  },
  mounted() { this.loadComments() }
}
</script>

<template lang="pug">
  v-card.show-news-item
    v-card-title.headline.grey.lighten-2(primary-title)
      | {{ request.title }}
      v-spacer
      .chips
        v-chip.mr-2(color="white") {{ request.category }}
        v-chip.mr-2(:color="request.status_color" :dark="request.status_color != 'yellow'") {{ request.status }}
        v-chip(color="white") {{ new Date(request.created_at * 1000).toLocaleString("nb-NO", {day: 'numeric', month: 'short', year: 'numeric' }) }}
    v-card-text(ref="main_content")
      div(v-html="request.body_html")
      br

      v-card-actions
        user-card(v-if="request.author" :user="request.author")
          v-chip.pointer(pill outlined)
            v-avatar(left)
              img(:src="request.author.avatar_url")
            | {{ $t('activerecord.attributes.feature_request.author') }}: {{ request.author.name }}
        v-spacer

        v-btn(@click="upvote")
          v-icon(v-if="upvoted" color="light-blue") mdi-thumb-up
          v-icon(v-else) mdi-thumb-up
          | {{upvotes}}

      br

      .comments
        h2 {{ pluralize_comments }}
        br
        comment-card(v-for="comment in comments" v-bind="comment" v-on:comments-updated="loadComments" :key="comment.id")

      comment-form(item_type="FeatureRequest" :item_id="request.id" v-on:comments-updated="loadComments")
    slot
</template>
