<script>
import PortalMetricChart from './metric_chart.vue'
export default {

    name: "PortalMetric",

    components: {
        PortalMetricChart
    },

    props: {
        title: {
            type: String,
            required: true,
            default: "...",
            // default: "Capture Rate",
        },
        value: {
            type: Number,
            required: true,
            default: 0,
        },
        unit: {
            type: String,
            required: true,
            default: "",
            // default: "MWh",
        },
        change: {
            type: Number,
            required: true,
            default: 0,
        },
        period: {
            type: String,
            required: true,
            default: ""
            // default: "last mth",
        },
        series_data: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            series: [{
                pointStart: 1,
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9]
            }]
        }
    },

    computed: {
        isPositive() {
            return this.change >= 0;
        },
        absoluteChange() {
            return Math.abs(this.change);
        },
        adjusted_value() {
            return this.unit === "MWh" ? this.value * 1000000 : this.value;
        },
        adjusted_unit() {
            return this.unit === "MWh" ? "Wh" : this.unit;
        }
    },

    methods: {
        prefix(value) {
            if (value > 9999) {
                return {
                    number: (value / 1000),
                    prefix: "k"
                }
            }
            return {
                number: value,
                prefix: ""
            }
        },
        format(number) {
            const SI_PREFIXES_CENTER_INDEX = 8;
            const siPrefixes = [
                'y', 'z', 'a', 'f', 'p', 'n', 'μ', 'm', '', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'
            ];
            if (number === 0) {
                return {
                    baseNumber: number.toString(),
                    prefix: ""
                }
            }
            const EXP_STEP_SIZE = 3;
            const base = Math.floor(Math.log10(Math.abs(number)));
            const siBase = (base < 0 ? Math.ceil : Math.floor)(base / EXP_STEP_SIZE);
            const prefix = siPrefixes[siBase + SI_PREFIXES_CENTER_INDEX];
            if (siBase === 0) {
                return {
                    baseNumber: number.toFixed(2),
                    prefix: ""
                }
            }
            const baseNumber = parseFloat((number / Math.pow(10, siBase * EXP_STEP_SIZE)).toFixed(2));
            return {
                baseNumber: baseNumber,
                prefix: prefix
            }
        }
    }
}
</script>

<template lang="pug">
  div(style="flex: 1; padding: 1.5rem; border-radius: 0.75rem; border: 1px solid var(--gray-200, #EAECF0); box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);")
    .captiva-metric-item
        .captiva-metric-item-title {{ title }}
        .captiva-metric-item-number-and-chart
            .captiva-metric-item-number-and-badge
                div
                    span.captiva-metric-item-number {{ format(adjusted_value).baseNumber }}
                    span &nbsp;
                    span.captiva-metric-item-number-unit {{ format(adjusted_value).prefix }}{{ adjusted_unit }}
                .captiva-metric-item-change-and-period
                    div.captiva-metric-item-badge(:class="isPositive ? 'badge-positive' : 'badge-negative'")
                        svg.captiva-metric-item-badge-arrow(v-if="isPositive", xmlns="http://www.w3.org/2000/svg", width="12", height="12", viewBox="0 0 12 12", fill="none")
                            path(d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6", stroke="#1B437A", stroke-width="1.5", stroke-linecap="round", stroke-linejoin="round")
                        svg.captiva-metric-item-badge-arrow(v-else, xmlns="http://www.w3.org/2000/svg", width="12", height="12", viewBox="0 0 12 12", fill="none")
                            path(d="M5 1.5L5 8.5M5 8.5L8.5 5M5 8.5L1.5 5", stroke="black", stroke-width="1.5", stroke-linecap="round", stroke-linejoin="round")
                        div.captiva-metric-item-badge-text {{ (absoluteChange * 100).toFixed(1) }}&nbsp;%
                    .captiva-metric-item-period {{ period }}
            .captiva-metric-item-chart
                portal-metric-chart(style="width: 100%; height: 100%;", :series_data="series_data", :is_positive="isPositive")
</template>
