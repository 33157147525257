<script>

export default {
  props: {
    value: Object,
    sharedProps: Function,
    currencyCodes: Array,
  },
  data() {
    return {
      self_consumption: this.value
    }
  },
  watch: {
    value(newValue) {
      this.self_consumption = newValue
    },
    self_consumption: {
      handler() {
        this.$emit("input", this.self_consumption)
      },
      deep: true
    }
  },
}
</script>

<template lang="pug">
  v-row(v-if="self_consumption")
    v-col(cols="12" sm="3")
      v-select(
        v-model="self_consumption.currency_code"
        :items="currencyCodes"
        v-bind="sharedProps('self_consumption_data.currency_code')"
      )
      v-checkbox(
        v-model="self_consumption.grid_injection_spot"
        v-bind="sharedProps('self_consumption_data.grid_injection_spot')"
      )

    v-col(cols="12" sm="3")
      v-text-field(
        v-if="!self_consumption.grid_injection_spot"
        v-model="self_consumption.grid_injection_price_mwh"
        v-bind="sharedProps('self_consumption_data.grid_injection_price_mwh')"
      )
      v-text-field(
        v-model="self_consumption.self_consumption_price_mwh"
        v-bind="sharedProps('self_consumption_data.self_consumption_price_mwh')"
      )
      v-text-field(
        v-model="self_consumption.generator_auxiliary_consumption_price_mwh"
        v-bind="sharedProps('self_consumption_data.generator_auxiliary_consumption_price_mwh')"
      )
</template>