<script>
export default {
  data() {
    return {
      inverter_types: [],
      suppliers: [],
      errors: {},
      errorMessage: ''
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const suppliers = await this.axios.get('/admin/suppliers')
      if (suppliers.data) {
        this.suppliers = suppliers.data.map((s) => {
          return { value: s.id, text: s.name }
        })
      } else {
        this.suppliers = []
      }
      const getInverterTypes = await this.axios.get('/admin/solar/inverter_types')
      if (getInverterTypes.data) this.inverter_types = getInverterTypes.data
    },
    sharedProps(field) {
      return {
        name: `solar_inverter_type[${field}]`,
        id: `solar_inverter_type_attributes_${field}`,
        readonly: false,
        errorMessages: this.errors[field],
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: 'mr-1 mb-2' // : 'd-none'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/inverter_type.${field}`)
    },
    addNew() {
      this.inverter_types.push({})
    },
    async saveInverterType(inverterType, index) {
      let method = 'post'
      this.errors = []
      let url = '/admin/solar/inverter_types'
      if (inverterType.id) {
        method = 'patch'
        url = `/admin/solar/inverter_types/${inverterType.id}`
      }
      try {
        const saved = await this.axios({
          method: method,
          url: url,
          data: {
            solar_inverter_type: inverterType
          }
        })
        if (saved.status === 201) {
          this.removeRow(index)
          this.inverter_types.push(saved.data)
        } else if (saved.status === 200) {
          this.inverter_types.filter((it) => it.id === inverterType.id)[0] = saved.data
        }
      } catch (error) {
        this.errors = error.response.data
      }
    },
    async destroy(inverterType) {
      if (inverterType.id) {
        if (confirm(this.$t('general.are_you_sure'))) {
          try {
            const deleteInverterType = await this.axios.delete(
              `/admin/solar/inverter_types/${inverterType.id}`
            )
            if (deleteInverterType.status === 204)
              this.inverter_types.splice(this.inverter_types.indexOf(inverterType), 1)
          } catch (error) {
            this.errorMessage = error
          }
        }
      }
    },
    removeRow(index) {
      this.inverter_types.splice(index, 1)
    }
  }
}
</script>

<template lang="pug">
#solar-inverter-types
  .mt-4.mb-6.ml-0
    .overline.d-inline-block Inverter types
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | {{ $t('general.add') }}
  .mb-1.error--text(v-if="errorMessage") {{ errorMessage }}
  v-row(v-for="(inverterType, index) in inverter_types" :key="inverterType.id" :class="{'opacity-50': inverterType._destroy}")
    v-col(cols="12").py-2
      v-row(dense)
        v-col(cols="2").py-0
          v-select(v-model="inverterType.supplier_id" v-bind="sharedProps('supplier_id')" :items="suppliers || []" :label="$t('activerecord.attributes.solar/inverter_type.supplier')")
        v-col(cols="2").py-0
          v-text-field(v-model="inverterType.model" v-bind="sharedProps('model')")
        v-col(cols="2").py-0
          v-text-field(v-model="inverterType.nominal_power_kw" v-bind="sharedProps('nominal_power_kw')" suffix="kW")
        v-col(cols="2").py-0
          v-text-field(v-model="inverterType.maximal_power_kva" v-bind="sharedProps('maximal_power_kva')" suffix="kVA")
        v-col(cols="2").py-0
          v-text-field(v-model="inverterType.mppt_count" v-bind="sharedProps('mppt_count')")
        v-col(cols="2" class="d-flex align-center").py-0
          v-btn.mr-1(:disabled="(Object.keys(inverterType).length > 0) ? false : true" @click="saveInverterType(inverterType, index)" small)
            | {{ $t('general.save') }}
          v-btn(color="error" small @click="inverterType.id ? destroy(inverterType) : removeRow(index)")
            span(v-if="inverterType.id") {{ $t('general.delete') }}
            span(v-else) {{ $t('general.cancel') }}
</template>
