<script>

export default {
  props: {
    options: Array,
    selected: Array,
    year: String,
    limit: Number,
    headline: String,
    redirect_url: String
  },
  data() {
    return {
      current_selection: this.selected,
      visible: false,
    }
  },
  computed: {
    currentIds() { return this.current_selection.map(x => x.value) },
    defaultIds() { return this.selected.map(x => x.value) },
    unchanged() { return JSON.stringify(this.currentIds) == JSON.stringify(this.defaultIds) },
    available() { return this.options.length > this.limit },
    headline_text() { return this.unchanged ?  this.headline : this.$t('portfolio_stats.click_to_refresh') },
  },
  methods: {
    blurEvent() {
      if(!this.unchanged) {
        let url = `${this.$props.redirect_url}?power_plant_ids=${this.currentIds.join(',')}&year=${this.year}`
        window.location = url
      }
    }
  },
}
</script>

<template lang="pug">
div
  h1.d-inline-block(:class="{'green--text': !unchanged}")
    | {{ headline_text }}
    v-btn(v-if="available && !visible" @click="visible = true" color="light-blue" text x-small) {{ $t('power_plants.change_selection') }}
  v-combobox(v-if="available && visible" v-model="current_selection" :items="options" @blur="blurEvent" :label="$t('power_plants.change_selection')" multiple chips clearable deletable-chips small-chips hide-selected outlined)
</template>
