
export function calc_merchant_capture_rates(asset_class_type, price_area) {
    // historical_data, time_periods
    // Monthly merchant capture rates
    const WIND = {
        NO1: [93.05, 95.74, 97.28, 98.83, 100.38, 101.52, 102.67, 103.81, 98.43, 93.05, 87.67, 90.36],
        NO2: [94.99, 96.51, 96.56, 96.62, 96.68, 95.95, 95.22, 94.49, 93.65, 92.80, 91.96, 93.47],
        NO3: [89.23, 91.60, 89.30, 87.00, 84.69, 85.21, 85.73, 86.25, 85.66, 85.08, 84.49, 86.86],
        NO4: [94.69, 94.49, 94.13, 93.76, 93.40, 92.89, 92.38, 91.86, 92.94, 94.01, 95.08, 94.89],
        NO5: [89.23, 91.60, 89.30, 87.00, 84.69, 85.21, 85.73, 86.25, 85.66, 85.08, 84.49, 86.86],
        SE1: [83.17, 86.93, 83.54, 80.16, 76.77, 79.27, 81.78, 84.28, 81.41, 78.53, 75.66, 79.42],
        SE2: [80.55, 85.45, 81.64, 77.82, 74.00, 76.18, 78.35, 80.52, 77.27, 74.01, 70.76, 75.66],
        SE3: [83.19, 86.22, 83.89, 81.57, 79.24, 79.80, 80.37, 80.93, 79.66, 78.40, 77.13, 80.16],
        SE4: [81.89, 83.51, 82.26, 81.02, 79.77, 79.78, 79.79, 79.80, 79.42, 79.04, 78.65, 80.27],
        DK1: [79.48, 81.41, 81.40, 81.38, 81.37, 81.27, 81.17, 81.07, 79.25, 77.43, 75.61, 77.54],
        DK2: [79.43, 80.26, 80.54, 80.83, 81.11, 81.46, 81.80, 82.14, 80.68, 79.23, 77.77, 78.60],
        FI: [83.08, 85.54, 83.55, 81.55, 79.56, 79.22, 78.88, 78.54, 78.41, 78.28, 78.14, 80.61],
        CH: [80.13, 80.59, 81.60, 82.61, 83.62, 83.93, 84.25, 84.56, 82.77, 80.99, 79.20, 79.67],
        DE: [80.13, 80.59, 81.60, 82.61, 83.62, 83.93, 84.25, 84.56, 82.77, 80.99, 79.20, 79.67],
    }
    const SOLAR = {
        NO1: [93.41, 96.25, 102.71, 109.17, 115.63, 115.20, 114.76, 114.33, 105.47, 96.61, 87.75, 90.58],
        NO2: [96.20, 96.77, 100.31, 103.86, 107.41, 106.88, 106.35, 105.83, 102.24, 98.66, 95.07, 95.64],
        NO3: [80.76, 79.05, 90.27, 101.48, 112.70, 110.97, 109.24, 107.51, 99.73, 91.96, 84.19, 82.47],
        NO4: [80.76, 79.05, 90.27, 101.48, 112.70, 110.97, 109.24, 107.51, 99.73, 91.96, 84.19, 82.47],
        NO5: [96.20, 96.77, 100.31, 103.86, 107.41, 106.88, 106.35, 105.83, 102.24, 98.66, 95.07, 95.64],
        SE1: [80.76, 79.05, 90.27, 101.48, 112.70, 110.97, 109.24, 107.51, 99.73, 91.96, 84.19, 82.47],
        SE2: [83.50, 85.73, 94.96, 104.19, 113.42, 112.49, 111.55, 110.62, 100.09, 89.56, 79.03, 81.26],
        SE3: [93.41, 96.25, 102.71, 109.17, 115.63, 115.20, 114.76, 114.33, 105.47, 96.61, 87.75, 90.58],
        SE4: [96.20, 96.77, 100.31, 103.86, 107.41, 106.88, 106.35, 105.83, 102.24, 98.66, 95.07, 95.64],
        DK1: [96.29, 95.94, 94.42, 92.90, 91.38, 91.48, 91.57, 91.67, 93.44, 95.22, 97.00, 96.64],
        DK2: [97.54, 98.31, 96.92, 95.52, 94.13, 94.34, 94.55, 94.76, 95.17, 95.59, 96.00, 96.77],
        FI: [104.09, 105.49, 110.72, 115.96, 121.19, 119.78, 118.38, 116.97, 111.75, 106.52, 101.29, 102.69],
        CH: [87.91, 85.50, 86.49, 87.48, 88.47, 91.08, 93.69, 96.30, 95.11, 93.92, 92.73, 90.32],
        DE: [90.68, 90.20, 87.87, 85.53, 83.20, 84.50, 85.80, 87.09, 88.61, 90.13, 91.65, 91.17],
    }
    const HYDRO = {
        NO1: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        NO2: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        NO3: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        NO4: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        NO5: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        SE1: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        SE2: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        SE3: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        SE4: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        DK1: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        DK2: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        FI: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        CH: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90],
        DE: [90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90]
    }
    const MAP = {
        ["Wind::Asset"]: WIND,
        ["Solar::Asset"]: SOLAR,
        ["Hydro::Asset"]: HYDRO,
    }
    const mapped_value = [...MAP[asset_class_type][price_area]]
    return mapped_value.map((value, index) => value / 100)
}

export function calc_merchant_variable_capture_rate(hedged_volume_factor, merchant_capture_rate) {
    const CUTOFF = 0.6
    return (CUTOFF - hedged_volume_factor) * merchant_capture_rate
}

export const MODUS = Object.freeze({
    HISTORIC: "historic",
    RISK: "risk",
    FORECAST: "forecast"
});

export const HISTORIC_SERIES = [
    ["Prices", "REVIEW__BUDGET_PRICE", "Budget Price", "EUR/MWh", "Budgeted achieved price."],
    ["Prices", "REVIEW__ACHIEVED_PRICE", "Achieved Price", "EUR/MWh", "Total revenues from power sales (day-ahead market and hedged) divided by total volume produced."],
    ["Prices", "REVIEW__MERCHANT_CAPTURE_RATE", "Merchant Capture Rate", "", "Merchant price / Baseload price."],
    ["Prices", "REVIEW__ACHIEVED_CAPTURE_RATE", "Achieved Capture Rate", "", "Achieved price / Baseload price."],

    ["Production", "REVIEW__BUDGET_VOLUME", "Budget Volume", "MWh", "Budgeted production over the period."],
    ["Production", "REVIEW__VOLUME", "Volume", "MWh", "Actual production over the period."],
    ["Production", "REVIEW__HEDGED_VOLUME", "Hedged Volume", "MWh", "Hedged production over the period."],
    ["Production", "REVIEW__FULL_LOAD_FACTOR", "Full Load Factor", "", "Average hourly production over the month / Installed production capacity."],

    ["Revenue", "REVIEW__BUDGET_REVENUE", "Budget Revenue", "EUR", "The budgeted reveneues."],
    ["Revenue", "REVIEW__HEDGE_REVENUE", "Hedge Revenue", "EUR", "Fixed revenues from hedge contracts."],
    ["Revenue", "REVIEW__NET_VARIABLE_REVENUE", "Net Variable Revenue", "EUR", "Net revenues from day-ahead market sales."],
    ["Revenue", "REVIEW__TOTAL_REVENUE", "Total Revenue", "EUR", "The sum of hedge and day-ahead market revenues."],
]

export const HISTORIC_SERIES_NAMES = HISTORIC_SERIES.map(series => series.slice(0, 2))

export const FORMATTED_HISTORIC_SERIES_NAMES = HISTORIC_SERIES.reduce((acc, [group, series_name, formatted_name, unit]) => {
    acc[series_name] = formatted_name
    return acc
}, {})

export const HISTORIC_UNITS = HISTORIC_SERIES.reduce((acc, [group, series_name, formatted_name, unit]) => {
    acc[series_name] = unit
    return acc
}, {})


export const RISK_SERIES = [
    ["Benchmark", "REVIEW__SPOT_PRICE", "Spot price", "EUR/MWh", "Actual average day-ahead market price for the price area."],

    ["Benchmark", "REVIEW__MERCHANT_REVENUE", "Merchant Revenue", "EUR", "The sales revenue if all production volume was sold in the day-ahead market."],
    ["Benchmark", "REVIEW__MERCHANT_PRICE", "Merchant Price", "EUR/MWh", "Merchant revenue divided by total volume produced."],
    ["Benchmark", "REVIEW__ACHIEVED_PRICE", "Achieved Price", "EUR/MWh", "Total revenues from power sales (day-ahead market and hedged) divided by total volume produced."],

    ["Revenue", "REVIEW__TOTAL_REVENUE", "Total Revenue", "EUR", "The sum of hedge and day-ahead market revenues."],
    ["Revenue", "REVIEW__HEDGE_REVENUE", "Hedge Revenue", "EUR", "Fixed revenues from hedge contracts."],
    ["Revenue", "REVIEW__NET_VARIABLE_REVENUE", "Net Variable Revenue", "EUR", "Net revenues from day-ahead market sales."],

    ["Risk", "REVIEW__HEDGED_VOLUME_FACTOR", "Hedged Volume Factor", "", "Monhtly hedged volume / Monthly budgeted volume."],
    // ["Risk", "REVIEW__HEDGED_VOLUME_HOURLY", "Hedged Volume (Hourly)", "MW", "Hedged production per hour over the period."],
    ["Risk", "REVIEW__HEDGED_VOLUME", "Hedged Volume", "MWh", "Hedged production over the period."],
    ["Risk", "REVIEW__HEDGE_PRICE", "Hedge Price", "EUR/MWh", "Volume weighted price of hedge contracts."],
    ["Risk", "REVIEW__MISSING_VOLUME", "Missing Volume", "MWh", "Volume bought back in the day-ahead markets."],
    ["Risk", "REVIEW__EXCESS_VOLUME", "Excess Volume", "MWh", "Unhedged volume sold in the day-ahead markets."],
    ["Risk", "REVIEW__MISSING_VOLUME_PRICE", "Missing Volume Price", "EUR/MWh", "Volume weighted average price for missing volume purchases."],
    ["Risk", "REVIEW__EXCESS_VOLUME_PRICE", "Excess Volume Price", "EUR/MWh", "Volume weighted average price for excess volume sales."],
]

export const RISK_SERIES_NAMES = RISK_SERIES.map(series => series.slice(0, 2))

export const FORMATTED_RISK_SERIES_NAMES = RISK_SERIES.reduce((acc, [group, series_name, formatted_name, unit]) => {
    acc[series_name] = formatted_name
    return acc
}, {})

export const RISK_UNITS = RISK_SERIES.reduce((acc, [group, series_name, formatted_name, unit]) => {
    acc[series_name] = unit
    return acc
}, {})


export const FORECAST_SERIES = [
    ["Budget", "FCAST__BUDGET_REVENUE", "Budget Revenue", "EUR", "Total budgeted revenues (day-ahead market and hedge contracts)."],
    ["Budget", "FCAST__BUDGET_VOLUME", "Budget Volume", "MWh", "Budgeted production over the period."],
    ["Budget", "FCAST__BUDGET_PRICE", "Budget Price", "EUR/MWh", "Budgeted achieved price."],
    ["Forecast", "FCAST__MERCHANT_CAPTURE_RATE", "Merchant Capture Rate", "", "Merchant price / Baseload price."],
    ["Forecast", "FCAST__MERCHANT_REVENUE", "Merchant Revenue", "EUR", "Forecasted sales revenue if all production volume was sold in the day-ahead market."],
    ["Forecast", "FCAST__TOTAL_REVENUE", "Total Revenue", "EUR", "Captiva forecasted (hedge and day-ahead market) revenue, considering historical perfomance of the asset."],
    ["Forecast", "FCAST__HEDGE_REVENUE", "Hedge Revenue", "EUR", "Fixed revenues from hedge contracts."],
    ["Forecast", "FCAST__NET_VARIABLE_REVENUE", "Net Variable Revenue", "EUR", "Net revenues from day-ahead market sales."],
    ["Hedging", "FCAST__HEDGED_VOLUME_FACTOR", "Hedged Volume Factor", "", "Monhtly hedged volume / Monthly budgeted volume."],
    ["Hedging", "FCAST__HEDGED_VOLUME", "Hedged Volume", "MWh", "Hedged production over the period."],
    ["Prices", "FCAST__SPOT_PRICE", "Spot Price", "EUR/MWh", "Forecasted day-ahead price."],
    ["Prices", "FCAST__HEDGE_PRICE", "Hedge Price", "EUR/MWh", "Volume weighted hedge contracts price."],
]

export const FORECAST_SERIES_NAMES = FORECAST_SERIES.map(series => series.slice(0, 2))

export const FORMATTED_FORECAST_SERIES_NAMES = FORECAST_SERIES.reduce((acc, [group, series_name, formatted_name, unit]) => {
    acc[series_name] = formatted_name
    return acc
}, {})

export const FORECAST_UNITS = FORECAST_SERIES.reduce((acc, [group, series_name, formatted_name, unit]) => {
    acc[series_name] = unit
    return acc
}, {})


export const DESCRIPTIONS = {
    [MODUS.HISTORIC]: HISTORIC_SERIES.reduce((acc, [group, series_name, formatted_name, unit, description]) => {
        acc[series_name] = description
        return acc
    }, {}),
    [MODUS.RISK]: RISK_SERIES.reduce((acc, [group, series_name, formatted_name, unit, description]) => {
        acc[series_name] = description
        return acc
    }, {}),
    [MODUS.FORECAST]: FORECAST_SERIES.reduce((acc, [group, series_name, formatted_name, unit, description]) => {
        acc[series_name] = description
        return acc
    }, {}),
}


export const FORMATTED_SERIES_NAMES = {
    ...FORMATTED_HISTORIC_SERIES_NAMES,
    ...FORMATTED_RISK_SERIES_NAMES,
    ...FORMATTED_FORECAST_SERIES_NAMES
}

export const UNITS = {
    ...HISTORIC_UNITS,
    ...RISK_UNITS,
    ...FORECAST_UNITS
}

export function flattenResponses(responses) {
    const data = responses.flatMap(response => {
        const { type, power_plant_id, year, values } = response
        return Object.entries(values).map(([series_name, values]) => {
            return [type, power_plant_id, year, series_name, values]
        })
    })
    return data
}

export function createPermutations(plant_ids, years) {
    const currentYear = new Date().getFullYear(); // Get the current year

    return plant_ids.flatMap(plantId =>
        years.flatMap(year =>
            Object.values(MODUS)
                .filter(mode =>
                    // Only include valid year-mode combinations
                    // !(year > currentYear && (mode === MODUS.HISTORIC || mode === MODUS.RISK)) &&
                    !(mode === MODUS.RISK) &&
                    !(year > currentYear && mode === MODUS.HISTORIC) &&
                    !(year < currentYear && mode === MODUS.FORECAST)
                )
                .map(mode => [plantId, year, mode])
        )
    )
}
