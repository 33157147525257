<script>
import EasyMDE from 'easymde'

export default {
  props: {
    textAreaName: String,
    value: String,
    errors: Array,
  },
  data(){
    return {
      easyMde: null
    }
  },
  watch: {
    value(value) {
      if(value != this.easyMde.value()) {
        this.easyMde.value(this.value);
      }
    }
  },
  mounted() {
    this.easyMde = new EasyMDE({
      element: this.$refs.bodyTextArea, 
      spellChecker: false,
      status: false,
      forceSync: true,
      toolbar: [
        {
          name: "bold",
          action: EasyMDE.toggleBold,
          className: "fa fa-bold",
          title: "Bold",
        },
        {
          name: "italic",
          action: EasyMDE.toggleItalic,
          className: "fa fa-italic",
          title: "Italic",
        },
        {
          name: "strikethrough",
          action: EasyMDE.toggleStrikethrough,
          className: "fa fa-strikethrough",
          title: "Strikethrough",
        },
        "|",
        {
          name: "heading-1",
          action: EasyMDE.toggleHeading1,
          className: "fa fa-header fa-header-x fa-header-1",
          title: "Heading 1",
        },
        {
          name: "heading-2",
          action: EasyMDE.toggleHeading2,
          className: "fa fa-header fa-header-x fa-header-2",
          title: "Heading 2",
        },
        {
          name: "heading-3",
          action: EasyMDE.toggleHeading3,
          className: "fa fa-header fa-header-x fa-header-3",
          title: "Heading 3",
        },
        "|",
        {
          name: "preview",
          action: EasyMDE.togglePreview,
          className: "fa fa-eye no-disable",
          title: "Preview",
        },
      ]
    });
    this.easyMde.value(this.value);
    this.easyMde.codemirror.on("change", () => {
      this.$emit('input', this.easyMde.value())
    });
  }
}

</script>

<template>
  <div>
    <textarea ref="bodyTextArea" :name="textAreaName"></textarea>
    <v-alert v-for="error in errors" :key="error" dense text type="error">
        {{error}}
    </v-alert>
  </div>
</template>
