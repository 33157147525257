<script>
export default {
  props: {
    access_level: String,
    items: Array,
    errors: Boolean,
    errorMessages: Array,
  },
  data() {
    return {
      selected_value: this.access_level
    }
  },
  computed: {
    access_selection() { return ['portfolio','landowner', 'editor'].includes(this.selected_value) },
  }
}
</script>

<template lang="pug">
div
  v-label {{$t('general.user_access_level')}}
  v-radio-group(v-model="selected_value")
    v-radio(v-for="item in items" :value="item" :key="item.id" id="user_access_level" name="user[access_level]" :label="item" :errors="errors" :error-messages='errorMessages' :filled="true")
  input(v-model="selected_value" type="hidden" id="user_access_level" name="user[access_level]")
  slot(name="companies" v-if="access_selection")
  slot(name="power_plants" v-if="access_selection")
</template>
