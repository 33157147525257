<script>
import ValidateDateFormatMixin from '../mixins/validate_date_format_mixin.js'
export default {
  mixins: [ValidateDateFormatMixin],
  props: {
    id: String,
    name: String,
    label: String,
    value: String,
    hint: String,
    errorMessages: Array,
    type: String,
    min: String,
    max: String,
    readonly: { type: Boolean, default: true },
    ignoreReadonlyStyle: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    filled: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    prependIcon: String
  },
  data() {
    return {
      menu: false,
      localValue: this.value,
      selectedDate: null,
    }
  },
  mounted() {
    this.setSelectDate(this.localValue);
  },
  methods: {
    getDate(date) {
      return new Date(date).getDate()
    },
    updateLocalValue(val) {
      this.localValue = val
      this.$emit('change', val)
    },
    setSelectDate(newDate) {
      const validatedDate = this.validateDateFormat(newDate)
      if (validatedDate) {
        this.selectedDate = validatedDate;
      }
    },
    emitNewDate(){
      const newDate = this.localValue
      this.$emit('input', newDate)
      this.setSelectDate(newDate)
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    },
    selectedDate(newDate) {
      this.$emit('input', newDate)
      if (newDate === null) {
        this.menu = false
      }
    },
    localValue(newValue) {
      this.setSelectDate(newValue)
    },
  }
}
</script>

<template lang="pug">
div
  v-menu(
    v-model="menu"
    ref="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  )

    template(v-slot:activator="{ on }")
      v-text-field.vTextFilled_dateFix(
        @blur="emitNewDate"
        v-model="localValue"
        :label="label"
        v-on="on"
        :dense="dense"
        :outlined="outlined"
        :solo="solo"
        :filled="filled"
        :id="id"
        :name="name"
        hide-details="auto"
        :clearable="clearable"
        :error-messages="errorMessages"
        :readonly="readonly"
        :class="ignoreReadonlyStyle ? 'ignore-readonly-styles' : ''"
        :hint="hint"
        :persistent-hint="persistentHint"
        :prepend-icon="prependIcon"
        type="text"
        autocomplete="off"
      )

    v-date-picker(
      v-model="selectedDate"
      @input="menu = false"
      @change="updateLocalValue"
      :type="type"
      :min="min"
      :max="max"
      :first-day-of-week="1"
      :day-format="getDate"
      :locale-first-day-of-year="4"
      show-week
      no-title
      scrollable
    )
</template>
