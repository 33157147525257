<script>
export default {
  props: {
    series: Array,
  },
  data() {
    return {}
  },
  computed: {
    chart_options() {
      const categories = this.series[0].data.map(item => item.date)

      const highchartsSeries = this.series.map(serie => {
        return {
          type: serie.type,
          name: serie.name,
          visible: serie.visible,
          color: serie.color,
          data: serie.data.map(item => item.value)
        }
      })

      return {
        chart: {
          type: 'column'
        },
        title: {
          text: this.$t('work_items.work_items_by_status_by_month')
        },
        yAxis: {
          title: {
            text: this.$t('work_items.number_of_work_items')
          }
        },
        tooltip: {
          formatter: function () {
            return `Number of ${this.series.name.toLowerCase()}: ${this.point.y}`
          }
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          },
          line: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          }
        },
        xAxis: {
          categories: categories,
          crosshair: true
        },
        credits: {
          enabled: false
        },
        series: highchartsSeries
      }
    }
  }
}

</script>

<template lang="pug">
div
  v-highcharts(:options="chart_options")
</template>
