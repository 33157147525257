<script>
export default {
  data() {
    return {
      turbine_types: [],
      suppliers: [],
      errors: {},
      errorMessage: '', // general error message, not specific to a field
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const suppliers = await this.axios.get('/admin/suppliers')
      if (suppliers.data) {
        this.suppliers = suppliers.data.map((s) => {
          return { value: s.id, text: s.name }
        })
      } else {
        this.suppliers = []
      }
      const getTurbineTypes = await this.axios.get('/admin/wind/turbine_types')
      if (getTurbineTypes.data) this.turbine_types = getTurbineTypes.data
    },
    sharedProps(field) {
      return {
        name: `wind_turbine_type[${field}]`,
        id: `wine_turbine_type_attributes_${field}`,
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        errorMessages: this.errors[field],
        class: 'mr-1 mb-2' // : 'd-none'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.wind/turbine_type.${field}`)
    },
    addNew() {
      this.turbine_types.unshift({})
    },
    async saveTurbineType(turbineType, index) {
      let method = 'post'
      this.errors = []
      let url = '/admin/wind/turbine_types'
      if (turbineType.id) {
        method = 'patch'
        url = `/admin/wind/turbine_types/${turbineType.id}`
      }
      try {
        const saved = await this.axios({
          method: method,
          url: url,
          data: {
            wind_turbine_type: turbineType
          }
        })
        if (saved.status === 201) {
          this.removeRow(index)
          this.turbine_types.push(saved.data)
        } else if (saved.status === 200) {
          this.turbine_types.filter((it) => it.id === turbineType.id)[0] = saved.data
        }
      } catch (error) {
        this.errors = error.response.data
      }
    },
    async destroy(turbineType) {
      if (turbineType.id) {
        if (confirm(this.$t('general.are_you_sure'))) {
          try {
            const deleteModuleType = await this.axios.delete(`/admin/wind/turbine_types/${turbineType.id}`)
            if (deleteModuleType.status === 204)
              this.turbine_types.splice(this.turbine_types.indexOf(turbineType), 1)
          } catch (error) {
            this.errorMessage = error
          }
        }
      }
    },
    removeRow(index) {
      this.turbine_types.splice(index, 1)
    }
  }
}
</script>

<template lang="pug">
#solar-module-types
  .mt-4.mb-6.ml-0
    .overline.d-inline-block Turbine types
    v-btn.ml-4#add_module_type_button(@click="addNew" small)
      v-icon(left) mdi-plus
      | {{ $t('general.add') }}
  .mb-2.error--text(v-if="errorMessage") {{ errorMessage }}
  v-row(v-for="(turbineType, index) in turbine_types" :key="turbineType.id" :class="{'opacity-50': turbineType._destroy}")
    v-col(cols="12").py-2
      v-row(dense)
        v-col(cols="2").py-0
          v-searchable-select(v-model="turbineType.supplier_id" v-bind="sharedProps('supplier_id')" :items="suppliers || []" :label="$t('activerecord.attributes.solar/module_type.supplier')")
        v-col(cols="2").py-0
          v-text-field(v-model="turbineType.name" v-bind="sharedProps('name')")
        v-col(cols="1").py-0
          v-text-field(v-model="turbineType.rated_power_kw" v-bind="sharedProps('rated_power_kw')" suffix="kW")
        v-col(cols="1").py-0
          v-text-field(v-model="turbineType.rotor_diameter" v-bind="sharedProps('rotor_diameter')" suffix="m")
        v-col(cols="2").py-0
          v-text-field(v-model="turbineType.meteomatics_id" v-bind="sharedProps('meteomatics_id')")
        v-col(cols="4" class="d-flex align-center").py-0
          v-btn.mr-1(:disabled="(Object.keys(turbineType).length > 0) ? false : true" @click="saveTurbineType(turbineType, index)" small)
            | {{ $t('general.save') }}
          v-btn.ml-1(color="error" small @click="turbineType.id ? destroy(turbineType) : removeRow(index)")
            span(v-if="turbineType.id") {{ $t('general.delete') }}
            span(v-else) {{ $t('general.cancel') }}
          v-btn.ml-1(:href="`/admin/wind/power_curves?wind_turbine_type_id=${turbineType.id}`" small)
            | Power curves
          v-btn.ml-1(:href="`/admin/wind/event_mappings?wind_turbine_type_id=${turbineType.id}`" small)
            | Event Mappings
</template>

