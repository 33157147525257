// const seriesData = (mainSeries, secondarySeries,
// ) => {
//     if (Object.keys(mainSeries).length === 0) {
//         return [];
//     }
//     const additonalSeries = secondarySeries.map(obj => ({ ...obj, type: 'spline' }));
//     const series_data = [{ type: 'areaspline', ...mainSeries }].concat(additonalSeries)

//     return series_data;
// }

// loadingTimeout = 1000
// showChartLoadingCallback
// series: isLoading ? [] : seriesData(mainSeries, secondarySeries),
// const isLoading = mainSeries && Object.keys(mainSeries).length === 0;
// mainSeries, secondarySeries,

export default function getChartOptions(seriesUnit) {

    const SECONDARY_SERIES_OPACITY = 0.5;

    return {

        chart: {
            zoomType: 'x',
            backgroundColor: null,
            marginTop: 30,
            animation: false,
            style: {
                fontFamily: 'Roboto',
            },
            // events: {
            //     load: () => {
            //         setTimeout(() => {
            //             console.log(isLoading)
            //             if (isLoading) {
            //                 showChartLoadingCallback();
            //             }
            //         }, loadingTimeout);
            //     }
            // }
        },
        tooltip: {
            // formatter: function () {
            //     return this.points.reduce(function (s, point) {
            //         return s + '<br/>' + point.series.name + ': ' + point.y + 'm';
            //     }, '');
            // },
            style: {
                fontSize: '0.675rem',
            },

            pointFormat: "<b>{series.name}</b>: {point.y}<br/>",
            valueSuffix: ` ${seriesUnit}`,
            xDateFormat: '%b %Y',
            shadow: false,
            borderColor: null,
            borderWidth: 0,
            shared: true,
            positioner: function () {
                return { x: 70, y: 0 };
            },
            shape: 'rect'
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 0,
            lineWidth: 1,
            tickWidth: 1,
            lineColor: 'rgba(0, 0, 0, 0.1)',
            tickColor: 'rgba(0, 0, 0, 0.1)',
            tickLength: 5,
            crosshair: true,
            startOnTick: true,
            // @TODO: fix
            // min: mainSeries[0][0],
            minPadding: 0,
            labels: {
                style: {
                    fontFamily: 'Roboto',
                    color: "#92959E",
                    fontSize: '0.675rem',
                }
            }
            // offset: 4,
        },
        yAxis: {
            gridLineWidth: 0,
            lineWidth: 1,
            tickWidth: 1,
            tickPosition: 'outside',
            tickLength: 5,
            lineColor: 'rgba(0, 0, 0, 0.1)',
            tickColor: 'rgba(0, 0, 0, 0.1)',

            title: {
                align: 'high',
                offset: 15,
                rotation: 0,
                style: {
                    color: '#00261C',
                    fontSize: '.875rem'
                },
                y: -15,
                // x: -15,
                text: seriesUnit
            },
            // offset: -14,
            // minPadding:0,
            crosshair: true,
            labels: {
                style: {
                    fontFamily: 'Monospace',
                    color: "#92959E",
                    fontSize: '0.675rem',
                }
            }
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            itemMarginTop: 8,
            itemMarginBottom: 8,
            squareSymbol: false,
            labelFormatter: function () {
                let svgPath
                let strokeColor;
                const opacity = SECONDARY_SERIES_OPACITY; // Set your desired opacity here

                // Convert the series color to RGBA with the specified opacity
                var rgbaColor = function (hex, opacity) {
                    var r = parseInt(hex.slice(1, 3), 16),
                        g = parseInt(hex.slice(3, 5), 16),
                        b = parseInt(hex.slice(5, 7), 16);

                    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
                };

                switch (this.options.dashStyle) {
                    case 'shortdash':
                        strokeColor = rgbaColor(this.color, opacity);
                        svgPath = '<path d="M 0 5 L 7 5 M 9 5 L 16 5" stroke-width="1.5" stroke="' + strokeColor + '"/>';
                        break;
                    default:
                        strokeColor = this.color;
                        svgPath = '<path d="M 0 5 L 16 5" stroke-width="3" stroke="' + strokeColor + '"/>';
                        break;
                }

                return '<svg height="10" width="30">' + svgPath + '</svg> ' + '<span style="font-size: 0.875rem">' + this.name + '</span>';
            },
            useHTML: true
        },
        plotOptions: {
            series: {
                animation: false
            },
            areaspline: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, 'rgba(124, 181, 236, 0.2)'],
                        [.9, 'rgba(255, 255, 255, 1)']
                    ]
                },
                marker: {
                    enabled: false,
                },
                shadow: false,
                lineWidth: 2,
                style: {
                    fontWeight: 400,
                },
                // states: {
                //     hover: {
                //         lineWidth: 1
                //     }
                // },
                // threshold: null
            },
            spline: {
                dashStyle: 'shortdash',
                lineWidth: 1.3,
                opacity: SECONDARY_SERIES_OPACITY,
                color: "#434348",
                marker: {
                    enabled: false,
                },
            }
        },
    }
}