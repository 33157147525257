<script>
import { DateTime } from 'luxon'
export default {
  props: {
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    selectedFromDate: String,
    selectedToDate: String
  },
  data() {
    const selectedFromDateSegments = this.selectedFromDate.split('-')
    const selectedToDateSegments = this.selectedToDate.split('-')
    return {
      selectedFrom: `${selectedFromDateSegments[0]}-${selectedFromDateSegments[1]}`,
      selectedTo: `${selectedToDateSegments[0]}-${selectedToDateSegments[1]}`
    }
  },
  methods: {
    fromAllowedDates(date) {
      const thisDate = new Date(date)
      const minDate = new Date(this.minDate)
      const maxDate = new Date(this.maxDate)
      return this.minDate == null || this.maxDate == null || (thisDate >= minDate && thisDate <= maxDate)
    },
    toAllowedDates(date) {
      const minDate = new Date(this.minDate)
      const maxDate = new Date(this.maxDate)
      const selectedFromDate = new Date(this.selectedFrom)

      const thisDate = new Date(date)
      return (
        thisDate >= selectedFromDate &&
        (this.minDate == null || this.maxDate == null || (thisDate >= minDate && thisDate <= maxDate))
      )
    },
    events(date) {
      const thisDate = new Date(date)
      const fromDate = new Date(this.selectedFrom)
      const toDate = new Date(this.selectedTo)

      return thisDate > fromDate && thisDate < toDate ? 'event-class' : ''
    },
    apply() {
      const params = new URLSearchParams(window.location.search)
      params.set('from_date', `${this.selectedFrom}-01`)

      const selectedToDate = new Date(this.selectedTo)

      params.set('to_date', DateTime.fromJSDate(selectedToDate).endOf('month').toFormat('yyyy-MM-dd'))
      window.location.search = params
    }
  }
}
</script>

<template lang="pug">
  v-menu(:close-on-content-click="false" offset-y)
    template(v-slot:activator="{on}")
      v-chip(v-on="on" color="light-blue" text-color="white") {{ $t('general.date') }}: {{ selectedFrom }} - {{ selectedTo }}


    div.white.pa-2
      .d-flex.month-from-to
        div
          .text-center {{ $t('general.date_from') }}
          v-date-picker(v-model="selectedFrom" :allowed-dates="fromAllowedDates" :events="events" type="month" :show-current="selectedTo" no-title)

        div.black(style="width: 2px;")

        div
          .text-center {{ $t('general.date_to') }}
          v-date-picker(v-model="selectedTo" :allowed-dates="toAllowedDates" :events="events" type="month" :show-current="selectedFrom" no-title )

      div.d-flex.justify-end
        v-btn(@click="apply" color="primary")
          | {{ $t('general.save')}}
</template>
