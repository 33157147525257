<script>
export default {
  props: {
    id: String,
    name: String,
    label: String,
    value: String,
    hint: String,
    errors: Boolean,
    errorMessages: Array,
    type: String,
    allowedMinutes: Array,
    readonly: { type: Boolean, default: true },
    ignoreReadonlyStyle: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    filled: { type: Boolean, default: true },
    prependIcon: String,
  },
  data() {
    return {
      menu: false,
      selected_value: this.value
    }
  },
  watch: {
    value(newValue) {
      this.selected_value = newValue
    },
    selected_value () {
      this.$emit('input', this.selected_value)
    }
  },
}
</script>

<template lang="pug">
div
  v-menu(v-model="menu" ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px")
    template(v-slot:activator="{ on }")
      v-text-field.vTextFilled_dateFix(v-model="selected_value" :label="label" v-on="on" :dense="dense" :outlined="outlined" :solo="solo" :filled="filled" :id="id" :name="name" hide-details="auto" :clearable="true" :error-messages="errorMessages" :readonly="readonly" :class="ignoreReadonlyStyle ? 'ignore-readonly-styles' : ''" :hint="hint" :persistent-hint="persistentHint" :prepend-icon="prependIcon")
    v-time-picker(v-model="selected_value" @input="menu = false" :type="type" format="24hr" :allowedMinutes="allowedMinutes" full-width)
</template>
