<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      availability_targets: this.value,
      target_years: Array.from({ length: 80 }, (_, index) => 2020 + index),
      default_availability_target: 98.0
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes
        .find(a => a.asset_class_attributes)
        .asset_class_attributes.find(a => a.wind_availability_targets_attributes).wind_availability_targets_attributes
    },
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][wind_availability_targets_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_wind_availability_targets_attributes_${field}`,
        errorMessages: this.errors[`asset_class.wind_availability_targets.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none',
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.wind/availability_target.${field}`)
    },
    addNew() {
      if (!this.availability_targets) {
        this.availability_targets = [];
      }
      this.availability_targets.push({
        contractual_availability_target: this.default_availability_target,
        _destroy: false
      })
    },
    destroy(availability_target) {
      if (availability_target.id) {
        availability_target._destroy = !availability_target._destroy
      } else {
        this.availability_targets.splice(this.availability_targets.indexOf(availability_target), 1)
      }
    },
  },
}
</script>

<template lang="pug">
#wind-availability-target
  .mb-2
    .overline.d-inline-block {{ $t('activerecord.attributes.wind/availability_target.contractual_availability_target') }}
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  .mb-4(v-for="target in availability_targets" :key="target.id" :class="{'opacity-50': target._destroy}")
    .d-flex.align-center
      v-row
        v-col(cols="1")
          v-select.ml-1.mb-1(v-model="target.year" v-bind="sharedProps('year')" :items="target_years")
        v-col(cols="2")
          v-text-field.ml-1.mb-1(v-model="target.contractual_availability_target" v-bind="sharedProps('contractual_availability_target')" type="number" min="0.0" max="100.0" step=".01" suffix="%")
        v-col(cols="1")
          v-icon(color="error" @click="destroy(target)") mdi-delete
</template>