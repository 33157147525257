<script>
export default {
  props: {
    wind_turbine_type_id: Number
  },
  data() {
    return {
      power_curves: [],
      errors: {},
      errorMessage: '', // general error message, not specific to a field
      showCurvePoints: false,
      selectedPowerCurve: null,
      showCurvePointsHelp: false
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    curvePointHeaders() {
      return [
        { text: this.$t('activerecord.attributes.wind/power_curve.wind_speed_ms'), value: 'wind_speed_ms' },
        { text: this.$t('activerecord.attributes.wind/power_curve.power_kw'), value: 'power_kw' }
      ]
    },
    defaultPowerCurve() {
      return this.power_curves.find((curve) => curve.default)
    }
  },
  methods: {
    async loadData() {
      const getPowerCurves = await this.axios.get(`/admin/wind/power_curves/?wind_turbine_type_id=${this.wind_turbine_type_id}`)
      if (getPowerCurves.data) this.power_curves = getPowerCurves.data
    },
    sharedProps(field) {
      return {
        name: `wind_power_curve[${field}]`,
        id: `wine_power_curve_attributes_${field}`,
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        errorMessages: this.errors[field],
        class: 'mr-1 mb-2' // : 'd-none'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.wind/power_curve.${field}`)
    },
    addNew() {
      this.power_curves.push({ wind_turbine_type_id: this.wind_turbine_type_id, default: false })
    },
    async setDefault(curve_id) {
      this.power_curves.forEach((curve) => {
        curve.default = false
      })

      const curve = this.power_curves.find((curve) => curve.id === curve_id)
      curve.default = true

      await this.axios.post(`/admin/wind/power_curves/${curve_id}/set_default`)
    },
    async savePowerCurve(powerCurve, index) {
      let method = 'post'
      this.errors = []
      let url = '/admin/wind/power_curves'
      if (powerCurve.id) {
        method = 'patch'
        url = `/admin/wind/power_curves/${powerCurve.id}`
      }
      try {
        const saved = await this.axios({
          method: method,
          url: url,
          data: {
            wind_power_curve: powerCurve
          }
        })
        if (saved.status === 201) {
          this.removeRow(index)
          this.power_curves.push(saved.data)
        } else if (saved.status === 200) {
          this.power_curves.filter((it) => it.id === powerCurve.id)[0] = saved.data
        }
      } catch (error) {
        this.errors = error.response.data
      }
    },
    async destroy(powerCurve) {
      if (powerCurve.id) {
        if (confirm(this.$t('general.are_you_sure'))) {
          try {
            await this.axios.delete(`/admin/wind/power_curves/${powerCurve.id}`)
            this.loadData()
          } catch (error) {
            this.errorMessage = error
          }
        }
      }
    },
    removeRow(index) {
      this.power_curves.splice(index, 1)
    },
    async submitCurvePoints(powerCurve) {
      let data = new FormData()
      data.append('file', powerCurve.file)
      try {
        let result = await this.axios.post(`/admin/wind/power_curves/${powerCurve.id}/power_curve_points`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        powerCurve.wind_power_curve_points = result.data
      } catch (error) {
        this.errors = error.response.data
      }
    }
  }
}
</script>
<template lang="pug">
#solar-module-types
  .mb-2
    .overline.mt-4 {{ $t('activerecord.attributes.wind/power_curve.default') }}
    v-row
      v-col(cols="3")
        v-select(:value="defaultPowerCurve" @input="setDefault" :items="power_curves" item-text="name" item-value="id" dense outlined)

    .overline.d-inline-block {{ $t('activerecord.attributes.wind/power_curve.title') }}

    v-btn.ml-4#add_module_type_button(@click="addNew" small)
      v-icon(left) mdi-plus
      | {{ $t('general.add') }}

  .mb-2.error--text(v-if="errorMessage") {{ errorMessage }}
  .mb-4(v-for="(powerCurve, index) in power_curves" :key="powerCurve.id" :class="{'opacity-50': powerCurve._destroy}")
    .d-flex.align-center
      v-text-field(v-model="powerCurve.name" v-bind="sharedProps('name')")
      v-text-field(v-model="powerCurve.air_density_kgm3" v-bind="sharedProps('air_density_kgm3')" suffix="kg/m3")
    div.d-flex(v-if="powerCurve.id")
      .d-flex
        v-file-input.pt-2.align-self-center(
          v-model="powerCurve.file"
          v-bind="sharedProps('file')"
          accept=".xlsx"
          style="width: 20vw;"
          :prepend-icon="null"
          append-icon="mdi-help-circle-outline"
          @click:append="showCurvePointsHelp = !showCurvePointsHelp"
          dense
        )
        v-btn.align-self-center(@click="submitCurvePoints(powerCurve)")
          | {{ $t('csv_import.import') }}
      v-btn.align-self-center.ml-2(@click="showCurvePoints = !showCurvePoints; selectedPowerCurve = powerCurve")
        | {{ $t('activerecord.attributes.wind/power_curve.show') }}
    div.d-flex
      v-btn(:disabled="(Object.keys(powerCurve).length > 0) ? false : true" @click="savePowerCurve(powerCurve, index)")
        | {{ $t('general.save') }}
      v-btn.ml-2(color="error" @click="powerCurve.id ? destroy(powerCurve) : removeRow(index)")
        span(v-if="powerCurve.id") {{ $t('general.delete') }}
        span(v-else) {{ $t('general.cancel') }}
  v-dialog(v-model="showCurvePoints" max-width="500")
    v-card(v-if="selectedPowerCurve")
      v-card-title {{ selectedPowerCurve.name }}
      v-card-text
        v-simple-table
          thead
            tr
              th(v-for="header in curvePointHeaders" :key="header.text") {{ header.text }}
          tbody
            tr(v-for="point in selectedPowerCurve.wind_power_curve_points" :key="point.id")
              td {{ point.wind_speed_ms }}
              td {{ point.power_kw }}
      v-card-actions
        v-btn(@click="showCurvePoints = false")
          | {{ $t('general.close') }}

  v-dialog(v-model="showCurvePointsHelp" max-width="500")
    v-card
      v-card-title
        | {{ $t('csv_import.example_xlsx') }}
      v-card-text
        pre
          | wind_speed_ms power_kw
          | 0.0 0.0
          | 0.5 0.0
          | ...
          | 2.0 0.0
          | 2.5 9.0
          | 3.0 22.0
          | ...
          | 24.5 3300.0
          | 25.0 3300.0
          | 25.5 0.0
      v-card-actions
        v-btn(@click="showCurvePointsHelp = false")
          | {{ $t('general.close') }}
</template>
