<script>
// Small wrapper around v-radio because v-radio doesnt update the DOM node value. Possble bug?

export default {
  props: {
    id: String,
    name: String,
    label: String,
    value: String,
    hint: String,
    items: Array,
    errors: Boolean,
    errorMessages: Array,
    horizontal: { type: Boolean, default: true },
  },
  data() {
    return {
      selected_value: this.value
    }
  },
  computed: {
    wrapperClass () {
      return this.horizontal ? 'radio-group-horizontal' : ''
    },
  },
}
</script>

<template lang="pug">
div(:class="wrapperClass")
  v-label {{ label }}
  v-radio-group(v-model="selected_value")
    v-radio(
      v-for="item in items"
      :key="`${id}_${item.value || item}`"
      :value="item.value || item"
      :label="item.text || item"
      :id="`${id}_${item.value || item}`"
      :name="name"
      :hint="hint"
      :errors="errors"
      :error-messages='errorMessages'
      :filled="true"
    )
  input(
    v-model="selected_value"
    type="hidden"
    :name="name"
  )
</template>
