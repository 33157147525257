<script>
export default {
  props: {
    title: String,
    button_text: String,
    submit_form: { type: Boolean, default: false }
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
  },
  methods: {
    submitForm() {
      document.querySelector('.v-dialog form').submit()
    }
  }
}
</script>

<template lang="pug">
span
  v-btn(@click.prevent="dialog = true" x-small) {{ button_text }}
  v-dialog(v-if="dialog" v-model='dialog' max-width="800px")
    v-card.show-news-item
      v-card-title.headline.grey.lighten-2(primary-title='')
        | {{ title }}
      v-card-text
        slot
      v-divider
      v-card-actions
        v-btn(v-if="submit_form" color: "primary", @click="submitForm" color="primary") {{ $t('general.save') }}
        .flex-grow-1
        v-btn(color='grey' text='' @click='dialog = false')
          | {{ $t('general.close') }}
</template>
