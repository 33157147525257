<script>
export default {
  name: 'ChipRow',
  props: {
    alarmObject: {
      type: Object,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  }
}
</script>

<template lang="pug">
div
  v-chip(v-for="age in ['1h', '24h', '7d']" :key="age" class="alarm" target="_blank" x-small)
    | {{ age }}
</template>
