<script>
import MarketOutlookSpotPrices from '../charts/market_outlook/spot_prices.vue'
import MarketOutlookPfcPrices from '../charts/market_outlook/pfc_prices.vue'
import MarketOutlookDuration from '../charts/market_outlook/duration.vue'
import MarketOutlookBoxSimulations from '../charts/market_outlook/box_simulations.vue'
import PriceForwardCurveTable from '../charts/market_outlook/price_forward_curve_table.vue'

export default {
  name: 'MarketOutlookIndex',
  components: {
    MarketOutlookSpotPrices,
    MarketOutlookPfcPrices,
    MarketOutlookDuration,
    MarketOutlookBoxSimulations,
    PriceForwardCurveTable
  },
  props: {
    currentYear: String,
    selectedYear: String,
    areas: Array,
    isAdmin: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      spotPrices: null,
      pfcPrices: null,
      durations: null,
      simulationsBox: null,
      pfcPricesTable: null,
      spotPricesLoading: true,
      pfcPricesLoading: true,
      durationsLoading: true,
      simulationsBoxLoading: true,
      pfcPricesTableLoading: true,
      error: null
    }
  },
  methods: {
    async fetchData() {
      try {
        this.fetchSpotPrices()
        this.fetchPfcPrices()
        this.fetchPfcPricesTable()
        this.fetchDurations()
        this.fetchSimulationsBox()
      } catch (error) {
        this.error = 'An error occurred while fetching data'
      }
    },
    async fetchSpotPrices() {
      try {
        const response = await this.axios.get(`/market_outlook/spot_prices`)
        this.spotPrices = response.data
      } catch (error) {
        this.error = 'Error loading spot prices'
      } finally {
        this.spotPricesLoading = false
      }
    },
    async fetchPfcPrices() {
      try {
        const response = await this.axios.get(`/market_outlook/pfc_prices`, {
          params: { year: this.selectedYear }
        })
        this.pfcPrices = response.data
      } catch (error) {
        this.error = 'Error loading PFC prices'
      } finally {
        this.pfcPricesLoading = false
      }
    },
    async fetchPfcPricesTable() {
      try {
        const response = await this.axios.get(`/market_outlook/pfc_prices_table`, {
          params: { year: this.selectedYear }
        })
        this.pfcPricesTable = response.data
      } catch (error) {
        this.error = 'Error loading PFC prices table'
      } finally {
        this.pfcPricesTableLoading = false
      }
    },
    async fetchDurations() {
      try {
        const response = await this.axios.get(`/market_outlook/durations`, {
          params: { year: this.selectedYear }
        })
        this.durations = response.data
      } catch (error) {
        this.error = 'Error loading durations'
      } finally {
        this.durationsLoading = false
      }
    },
    async fetchSimulationsBox() {
      try {
        const response = await this.axios.get(`/market_outlook/simulations_box`, {
          params: { year: this.selectedYear }
        })
        this.simulationsBox = response.data
      } catch (error) {
        this.error = 'Error loading simulations box'
      } finally {
        this.simulationsBoxLoading = false
      }
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<template lang="pug">
v-container#market-prices
  v-container(v-if="spotPricesLoading && pfcPricesLoading && durationsLoading && simulationsBoxLoading && pfcPricesTableLoading")
    v-row
      v-col(align="center")
        v-progress-circular(indeterminate=true class="mr-2")
        | {{ $t('benchmarking.loading_please_wait') }}

  template(v-if="error")
    p {{ error }}

  v-container(v-if="!spotPricesLoading || !pfcPricesLoading || !durationsLoading || !simulationsBoxLoading || !pfcPricesTableLoading")
    h1 {{ $t('market_outlook.heading') }}

    template(v-if="currentYear === selectedYear")
      template(v-if="!spotPricesLoading")
        tooltip(:tip="$t('help_texts.market_outlook.spot_price')", bottom)
          v-card.mt-8
            v-card-title
              span {{ $t('market_outlook.spot_prices_ytd') }}
            v-card-text
              MarketOutlookSpotPrices(:prices="spotPrices", y-title="EUR/MWh")

    template(v-if="!pfcPricesLoading")
      tooltip(:tip="$t('help_texts.market_outlook.pfc_prices')", bottom)
        v-card.mt-12
          v-card-text
            MarketOutlookPfcPrices(
              :prices="pfcPrices"
              y-title="EUR/MWh"
              :year="selectedYear"
              :admin_user="isAdmin"
            )

    template(v-if="!pfcPricesTableLoading")
      tooltip(:tip="$t('help_texts.market_outlook.pfc_prices')", bottom)
        v-card.mt-12
          v-card-title
            span {{ $t('market_outlook.pfc_table') }}
          v-card-text
            PriceForwardCurveTable(
              :prices="pfcPricesTable"
              :x_axis="areas"
              :year="selectedYear"
              currency_code="EUR"
            )

    template(v-if="!durationsLoading")
      tooltip(:tip="$t('help_texts.market_outlook.duration')", bottom)
        v-card.mt-12
          v-card-text
            MarketOutlookDuration(
              :durations="durations"
              y-title-left="EUR/MWh"
              y-title-right="Pareto"
            )

    template(v-if="!simulationsBoxLoading")
      tooltip(:tip="$t('help_texts.market_outlook.simulations')", bottom)
        v-card.mt-12
          v-card-title
            span {{ $t('market_outlook.simulations') }}
          v-card-text
            MarketOutlookBoxSimulations(:data="simulationsBox", y-title="EUR/MWh")
</template>
