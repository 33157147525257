<script>
export default {
  props: {
    value: Array,
    inverterId: Number,
    form_data: Object,
    solar_fields: Array,
    solar_module_types: Array,
    errors: Object
  },
  data() {
    return {
      mppts: this.value
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes
        .find((a) => a.asset_class_attributes)
        .asset_class_attributes.find((a) => a.solar_inverters_attributes)
        .solar_inverters_attributes.find((b) => b.solar_mppts_attributes).solar_mppts_attributes
    }
  },
  methods: {
    computed_installed_kwp(index) {
      if (this.mppts[index].solar_module_type_id) {
        let solarModuleType = this.solar_module_types.filter(
          (i) => i.id === this.mppts[index].solar_module_type_id
        )[0]
        if (solarModuleType) {
          let result =
            solarModuleType.power_pmpp_w *
            this.mppts[index].modules_in_series *
            this.mppts[index].strings_in_parallel *
            0.001

          if (isNaN(result)) return null
          else return result.toFixed(2)
        }
      } else {
        return null
      }
    },
    sharedProps(field, index) {
      return {
        name: `power_plant[asset_class_attributes][solar_inverters_attributes][solar_mppts_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_solar_inverters_attributes_solar_mppts_attributes_${field}`,
        errorMessages: this.errors[`asset_class.solar_inverters.solar_mppts.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? 'mr-1 mb-2' : 'd-none'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/mppt.${field}`)
    },

    destroy(mppt) {
      if (mppt.id) {
        mppt._destroy = !mppt._destroy
        this.$forceUpdate()
      } else {
        this.mppts.splice(this.mppts.indexOf(mppt), 1)
      }
    }
  }
}
</script>
<template lang="pug">
.solar_mppt(v-if="solar_module_types.length > 0")
  | MPPTs
  .mppt_wrapper.ml-16.mb-0.pb-0(v-for="(mppt, index) in mppts" :key="mppt.id")
    v-row(:class="{'opacity-50': mppt._destroy}")
      v-col(cols="1" lg="1" md="1").mb-0.pb-0
        | ID: {{ mppt.id }}
        v-text-field(disabled :value="computed_installed_kwp(index)" :label=" $t('activerecord.attributes.solar/mppt.installed_power_kwp')")
      v-col(cols="3" lg="1" xl="1" md="2")
        v-text-field(v-model="mppt.input_number" v-bind="sharedProps('input_number', index)")
      v-col(cols="3"  lg="1" md="2")
        v-text-field(v-model="mppt.source_name" v-bind="sharedProps('source_name', index)")

      v-col(cols="3" lg="1" md="2")
        v-text-field(v-model="mppt.modules_in_series" v-bind="sharedProps('modules_in_series', index)")
      v-col(cols="3" lg="1" md="2")
        v-text-field(v-model="mppt.strings_in_parallel" v-bind="sharedProps('strings_in_parallel', index)")
      v-col(cols="3" lg="2" md="2")
        v-select(v-model="mppt.solar_field_id" v-bind="sharedProps('solar_field_id', index)" item-value="id" item-text="name" :items="solar_fields")
      v-col(cols="3" lg="3" md="4")
        v-select(v-model="mppt.solar_module_type_id" v-bind="sharedProps('solar_module_type_id', index)" :items="solar_module_types"  item-value="id" item-text="combined_name")
      v-col(cols="1")
        v-btn.mt-3(color="error" @click="destroy(mppt)" small) {{ $t('general.delete') }}
</template>
