<script>
import ChipRow from './chip_row.vue'

export default {
  name: 'EnvFlowChips',
  components: {
    ChipRow
  },
  props: {
    powerPlant: {
      type: Object,
      required: true
    }
  },
  computed: {
    alarmObject() {
      return this.powerPlant.env_flow_alarm
    },
    dataUrl() {
      const envFlowSensor = this.powerPlant.env_flow_alarm?.alarm_filter
      if (envFlowSensor) {
        return `/admin/tyde/sensor_data?tyde_id=${envFlowSensor.sensor.tyde_id}&max=${
          envFlowSensor.threshold - 0.001
        }`
      }
      return ''
    }
  }
}
</script>

<template lang="pug">
div
  ChipRow(v-if="alarmObject" :alarm-object="alarmObject" :data-url="dataUrl")
  div(v-else-if="!powerPlant.tyde_sensors?.environmental_flow")
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
      span {{ $t('om_dashboard.help.sensor_type', { sensor_type: 'environmental_flow' }) }}
  div(v-else-if="powerPlant.tyde_sensors.environmental_flow?.alarm_threshold == null")
    v-tooltip(bottom)
      template(v-slot:activator="{ on, attrs }")
        v-icon.small.grey--text.text--lighten-1(v-bind="attrs" v-on="on") mdi-minus
      span {{ $t('om_dashboard.help.env_flow_threshold') }}
</template>
