<script>
export default {
  props: {
    p50_prod: Array,
    hedge_ratio: Array,
  },
  methods: {
    chart_options() {
      return {
        title: { text: "" },
        credits: {
          enabled: false
        },
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            title: { text: '' }
          },

        ],
        plotOptions: {
          series: {
            marker: {
              radius: 1
            }
          }
        },
        tooltip: {
          split: true
        },
        series: [
          {
            name: 'P50',
            type: 'column',
            data: this.p50_prod,
            yAxis: 0,
            color: 'darkblue',
            tooltip: {
              pointFormat: '{series.name} {point.y} MWh',
              valueDecimals: 0,
            },
          },
          {
            name: 'Hedge ratio',
            type: 'line',
            data: this.hedge_ratio,
            yAxis: 0,
            color: 'red',
            tooltip: {
              pointFormat: '{series.name} {point.y} MWh',
              valueDecimals: 0,
            },
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options()")
</template>
