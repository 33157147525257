<script>
import Load from './elements/load.vue'
import Losses from './elements/losses.vue'
import Weather from './elements/weather.vue'
import AlarmIcon from './elements/alarm_icon.vue'
import Revenue from './elements/revenue.vue'
import ComponentStatus from './elements/component_status.vue'
import OMDashboardHelpers from '../../mixins/om_dashboard_helpers'

export default {
  name: 'OmOverview',
  mixins: [OMDashboardHelpers],
  components: {
    AlarmIcon,
    Load,
    Losses,
    Weather,
    Revenue,
    ComponentStatus
  },
  props: {
    currency_code: String,
    power_plants: Array,
    assetFilter: String,
    year: String,
    current_company: String
  },
  data() {
    return {
      filteredAssetClass: this.assetFilter,
      filteredPlants: this.power_plants,
      parseMethods: {
        load: (value) => (value ? parseFloat(value) : null),
        losses: (value) => (value ? parseFloat(value) : null),
        default: (value) => (value === 'N/A' ? null : value)
      },
      search: '',
      sorting: {
        sortBy: 'name',
        descending: false
      },
      table_headers: [
        {
          text: this.$t('om_dashboard.overview.status'),
          value: 'status',
          sortable: true,
          align: 'center'
        },
        {
          text: this.$t('om_dashboard.overview.name'),
          value: 'name',
          sortable: true,
          align: 'start'
        },
        {
          text: this.$t('om_dashboard.overview.asset'),
          value: 'asset',
          sortable: true,
          align: 'center'
        },
        {
          text: this.$t('om_dashboard.overview.components'),
          value: 'status_of_components',
          sortable: true,
          align: 'start'
        },
        {
          text: this.$t('om_dashboard.overview.capacity'),
          value: 'rated_power_mw',
          sortable: true,
          align: 'right'
        },
        {
          text: this.$t('om_dashboard.overview.load'),
          value: 'load_percent',
          sortable: true,
          align: 'center'
        },
        {
          text: this.$t('om_dashboard.overview.losses'),
          value: 'losses',
          sortable: true,
          align: 'center'
        },
        {
          text: this.$t('om_dashboard.overview.forecast_now'),
          value: 'forecast',
          sortable: false,
          align: 'start'
        },
        {
          text: this.$t('om_dashboard.overview.forecast_48h'),
          value: 'forecast_in48h',
          sortable: false,
          align: 'start'
        },
        {
          text: this.$t('om_dashboard.overview.revenue', { currency_code: this.currency_code }),
          value: 'revenue',
          sortable: true,
          align: 'end'
        },
        {
          text: this.$t('om_dashboard.overview.lost_revenue', { currency_code: this.currency_code }),
          value: 'lost_revenue',
          sortable: true,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    uniqueAssetClasses() {
      const assetTypes = this.power_plants.map((pp) => pp.asset_type)
      return ['All', ...new Set(assetTypes)]
    },
    showTabs() {
      return this.uniqueAssetClasses.length > 2 // "All" + at least 2 different asset classes
    }
  },
  methods: {
    filterClasses(assetClass) {
      if (assetClass === 'All') this.filteredPlants = this.power_plants
      else this.filteredPlants = this.power_plants.filter((pp) => pp.asset_type === assetClass)
    },

    changeSort(column) {
      if (this.sorting.sortBy === column) {
        this.sorting.descending = !this.sorting.descending
      } else {
        this.sorting.sortBy = column
        this.sorting.descending = false
      }

      this.applySort()
    },
    getSortableValue(item, column) {
      let value
      if (column === 'asset') {
        value = item.asset_type
      } else if (column === 'revenue') {
        value = item.adjusted_income
      } else if (column === 'status') {
        value = item.operational_status.severity
      } else if (column === 'status_of_components') {
        value = Object.values(item.status_of_components).reduce(
          (sum, num) => sum + (Number.isFinite(num) ? num : 0),
          0
        )
      } else {
        value = item[column]
      }

      const parse = this.parseMethods[column] || this.parseMethods.default
      return parse(value)
    },
    applySort() {
      this.filteredPlants.sort((a, b) => {
        const { sortBy, descending } = this.sorting
        let valueA = this.getSortableValue(a, sortBy)
        let valueB = this.getSortableValue(b, sortBy)

        // Simplify null handling and comparison in one step
        const compareResult =
          valueA === null && valueB === null
            ? 0
            : valueA === null
            ? 1
            : valueB === null
            ? -1
            : valueA > valueB
            ? 1
            : -1

        return descending ? -compareResult : compareResult
      })
    },
    drillDown(plant) {
      window.location.href = `/om_dashboard/drilldown/${plant.id}`
    },
    getAlignmentFor(columnValue) {
      const header = this.table_headers.find((header) => header.value === columnValue)
      return header ? header.align : 'left' // Default to 'left' if not specified
    }
  },
  mounted() {
    this.filterClasses(this.assetFilter)
  }
}
</script>
<template lang="pug">
v-container
  v-sheet
    h1.heading {{ $t('om_dashboard.operations') }}
    h1.subheading {{ `${current_company}, ${year}` }}
  v-sheet
    #om-dashboard2-overview
      v-tabs(v-model="filteredAssetClass")
        v-tab(
          v-if="showTabs"
          v-for="assetClass in uniqueAssetClasses"
          :key="assetClass"
          @click.prevent="filterClasses(assetClass)"
          rel="nofollow"
          :href="assetClass"
          )
          img(:src="`/om_dashboard/${assetClass}.svg`" v-if="assetClass !== 'All'")
          | {{ assetClass }}
        v-tab
          v-icon mdi-magnify
          v-text-field(v-model="search" append-icon="" label="" single-line  hide-details)
      v-card.mt-8
        v-card-text
          v-data-table#overview-table(
            :items="filteredPlants"
            :headers="table_headers"
            :search="search"
            disable-pagination
            :hideDefaultHeader="true"
            :hideDefaultFooter="true"
            @click:row="drillDown"
            )
            template(slot="header" :headers="table_headers")
              thead#om_header(class="v-data-table-header")
                tr
                  th(
                    v-for="item in table_headers"
                    :class="[item.sortable ? 'sortable' : '', item.value]"
                    @click="item.sortable ? changeSort(item.value) : ''"
                    :key="item.value"
                    :style="`text-align: ${item.align}`"
                    )
                    | {{ item.text }}
                    v-icon(
                      v-if="item.sortable && !sorting.descending && sorting.sortBy === item.value"
                      small
                      ) mdi-chevron-up
                    v-icon(
                      v-else-if="item.sortable && sorting.descending && sorting.sortBy === item.value"
                      small
                      ) mdi-chevron-down
            template(v-slot:item.status="{item, value}")
              .operational_status(:style="`text-align: ${getAlignmentFor('status')}`", class="d-flex align-center justify-center")
                AlarmIcon(
                  v-if="item && item.operational_status && item.operational_status.severity"
                  :operational_status_severity="item.operational_status.severity"
                  :alarm_category="item.operational_status.category"
                  :tech="item.asset_type.toLowerCase()" :last_updated="item.latest_calculation_timestamp"
                  )
                              
            template(v-slot:item.name="{item, value}")
              .plant_name(:style="`text-align: ${getAlignmentFor('name')}`")
                | {{ (value) }}
                v-tooltip(top v-if="item.latest_calculation_timestamp")
                  template(v-slot:activator="{ on, attrs }")
                    v-icon.ml-1.green--text.text--accent-4(v-if="item.live_data" v-on="on" v-bind="attrs") mdi-wifi
                    v-icon.ml-1(v-on="on" v-bind="attrs" v-else) mdi-wifi-off
                  
                  span
                    | {{ $t('om_dashboard.overview.last_update') }}: {{ formatDateTime(item.latest_calculation_timestamp) }}  
                span(v-else)
                  v-icon.ml-1.green--text.text--accent-4(v-if="item.live_data") mdi-wifi
                  v-icon.ml-1(v-else) mdi-wifi-off
              .metadata
                img.flag(:src="item.flag_url" width="10" height="10")
                .country_name {{ item.country_name }}
            template(v-slot:item.asset="{ item }")
              .asset_type(:style="`text-align: ${getAlignmentFor('asset')}`")
                img.asset_class(:src="`/om_dashboard/${item.asset_type}.svg`")
                | {{ item.asset_type }}
              span.hidden-md-and-up {{  item.rated_power_mw.toFixed(2) }} MW
            template(v-slot:item.status_of_components="{ item }")
              .asset_header.weather.hidden-md-and-up {{ $t('om_dashboard.overview.components') }}
              ComponentStatus(:status_of_components="item.status_of_components", :tech="item.asset_type.toLowerCase()" )
            template(v-slot:item.rated_power_mw="{ item }")
              .hidden-sm-and-down {{  item.rated_power_mw.toFixed(2) }} MW
            template(v-slot:item.load_percent="{ item }")
              .asset_header.hidden-md-and-up {{ $t('om_dashboard.overview.load') }}
              Load(:item="item" :style="`text-align: ${getAlignmentFor('load')}`")
            template(v-slot:item.losses="{ item }")
              .asset_header.hidden-md-and-up {{ $t('om_dashboard.overview.losses') }}
              Losses(:item="item" :style="`text-align: ${getAlignmentFor('losses')}`" v-if="item.asset_type.toLowerCase() !== 'hydro'")
            template(v-slot:item.forecast="{ item }")
              .asset_header.weather.hidden-md-and-up {{ $t('om_dashboard.overview.forecast_now') }}
              Weather(:weather="item.weather_now" v-if="item.weather_now" :tag="'td'")
            template(v-slot:item.forecast_in48h="{ item }")
              .asset_header.weather.hidden-md-and-up {{ $t('om_dashboard.overview.forecast_48h') }}
              Weather(:weather="item.weather_48h" v-if="item.weather_48h" :tag="'td'")
            template(v-slot:item.revenue="{ item, value }")
              .asset_header.hidden-md-and-up(v-if="item.revenue || item.adjusted_income") {{ $t('om_dashboard.overview.revenue', { currency_code: currency_code }) }}
              Revenue(:item="item" tag="div" textAlign="right")
            template(v-slot:item.lost_revenue="{ item, value }")
              .asset_header.hidden-md-and-up(v-if="item.lost_revenue") {{ $t('om_dashboard.overview.lost_revenue', { currency_code: currency_code }) }}
              Revenue(:item="item" tag="div" :isLoss="true" textAlign="right")
</template>
