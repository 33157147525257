<script>
export default {
  data() {
    return {
      userEmail: this.email,
      userPassword: this.password,
      userChangedLanguage: false,
      supportedLanguages: ['nb', 'en', 'fr', 'de'],
    }
  },
  computed: {
    languages() {
      return this.supportedLanguages.map(code => ({
        text: this.$t(`general.${code}`),
        code: code,
      }))
    },
    flagIconPath() {
      const locale = this.$i18n.locale || 'en'
      return `/flags/${locale}.svg`
    },
    resetPasswordPath() {
      if (this.userChangedLanguage) {
        const currentLocale = this.$i18n.locale || 'en'
        return `/users/new_password?locale=${currentLocale}`
      }
      return '/users/new_password'
    }
  },
  methods: {
    setLocale(newLocale, userInitiated = false) {
      this.$i18n.locale = newLocale

      if (userInitiated) {
        this.userChangedLanguage = true
        const newUrl = new URL(window.location.href)
        newUrl.searchParams.set('locale', newLocale)
        history.pushState({}, '', newUrl)
      }
    },
    initializeLanguage() {
      let chosenLang
      const urlLocale = new URLSearchParams(window.location.search).get('locale')
      const browserLang = navigator.language.split('-')[0]

      if (urlLocale && this.supportedLanguages.includes(urlLocale)) {
        chosenLang = urlLocale
      } else {
        chosenLang = this.supportedLanguages.includes(browserLang) ? browserLang : 'en'
      }

      this.setLocale(chosenLang)
    },
    async submitForm() {
      try {
        let currentLocale

        if (this.userChangedLanguage) {
          currentLocale = new URLSearchParams(window.location.search).get('locale')
        }

        if (!currentLocale || !this.supportedLanguages.includes(currentLocale)) {
          currentLocale = this.$i18n.locale || 'en'
        }

        const postData = {
          user: {
            email: this.userEmail,
            password: this.userPassword
          },
          locale: currentLocale,
          user_changed_language: this.userChangedLanguage
        }

        const response = await this.axios.post('/sessions', postData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (response.data.status === 'success') {
          window.location.href = response.data.redirect_to
          this.$toast( this.$t('general.logged_in_successfully') , {type: 'success', position: "top-center"})
        } else {
          this.$toast(response.data.message || this.$t('general.wrong_email_or_password') , {type: 'error', position: "top-center"})
          window.location.href = response.data.redirect_to
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.$toast(error.response.data.message || this.$t('general.login_failed'), {type: 'error', position: "top-center"})
        }
      }
    }
  },
  mounted() {
    this.initializeLanguage()
  },
}
</script>

<template lang="pug">
v-container#navigation.fill-height(fluid)
  v-row(align="center", justify="center")
    v-col(cols="12", md="7", lg="5", id="login-form-wrapper")
      v-row.pa-2
        v-col(cols="4", offset="4")
          v-img(src="/captiva_logo.png", id="logo")
      v-card.elevation-12
        v-form(@submit.prevent="submitForm")
          v-toolbar(color="primary", dark, flat)
            v-toolbar-title {{ $t('general.welcome') }}
            v-spacer
            v-menu#language-selector
              template(v-slot:activator="{ on, attrs }")
                v-chip.pointer.mr-2#language-chip(
                  v-bind="attrs",
                  v-on="on",
                  :title="$t('help_texts.navigation.change_language')"
                )
                  img(:src="flagIconPath", height="30", width="30")
                span {{ $t('general.language') }}:
              v-list#language-menu
                v-list-item-group(color="primary")
                  v-list-item
                    b {{ $t('navigation.language.select') }}
                  v-list-item(v-for="language in languages", :key="language.code", @click="setLocale(language.code, true)")
                    v-list-item-avatar(height="25", width="25", min-width="25")
                      v-img(:src="`/flags/${language.code}.svg`")
                    v-list-item-content
                      v-list-item-title {{ language.text }}
          v-col.col-auto
            v-card-text.pb-2
              v-row.mx-1
                label(for="email") {{ $t('general.email') }}:
              v-row.ma-0
                v-text-field(
                  v-model="userEmail",
                  id="email",
                  name="email",
                  prepend-icon="mdi-account",
                  type="email",
                  autocomplete="email"
                )
              v-row.mx-1
                label(for="password") {{ $t('general.password') }}:
              v-row.ma-0
                v-text-field(
                  v-model="userPassword",
                  id="password",
                  name="password",
                  prepend-icon="mdi-lock",
                  type="password",
                  autocomplete="current-password"
                )
          v-card-actions.mx-2.pb-4
            v-row(justify="space-between")
              v-col(cols="12", sm="6", md="6")
                v-btn(color="primary", type="submit", block) {{ $t('general.login') }}
              v-col(cols="12", sm="6", md="6")
                v-btn(:href="resetPasswordPath", block) {{ $t('general.reset_password') }}
</template>
