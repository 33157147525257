<script>
export default {
  props: {
    value: Object,
    sensor_types: Array,
    position: String
  },
  data() {
    return {
      sensor: this.value,
      saving: false,
      save_successful: false,
      save_failed: false,
      error_msg: '',
      defaults: {
        '1_custom_sensor': { type: 'chart', name: 'Custom chart 1' },
        '2_custom_sensor': { type: 'chart', name: 'Custom chart 2' },
        '3_custom_sensor': { type: 'chart', name: 'Custom chart 3' },
        '4_custom_sensor': { type: 'chart', name: 'Custom chart 4' },
        '5_custom_sensor': { type: 'chart', name: 'Custom chart 5' },
        apparent_effect: { type: 'sidebar', name: 'Apparent effect' },
        autostart_sp: { type: 'sidebar', name: 'Set point autostart' },
        environmental_flow: { type: 'chart', name: 'Environmental flow', standard: true },
        fall_loss_intake_diff: { type: 'sidebar', name: 'Fall loss intake difference' },
        fall_loss: { type: 'chart', name: 'Fall loss', needs_custom_limits: true },
        frequency: { type: 'sidebar', name: 'Frequency' },
        gen_l1_l2: { type: 'sidebar', name: 'Gen L1L2' },
        gen_l2_l3: { type: 'sidebar', name: 'Gen L2L3' },
        gen_l3_l1: { type: 'sidebar', name: 'Gen L3L1' },
        penstock_pressure: { type: 'sidebar', name: 'Penstock pressure' },
        reactive_effect: { type: 'sidebar', name: 'Reactive effect' },
        regulation: { type: 'sidebar', name: 'Regulation' },
        set_point_water_level: { type: 'sidebar', name: 'Set point water level' },
        temp_outside: { type: 'sidebar', name: 'Temperature outside' },
        turbine_1_active_power_kw: { type: 'chart', name: 'Turbine 1 active power' },
        water_level_downstream: { type: 'sidebar', name: 'Water level downstream' },
        water_level: { type: 'chart', name: 'Water level', standard: true }
      }
    }
  },
  computed: {
    conditionalDescription: {
      get() {
        if (this.sensor.description) {
          return this.sensor.description
        } else {
          return this.defaults[this.sensor.sensor_type].name
        }
      },
      set(val) {
        this.sensor.description = val
      }
    },
    isStandard() {
      return this.defaults[this.sensor.sensor_type]?.standard
    },
    needsCustomLimits() {
      const sensorType = this.sensor.sensor_type
      return sensorType && this.defaults[sensorType]?.needs_custom_limits
    },
    shouldShowCustomFields() {
      return this.sensor.custom_om_chart || this.needsCustomLimits
    },
    sensor_type_options() {
      return this.sensor_types
        .map((s) => {
          return { text: `${this.defaults[s].type}: ${s}`, value: s }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    shared_props() {
      return {
        dense: true,
        outlined: true,
        hideDetails: 'auto'
      }
    }
  },
  methods: {
    async save() {
      this.save_failed = false
      this.saving = true
      try {
        const url = `/admin/tyde_sensors/${this.sensor.id}`
        await this.axios.put(url, { tyde_sensor: this.sensor })
        this.save_successful = true
        this.$emit('markDirty', true)
        this.saving = false
      } catch (error) {
        this.errorHandler(error)
      }
    },
    errorHandler(error) {
      this.saving = false
      const response = error.response || {}
      if (response.status === 422) {
        this.error_msg = response.data.errors.join(' ')
        this.save_failed = true
      } else {
        alert('An unexpected error occurred')
      }
    }
  },
  watch: {
    'sensor.sensor_type': function (newType) {
      this.sensor.custom_om_chart = !!(newType && newType.includes('custom_sensor'))
    },
    sensor() {
      this.$emit('input', this.sensor)
    }
  }
}
</script>
<template lang="pug">
div(:class="position")
  v-row
    v-col(cols="4")
      | {{ sensor.name }}
    v-col(cols="4")
      v-select(
        v-model="sensor.sensor_type"
        :items="sensor_type_options"
        v-bind="shared_props"
        label="Sensor type"
        clearable
      )
    v-col(cols="3")
      v-text-field(
        v-if="isStandard"
        v-model="conditionalDescription"
        v-bind="shared_props"
        label="Title"
      )
      v-checkbox(
        v-else-if="sensor.sensor_type && !needsCustomLimits"
        v-model="sensor.custom_om_chart"
        v-bind="shared_props"
        label="Add as custom chart?"
      )
    v-col
      v-btn(@click="save" :disabled="saving" small) {{ $t('general.save') }}
      v-progress-circular.ml-2(v-if="saving" size="20" color="primary" indeterminate)
      v-snackbar(v-model="save_successful" :timeout="2000" color="success")
        | Sensor saved. Reload page to see the effects.
      v-snackbar(v-model="save_failed" color="error") {{ error_msg }}
  v-row(
    v-if="shouldShowCustomFields"
  )
    v-spacer
    v-col(cols="3")
      v-text-field(
        v-if="!isStandard"
        v-model="sensor.description"
        v-bind="shared_props"
        label="Chart title"
      )
    v-col(cols="2")
      v-text-field(
        :disabled="false"
        v-model="sensor.unit"
        v-bind="shared_props"
        label="Custom unit"
      )
    v-col(cols="2")
      v-text-field(
        :disabled="!shouldShowCustomFields"
        v-model="sensor.custom_upper_limit"
        v-bind="shared_props"
        label="Custom upper limit"
      )
    v-col(cols="2")
      v-text-field(
        :disabled="false"
        v-model="sensor.custom_lower_limit"
        v-bind="shared_props"
        label="Custom lower limit"
      )
</template>
