<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    paramName: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.isChecked;
      },
      set(newValue) {
        this.$emit("change", {
          paramName: this.paramName,
          value: newValue
        });
      }
    }
  }
};
</script>

<template lang="pug">
  v-checkbox(
    :label="label"
    v-model="computedValue"
    hide-details
    :false-value="false"
  )
</template>
