<script>

export default {
  props: {
    headers: Array,
    rows: Array,
    highlight: Boolean,
  },
  data() {
    return {
    }
  },
  methods: {
    RowClass () {
      return this.highlight ? 'highlight' : ''
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    formatNumber (value) {
      if (this.isNumeric(value)) return Number(value).toLocaleString('no-NO')
      else return value
    }
  },
}

</script>

<template lang="pug">
  div
    v-data-table(dense :headers="headers" :items="rows" :item-class="RowClass" :items-per-page="15")
      template(v-for="header in headers.slice(1, -1)" v-slot:[`item.${header.value}`]="{ header, value, item }")
        span(v-if="item['price_area'] !== $t('profile.average')")
          | {{ formatNumber(item[header.value].avg_price.toFixed(2)) }}
        span.font-weight-bold(v-if="item['price_area'] === $t('profile.average')") {{ formatNumber(item[header.value].avg_price.toFixed(2)) }}
      template(v-slot:item.price_area="{ item }")
        span.font-weight-bold {{ item.price_area }}
      template(v-slot:item.yearly_summary="{ item }")
        span.font-weight-bold {{ formatNumber(item.yearly_summary.avg_price.toFixed(2)) }}
</template>
