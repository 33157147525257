<script>
import { orderBy } from 'lodash'

export default {
  data() {
    return {
      module_types: [],
      suppliers: [],
      selectedSupplier: null,
      searchQuery: '',
      errors: {},
      errorMessage: '',
      CELL_TYPE_VALUES: ['monoSi', 'multiSi', 'polySi', 'cis', 'cigs', 'cdte', 'amorphous'],
      currentPage: 1,
      itemsPerPage: 50,
    }
  },
  computed: {
    filteredModuleTypes() {
      let filtered = this.module_types
      if (this.selectedSupplier) {
        filtered = filtered.filter(mt => mt.supplier_id === this.selectedSupplier)
      }
      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase()
        filtered = filtered.filter(mt => mt.model?.toLowerCase().includes(searchQueryLower))
      }

      const pendingModules = filtered.filter(mt => mt.isPending)
      const savedModules = filtered.filter(mt => !mt.isPending)

      const sortedSavedModules = orderBy(savedModules, ['supplier_id', 'power_pmpp_w'], ['asc', 'asc'])

      return pendingModules.concat(sortedSavedModules)
    },
    paginatedModuleTypes() {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.filteredModuleTypes.slice(start, end)
    },
    pageCount() {
      return Math.ceil(this.filteredModuleTypes.length / this.itemsPerPage)
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    moduleEfficiency(moduleType) {
      return (moduleType.power_pmpp_w / (moduleType.length_mm * moduleType.width_mm)) * 1000
    },
    async loadData() {
      const suppliers = await this.axios.get('/admin/suppliers')
      if (suppliers.data) {
        this.suppliers = suppliers.data.map(s => ({ value: s.id, text: s.name }))
      } else {
        this.suppliers = []
      }
      const getModuleTypes = await this.axios.get('/admin/solar/module_types')
      if (getModuleTypes.data) this.module_types = getModuleTypes.data
    },
    sharedProps(field) {
      return {
        name: `solar_module_type[${field}]`,
        id: `solar_module_type_attributes_${field}`,
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        errorMessages: this.errors[field],
        class: 'mr-1 mb-2'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/module_type.${field}`)
    },
    addNew() {
      const newModule = {
        isPending: true,
        supplier_id: null,
        model: '',
        length_mm: null,
        width_mm: null,
        power_pmpp_w: null,
        current_impp_a: null,
        voltage_uoc_v: null,
        current_isc_a: null,
        voltage_umpp_v: null,
        system_voltage_v: null,
        cell_type: 'monoSi',
        alpha_isc: null,
        beta_uoc: null,
        gamma_pmpp: null,
        noct_degc: null,
        cells_in_series: null,
      }
      this.module_types.unshift(newModule)
      this.currentPage = 1
    },
    async saveModuleType(moduleType, index) {
      let method = 'post'
      this.$set(this.errors, moduleType.id || index, [])
      let url = '/admin/solar/module_types'
      if (moduleType.id) {
        method = 'patch'
        url = `/admin/solar/module_types/${moduleType.id}`
      }

      const moduleData = { ...moduleType }
      delete moduleData.isPending

      try {
        const saved = await this.axios({
          method,
          url,
          data: {
            solar_module_type: moduleData
          }
        })
        if (saved.status === 201) {
          this.module_types.splice(index, 1)
          saved.data.isPending = false
          this.module_types.unshift(saved.data)
          this.currentPage = 1
        } else if (saved.status === 200) {
          this.module_types = this.module_types.map(it => (it.id === moduleType.id ? saved.data : it))
        }
      } catch (error) {
        this.$set(this.errors, moduleType.id || index, error.response.data)
      }
    },
    async destroy(moduleType) {
      if (moduleType.id) {
        if (confirm(this.$t('general.are_you_sure'))) {
          try {
            const deleteModuleType = await this.axios.delete(`/admin/solar/module_types/${moduleType.id}`)
            if (deleteModuleType.status === 204) this.module_types.splice(this.module_types.indexOf(moduleType), 1)
          } catch (error) {
            this.errorMessage = error
          }
        }
      } else {
        const index = this.module_types.indexOf(moduleType)
        if (index > -1) {
          this.module_types.splice(index, 1)
        }
      }
    },
    removeRow(index) {
      this.module_types.splice(index, 1)
    }
  }
}
</script>

<template lang="pug">
#solar-module-types
  v-container
    v-row(class="d-flex align-center")
      v-col(cols="12" md="6").pl-0.mb-0.py-0
        v-text-field(
          v-model="searchQuery"
          label="Search Module Type by Name"
          prepend-icon="mdi-magnify"
        )
      v-col(cols="12" md="6").pr-0.mb-0.py-0
        v-select(
          v-model="selectedSupplier"
          v-bind="sharedProps('supplier_id')"
          :items="suppliers || []"
          :label="'Filter by ' + $t('activerecord.attributes.solar/module_type.supplier').toLowerCase()"
          :menu-props="{ bottom: true, offsetY: true }"
        )
    v-row
      v-col(cols="12").ml-0.pl-0.overline.d-inline-block Module types
        v-btn.ml-4#add_module_type_button(@click="addNew" small)
          v-icon(left) mdi-plus
          | {{ $t('general.add') }}
      .mb-2.error--text(v-if="errorMessage") {{ errorMessage }}
  v-row
    v-col(cols="12" v-for="(moduleType, index) in paginatedModuleTypes" :key="moduleType.id || index" :class="{'opacity-50': moduleType._destroy}")
      v-card(color="grey lighten-4")
        v-card-text
          v-row.d-flex.align-center
            v-col(cols="12" md="2")
              v-text-field(
                :value="moduleType.isPending ? 'Pending...' : moduleType.id"
                v-bind="sharedProps('id')"
                label="ID"
                disabled
              )
            v-col(cols="12" md="2")
              v-select(
                v-model="moduleType.supplier_id"
                v-bind="sharedProps('supplier_id')"
                :items="suppliers || []"
                :label="$t('activerecord.attributes.solar/module_type.supplier')"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.model"
                v-bind="sharedProps('model')"
                :error-messages="errors[moduleType.id || index]?.model"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.length_mm"
                v-bind="sharedProps('length_mm')"
                suffix="mm"
                :error-messages="errors[moduleType.id || index]?.length_mm"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.width_mm"
                v-bind="sharedProps('width_mm')"
                suffix="mm"
                :error-messages="errors[moduleType.id || index]?.width_mm"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.power_pmpp_w"
                v-bind="sharedProps('power_pmpp_w')"
                suffix="Wp"
                :error-messages="errors[moduleType.id || index]?.power_pmpp_w"
              )
          v-row.d-flex.align-center.mt-3
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.voltage_uoc_v"
                v-bind="sharedProps('voltage_uoc_v')"
                suffix="V"
                :error-messages="errors[moduleType.id || index]?.voltage_uoc_v"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.current_isc_a"
                v-bind="sharedProps('current_isc_a')"
                suffix="A"
                :error-messages="errors[moduleType.id || index]?.current_isc_a"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.voltage_umpp_v"
                v-bind="sharedProps('voltage_umpp_v')"
                suffix="V"
                :error-messages="errors[moduleType.id || index]?.voltage_umpp_v"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.current_impp_a"
                v-bind="sharedProps('current_impp_a')"
                suffix="A"
                :error-messages="errors[moduleType.id || index]?.current_impp_a"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.system_voltage_v"
                v-bind="sharedProps('system_voltage_v')"
                suffix="V"
                :error-messages="errors[moduleType.id || index]?.system_voltage_v"
              )
            v-col(cols="12" md="2")
              v-select(
                v-model="moduleType.cell_type"
                v-bind="sharedProps('cell_type')"
                :items="CELL_TYPE_VALUES"
                :label="$t('activerecord.attributes.solar/module_type.cell_type')"
              )
          v-row.d-flex.align-center.mt-3
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.alpha_isc"
                v-bind="sharedProps('alpha_isc')"
                suffix="%/°C"
                :error-messages="errors[moduleType.id || index]?.alpha_isc"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.beta_uoc"
                v-bind="sharedProps('beta_uoc')"
                suffix="%/°C"
                :error-messages="errors[moduleType.id || index]?.beta_uoc"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.gamma_pmpp"
                v-bind="sharedProps('gamma_pmpp')"
                suffix="%/°C"
                :error-messages="errors[moduleType.id || index]?.gamma_pmpp"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.noct_degc"
                v-bind="sharedProps('noct_degc')"
                suffix="°C"
                :error-messages="errors[moduleType.id || index]?.noct_degc"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-model="moduleType.cells_in_series"
                v-bind="sharedProps('cells_in_series')"
                :error-messages="errors[moduleType.id || index]?.cells_in_series"
              )
            v-col(cols="12" md="2")
              v-text-field(
                v-bind="sharedProps('module_efficiency')"
                :value="moduleEfficiency(moduleType)"
                disabled
                suffix="%"
              )
          v-row.justify-end.align-center.mt-3
            v-col(cols="6" md="2" class="d-flex justify-end")
              v-btn.mr-2(
                :disabled="(Object.keys(moduleType).length > 0) ? false : true"
                @click="saveModuleType(moduleType, index)"
                small
              )
                | {{ $t('general.save') }}
            v-col(cols="6" md="2" class="d-flex justify-end")
              v-btn(
                color="error"
                small
                @click="moduleType.id ? destroy(moduleType) : removeRow(index)"
              )
                span(v-if="moduleType.id") {{ $t('general.delete') }}
                span(v-else) {{ $t('general.cancel') }}
  v-row
    v-col(cols="12")
      v-pagination(
        v-model="currentPage"
        :length="pageCount"
        total-visible="7"
      )
</template>
