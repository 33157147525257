<script>
// Wrapper to workaround an issue where higcharts isnt redrawing after vuetify components are rendered
export default {
  props: {
    options: Object,
    callback: Function,
    constructorType: String
  },
  data() {
    return {
      ready: false
    }
  },
  methods: {
    redraw() {
      window.dispatchEvent(new Event('resize'))
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  computed: {
    computedOptions() {
      const disableExport = { exporting: { enabled: false } }
      const tzOptions = { time: { timezone: 'Europe/Oslo' } }

      if (this.options) {
        return Object.assign(disableExport, this.options, tzOptions)
      } else {
        return tzOptions
      }
    }
  }
}
</script>

<template lang="pug">
highcharts(v-if="ready" :options="computedOptions" :constructor-type="constructorType" :callback="callback")
</template>
