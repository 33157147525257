<script>
export default {
  props: {
    prices: Array,
    x_axis: Array,
    year: String,
    currency_code: String
  },
  data() {
    const fullMonths = this.$t("date.month_names")
    return {
      chart_options: {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1,
          scrollablePlotArea: {
            minWidth: 1024,
          },
        },
        colorAxis: {
          stops: [
            [0, '#FB867C'],
            [0.5, '#FFFFFF'],
            [1, '#00FF00']
          ]
        },
        yAxis: {
          categories: this.$t("date.abbr_month_names"),
          title: null,
          reversed: true
        },
        xAxis: {
          categories: this.x_axis,
          opposite: false,   // a bit easier to read on the bottom
          labels: {
            overflow: 'allow',
            style: {
              fontWeight: 'bold',
              fontSize: '10px'
            }
          }
        },
        tooltip: {
          formatter: (p) => {
            let point = p.chart.hoverPoint
            return `<em>${fullMonths[point.y]} ${this.year}</em><br />${p.chart.xAxis[0].categories[point.x]} ${this.$t("general.price_area")}<br  /><strong>${point.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${this.currency_code}/MWh</strong>`
          }
        },
        legend: false,
        title: false,
        accessibility: {
          point: {
            descriptionFormatter: function (point) {
              return point.value
            }
          }
        },
        credits: {
          enabled: false,
        },     
        series: [{
          borderWidth: 1,
          data: this.prices,
          dataLabels: {
            enabled: true,
            color: '#000000'
          }
        }]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>