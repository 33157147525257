<script>
import { syncCharts } from '../../../charts/sync_charts.js'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    name: String,
    unit: String,
    upperThreshold: Number,
    lowerThreshold: Number
  },
  data() {
    return this.calculateChartOptions()
  },
  watch: {
    data: {
      handler() {
        this.updateChartOptions()
      },
      deep: true
    }
  },
  methods: {
    calculateChartOptions() {
      const values = Array.isArray(this.data) ? this.data.map((d) => d[1]).filter((n) => n) : []
      const max = values.length > 0 ? Math.max(...values) : 0
      const min = values.length > 0 ? Math.min(...values) : 0
      const margin = (max - min) * 0.05
      const y_max = Math.max(...[max, this.upperThreshold, this.lowerThreshold].filter((n) => n)) + margin
      const y_min = Math.min(...[min, this.upperThreshold, this.lowerThreshold].filter((n) => n)) - margin
      const unit = this.unit || ''
      return {
        chart_options: {
          time: {
            timezoneOffset: new Date().getTimezoneOffset()
          },
          credits: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          navigator: {
            enabled: false
          },
          chart: {
            zooming: {
              mouseWheel: {
                enabled: false
              }
            },
            zoomType: 'x',
            margin: [0, 0, 30, 0],
            height: 200
          },
          yAxis: [
            {
              tickAmount: 6,
              gridZIndex: 4,
              startOnTick: false,
              endOnTick: false,
              opposite: false,
              min: y_min,
              max: y_max,
              labels: {
                align: 'left',
                x: 0,
                formatter() {
                  return this.value + unit
                }
              },
              plotLines: [
                {
                  color: '#888888',
                  width: 1,
                  dashStyle: 'LongDash',
                  zIndex: 5,
                  label: {
                    text: `${this.$t('general.limit')} ${this.upperThreshold} ${unit}`,
                    align: 'right',
                    x: 0,
                    style: {
                      color: '#444444',
                      fontSize: '0.9em'
                    }
                  },
                  value: this.upperThreshold
                },
                {
                  color: '#888888',
                  width: 1,
                  dashStyle: 'LongDash',
                  zIndex: 5,
                  label: {
                    text: `${this.$t('general.limit')} ${this.lowerThreshold} ${unit}`,
                    align: 'right',
                    x: 0,
                    style: {
                      color: '#444444',
                      fontSize: '0.9em'
                    }
                  },
                  value: this.lowerThreshold
                }
              ]
            }
          ],
          series: [
            {
              name: this.name,
              data: this.data,
              type: 'areaspline',
              threshold: null,
              tooltip: {
                pointFormat: `{series.name} {point.y} ${this.unit ? this.unit : ''}`,
                valueDecimals: 2
              },
              lineWidth: 1,
              color: '#97A4B8',
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },
                stops: [
                  [0, '#D6DDE2'],
                  [1, '#FFFFFF']
                ]
              }
            }
          ],
          tooltip: {
            distance: 80,
            style: {
              padding: '10px'
            }
          }
        }
      }
    },
    updateChartOptions() {
      this.chart_options = this.calculateChartOptions().chart_options
    }
  },
  mounted() {
    syncCharts()
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options" constructor-type="stockChart")
</template>
