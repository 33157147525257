<script>
export default {
  props: {
    category_opts: Array,
    status_opts: Array,
    selected_category: String,
    selected_status: String,
  },
  data() {
    return {
      category: this.selected_category,
      status: this.selected_status,
    }
  },
  computed: {
    url() {
      const cat = this.category || ''
      const status = this.status || ''
      return `?category=${cat}&status=${status}`
    },
  },
  watch: {
    url() { window.location = this.url }
  }
}
</script>

<template lang="pug">
.d-flex
  v-select.ml-3(v-model="category" name='category' :items= "category_opts" :placeholder="$t('feature_requests.filter_category')" :clearable='true' solo)
  v-select.ml-3(v-model="status" name='status' :items= "status_opts" :placeholder="$t('feature_requests.filter_status')" :clearable='true' solo)
</template>
