<script>
import { debounce } from '../../lib/debounce.js'

const load_delay = process.env.NODE_ENV == "test" ? 10 : 300

export default {
  props: {
    labels: Array,
    query: String,
    currentLabels: Array,
    currentResponsibles: Array,
    responsibles: Array,
  },
  data() {
    return {
      selectedLabels: this.currentLabels.map(label => (this.labels.find(l => l.id == label).name)),
      selectedResponsibles: this.currentResponsibles.map(responsible => (this.responsibles.find(r => r.id == responsible))),
      menuOpened: null,
      nameFilter: this.query,
    }
  },
  methods: {
    apply() {
      var url = new URL(window.location)
      url.searchParams.delete('label[]')
      this.selectedLabels.forEach(name => {
        url.searchParams.append('label[]', this.labels.find(l => l.name == name).id)
      })

      url.searchParams.delete('query')
      if(this.nameFilter != '' && this.nameFilter != null) {
        url.searchParams.append('query', this.nameFilter)
      }

      url.searchParams.delete('responsible[]')
      this.selectedResponsibles.forEach(responsible => {
        url.searchParams.append('responsible[]', responsible.id)
      })

      window.history.pushState('', '', url)

      this.$emit("change")
    },
    clear() {
      this.selectedLabels = []
      this.nameFilter = ""
      this.apply()
    },
    colorFromName(name) {
      return this.labels.find(l => l.name == name).color
    }
  },
  computed: {
    labelsWithNone() {
      return [{ id: "", name: 'all_labels'}, ...this.labels]
    }
  },
  watch: {
    nameFilter: debounce(function () {
      this.apply()
    }, load_delay),
    selectedLabels() {
      this.apply()
    },
    selectedResponsibles() {
      this.apply()
    }
  }
}
</script>

<template lang="pug">
div.ma-5
  v-autocomplete(v-model="selectedResponsibles" :items="responsibles" :label="$t('work_items.filter_responsibles')" item-text="name" item-id="name" multiple return-object)
  v-text-field(v-model="nameFilter" :label="$t('general.search')")
  v-checkbox(v-for="label in labels" v-model="selectedLabels" :key="label.id" :value="label.name", :label="label.name")
    template(v-slot:label)
      v-chip(:color="label.color" dark)
        | {{ label.name }}
  div.d-flex
    v-btn(@click="clear")
      | {{ $t('labels.clear') }}
</template>
