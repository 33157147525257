<script>
import VSearchableSelect from '../v_searchable_select.vue'

export default {
  components: {
    VSearchableSelect
  },
  props: {
    value: Object,
    roles: Array,
  },
  data() { 
    return {
      powerPlantResponsibles: this.value.power_plant_responsibles,
      companyResponsibles: this.value.company_responsibles,
      responsibleToEdit: null,
      showEdit: false,
      errors: []
    }
  },
  watch: {
    powerPlantResponsibles() {
      const newValue = { ...this.value, power_plant_responsibles: this.powerPlantResponsibles }
      this.$emit("input", newValue)
    },
    showEdit() {
      if(!this.showEdit)
        this.responsibleToEdit = null
    }
  },
  computed: {
    rolesTranslated() {
      return this.roles.map(role => ({ value: role, text: this.roleTranslated(role)}))
    },
    companyResponsibleItems() {
      return this.companyResponsibles.map(companyResponsible => ({ value: companyResponsible.id, text: companyResponsible.responsible.display_name }))
    }
  },
  methods: {
    roleTranslated(role) {
      return this.$t(`activerecord.attributes.power_plant_responsible.role_values.${role}`)
    },
    sharedProps(field) {
      return {
        name: `power_plant_responsible[${field}]`,
        id: `power_plant_responsible_${field}`,
        errorMessages: this.errors[field],
        readonly: false,
        label: this.$t(`activerecord.attributes.power_plant_responsible.${field}`),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: 'mb-2'
      }
    },
    editPowerPlantResponsible(power_plant_responsible) {
      this.responsibleToEdit = { ...power_plant_responsible }
      this.showEdit = true
    },
    addNewPowerPlantResponsible() {
      this.responsibleToEdit = { power_plant_id: this.value.id }
      this.showEdit = true
    },
    async save() {
      try {
        if(!this.responsibleToEdit.id) {
          const response = await this.axios.post(`/admin/power_plant_responsibles`, { power_plant_responsible: this.responsibleToEdit })
          this.powerPlantResponsibles.push(response.data)
        } else {
          const response = await this.axios.patch(`/admin/power_plant_responsibles/${this.responsibleToEdit.id}`, { power_plant_responsible: this.responsibleToEdit })
          const index = this.powerPlantResponsibles.findIndex(r => r.id === this.responsibleToEdit.id)
          this.powerPlantResponsibles.splice(index, 1, response.data)
        }
        this.responsibleToEdit = {}
        this.showEdit = false
      } catch(error) {
        console.log(error)
        this.errors = error.response.data
      }
    },
    async destroy(power_plant_responsible) {
      if(!confirm("Are you sure?")) {
        return;
      }

      try {
        await this.axios.delete(`/admin/power_plant_responsibles/${power_plant_responsible.id}`)
        let index = this.powerPlantResponsibles.indexOf(power_plant_responsible)
        this.powerPlantResponsibles.splice(index, 1)
      } catch(error) {
        console.log(error.response)
      }
    }
  }
}
</script>

<template lang="pug">
v-card.mb-5(elevation="1" id="power_plant_responsibles")
  v-card-title {{ value.name }}
    v-btn.ml-2(@click="addNewPowerPlantResponsible")
      v-icon(left) mdi-plus
      | {{ $t('general.new') }}
  v-card-text
    v-simple-table
      template(v-slot:default)
        thead
          tr
            th {{ $t('activerecord.attributes.responsible.name') }}
            th {{ $t('activerecord.attributes.power_plant_responsible.roles') }}
            th {{ $t('activerecord.attributes.responsible.email') }}
            th {{ $t('activerecord.attributes.responsible.phone') }}
            th
        tbody
          tr(v-for="power_plant_responsible in powerPlantResponsibles")
            td {{ power_plant_responsible.responsible.display_name }}
            td
              v-chip.mr-2(v-for="role in power_plant_responsible.roles" :key="role" small) {{ roleTranslated(role) }}
            td {{ power_plant_responsible.responsible.email }}
            td {{ power_plant_responsible.responsible.phone }}
            td.d-flex.justify-end.align-center
              v-btn.ml-2(elevation="1" @click="editPowerPlantResponsible(power_plant_responsible)" x-small fab)
                v-icon mdi-pencil
              v-btn.ml-2(elevation="1" @click="destroy(power_plant_responsible)" color="error" x-small fab)
                v-icon mdi-delete
    v-dialog(v-model="showEdit" max-width="500px")
      v-card(v-if="responsibleToEdit")
        v-card-title
          | {{ $t('responsible.edit_power_plant_responsible') }} {{ value.name }}
        v-card-text
          v-row
            v-col
              v-searchable-select(v-model="responsibleToEdit.company_responsible_id" :items="companyResponsibleItems" v-bind="sharedProps('company_responsible')")
            v-col
              v-select(v-model="responsibleToEdit.roles" :items="rolesTranslated" v-bind="sharedProps('roles')" small-chips chips multiple)
        v-card-actions
            v-btn#power_plant_responsibles_save(@click="save()" color="primary") {{ $t('general.save') }}
            v-spacer
            v-btn(@click="showEdit = false") {{ $t('general.cancel') }}
</template>
