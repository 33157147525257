<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      turbines: this.value
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes
        .find((a) => a.asset_class_attributes)
        .asset_class_attributes.find((a) => a.wind_turbines_attributes).wind_turbines_attributes
    }
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][wind_turbines_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_wind_turbines_attributes_${field}`,
        errorMessages: this.errors[`asset_class.wind_turbines.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.wind/turbine.${field}`)
    },
    addNew() {
      this.turbines.push({
        installed_effect_mw: null,
        _destroy: false
      })
    },
    destroy(turbine) {
      if (turbine.id) {
        turbine._destroy = !turbine._destroy
      } else {
        this.turbines.splice(this.turbines.indexOf(turbine), 1)
      }
    }
  }
}
</script>
<template lang="pug">
#wind-turbines
  .mb-2
    .overline.d-inline-block Turbines
    v-btn.ml-4(@click="addNew" small)
      v-icon(left) mdi-plus
      | Add
  .mb-4(v-for="turbine in turbines" :key="turbine.id" :class="{'opacity-50': turbine._destroy}")
    .d-flex.align-center
      div
        .d-flex
          v-text-field.ml-1.mb-1(v-model="turbine.name" v-bind="sharedProps('name')")
          v-select.ml-1.mb-1(v-model="turbine.wind_turbine_type_id" v-bind="sharedProps('wind_turbine_type_id')" :items="form_data.wind_turbine_types" item-text="combined_name" item-value="id")
          v-text-field.ml-1.mb-1(v-model="turbine.lat" v-bind="sharedProps('lat')")
          v-text-field.ml-1.mb-1(v-model="turbine.lng" v-bind="sharedProps('lng')")
        .d-flex.mt-1
          v-text-field.ml-1(v-model="turbine.hub_height" v-bind="sharedProps('hub_height')" suffix="m")
          v-text-field.ml-1(v-model="turbine.tip_height" v-bind="sharedProps('tip_height')" suffix="m")
          v-text-field.ml-1(v-model="turbine.ground_altitude_masl" v-bind="sharedProps('ground_altitude_masl')" suffix="masl")
          v-text-field.ml-1(v-model="turbine.commissioning_year" v-bind="sharedProps('commissioning_year')")
        .d-flex.mt-1
          v-text-field.ml-1(v-model="turbine.external_source_id" v-bind="sharedProps('external_source_id')")
      div
        v-icon(color="error" @click="destroy(turbine)") mdi-delete
</template>
