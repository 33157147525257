// Based on https://codesandbox.io/s/vue-template-jyp7o
// and https://www.highcharts.com/demo/synchronized-charts

import Highcharts from 'highcharts'
import Vue from 'vue'

const EventBus = new Vue()

EventBus.$on('extremes-changed', (props) => {
  Highcharts.charts.forEach((chart) => {
    if (chart && chart.index !== props.chartId) {
      chart.xAxis[0].setExtremes(props.min, props.max)
    }
  })
})

Highcharts.Point.prototype.highlight = function (event) {
  event = this.series.chart.pointer.normalize(event)
  this.onMouseOver() // Show the hover marker
  // this.series.chart.tooltip.refresh(this); // Show the tooltip
  this.series.chart.xAxis[0].drawCrosshair(event, this) // Show the crosshair
}

function pointerEventHandler(e) {
  var chart, point, i, event
  for (i = 0; i < Highcharts.charts.length; i = i + 1) {
    chart = Highcharts.charts[i]
    if (!chart) continue

    // Find coordinates within the chart
    event = chart.pointer.normalize(e)
    // Get the hovered point
    point = chart.series[0].searchPoint(event, true)

    if (point) {
      point.highlight(e)
    }
  }
}

function syncPointers(e) {
  var chart_node = e.target.renderTo
  ;['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
    chart_node.removeEventListener(eventType, pointerEventHandler)
    chart_node.addEventListener(eventType, pointerEventHandler)
  })
}

function notifyZoomChanged(e) {
  var chart = e.target
  Highcharts.addEvent(chart.xAxis[0], 'afterSetExtremes', function (e) {
    EventBus.$emit('extremes-changed', {
      min: e.min,
      max: e.max,
      chartId: chart.index
    })
  })
}

function syncCharts() {
  Highcharts.addEvent(Highcharts.Chart, 'load', function (e) {
    notifyZoomChanged(e)
    syncPointers(e)
  })
  Highcharts.Pointer.prototype.reset = function () {
    return undefined
  }
}

export { syncCharts }
