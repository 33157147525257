<script>
import OMDashboardHelpers from '../../../../mixins/om_dashboard_helpers'
import Highcharts from 'highcharts'

const DEFAULT_STATUS_COLOR = '#1890FF'
export default {
  props: {
    power_curve_data: Object,
    x_title: String,
    y_title: String,
    name: String,
    x_unit: String,
    y_unit: String,
    additionalNames: Array,
    additionalValues: Array
  },
  mixins: [OMDashboardHelpers],
  data() {
    return {
      tooltipLimit: 20000,
      is_mounted: false,
      additionalColours: ['#53B1FD', '#0000AA', '#00FFBB', '#6F8435', '#876F49', '#E1522A']
    }
  },
  watch: {
    power_curve_data: {
      handler(newVal) {
        this.points = newVal.values || {}
        this.power_curve = newVal.power_curve || {}
        this.historical_power_curve = newVal.historical_power_curve || {}
      },
      deep: true
    }
  },
  methods: {
    buildSeries() {
      let tooltip = {
        enabled: this.hasTooltip,
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: `<br>${this.$t('activerecord.attributes.wind/power_curve.power')}: {point.y} ${
          this.y_unit
        }<br>${this.$t('activerecord.attributes.wind/power_curve.wind_speed')}: {point.x} ${
          this.x_unit
        }<br>${this.$t('general.timestamp')}: {point.timestamp}`
      }

      let marker = {
        radius: 2,
        symbol: 'circle'
      }

      let series = []

      if (Array.isArray(this.additionalValues) && this.additionalValues.length) {
        // Combine all points from all status codes for the main turbines into one series
        let mainTurbineData = Object.values(this.power_curve_data.points).flat()
        series.push({
          turboThreshold: 0,
          type: 'scatter',
          name: this.name,
          color: this.additionalColours[0],
          enableMouseTracking: this.hasTooltip,
          data: mainTurbineData.map((p) => ({
            x: parseFloat(p.x_value) || 0,
            y: parseFloat(p.y_value) || 0,
            timestamp: new Date(p.timestamp).toLocaleString('en-GB', {
              timeZone: 'UTC',
              dateStyle: 'short',
              timeStyle: 'short'
            })
          })),
          tooltip: tooltip,
          boostThreshold: this.dataLength + 1,
          marker: marker
        })
        // Combine all points from all status codes for additional turbines into one series each
        this.additionalValues.forEach((windTurbine, index) => {
          let windTurbineDataPoints = Object.values(windTurbine.points).flat()
          series.push({
            turboThreshold: 0,
            type: 'scatter',
            name: this.additionalNames[index],
            color: this.additionalColours[(index + 1) % this.additionalColours.length],
            enableMouseTracking: this.hasTooltip,
            data: windTurbineDataPoints.map((p) => ({
              x: parseFloat(p.x_value) || 0.0,
              y: parseFloat(p.y_value) || 0.0,
              timestamp: new Date(p.timestamp).toLocaleString('en-GB', {
                timeZone: 'UTC',
                dateStyle: 'short',
                timeStyle: 'short'
              })
            })),
            tooltip: tooltip,
            boostThreshold: this.dataLength + 1,
            marker: marker
          })
        })
      } else {
        Object.entries(this.power_curve_data.points).forEach(([status, points], _index) => {
          const name = this.operationalStatusTypes[status]?.title
            ? this.operationalStatusTypes[status].title
            : 'missing_status'
          series.push({
            turboThreshold: 0,
            type: 'scatter',
            name: this.$t(`om_dashboard.status.wind.${name}`),
            color: this.operationalStatusTypes[status]?.color || DEFAULT_STATUS_COLOR,
            enableMouseTracking: this.hasTooltip,
            data: points.map((p) => ({
              x: parseFloat(p.x_value) || 0.0,
              y: parseFloat(p.y_value) || 0.0,
              timestamp: new Date(p.timestamp).toLocaleString('en-GB', {
                timeZone: 'UTC',
                dateStyle: 'short',
                timeStyle: 'short'
              }),
              color: this.operationalStatusTypes[status]?.color || DEFAULT_STATUS_COLOR
            })),
            tooltip: tooltip,
            boostThreshold: this.dataLength + 1,
            marker: marker
          })
        })
      }

      if (this.historicalPowerCurveSeries.length) {
        series.push(this.createHistoricalPowerCurveSeries(this.historicalPowerCurveSeries))
      }

      if (this.powerCurveSeries.length) {
        series.push(this.createPowerCurveSeries(this.powerCurveSeries))
      }
      return series
    },
    createLine(data, color, title) {
      return {
        type: 'line',
        name: `${title}${this.name ? ` for ${this.name}` : ''}`,
        lineWidth: 1,
        data: data,
        color: color,
        marker: { enabled: false },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: `<br>${this.$t('activerecord.attributes.wind/power_curve.power')}: {point.y} ${
            this.y_unit
          }<br>${this.$t('activerecord.attributes.wind/power_curve.wind_speed')}: {point.x} ${this.x_unit}`
        }
      }
    },
    createPowerCurveSeries(data) {
      return this.createLine(
        data,
        'black',
        this.$t('activerecord.attributes.wind/power_curve.manufacturer_power_curve')
      )
    },
    createHistoricalPowerCurveSeries(data) {
      return this.createLine(
        data,
        '#F97066',
        this.$t('activerecord.attributes.wind/power_curve.historical_power_curve')
      )
    }
  },
  computed: {
    powerCurveSeries() {
      if (this.power_curve_data.power_curve && this.power_curve_data.power_curve.length > 0) {
        return this.power_curve_data.power_curve.map((p) => ({
          x: parseFloat(p.x_value) || 0.0,
          y: parseFloat(p.y_value) || 0.0
        }))
      }
      return []
    },
    historicalPowerCurveSeries() {
      if (
        this.power_curve_data.historical_power_curve &&
        this.power_curve_data.historical_power_curve.length > 0
      ) {
        return this.power_curve_data.historical_power_curve.map((p) => ({
          x: parseFloat(p.x_value) || 0.0,
          y: parseFloat(p.y_value) || 0.0
        }))
      }
      return []
    },
    dataLength() {
      let initial = Object.values(this.power_curve_data).flat().length
      if (Array.isArray(this.additionalValues) && this.additionalValues.length) {
        this.additionalValues.forEach((add) => {
          initial += Object.values(add).flat().length
        })
      }
      return initial || 0
    },
    hasTooltip() {
      return this.dataLength <= this.tooltipLimit
    },
    hasData() {
      return Object.keys(this.power_curve_data).length > 0
    },
    yAxisMax() {
      let maxY = 0;
      this.buildSeries().forEach(series => {
        series.data.forEach(point => {
          if (point.y > maxY) {
            maxY = point.y;
          }
        });
      });
      return maxY * 1.1
    },
    options() {
      Highcharts.seriesTypes.scatter.prototype.drawLegendSymbol =
        Highcharts.seriesTypes.column.prototype.drawLegendSymbol

      let series = this.buildSeries()

      return {
        title: { text: null },
        credits: { enabled: false },
        legend: {
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 10,
          itemStyle: {
            color: '#333333',
            fontWeight: 'bold'
          }
        },
        xAxis: [
          {
            gridLineWidth: 0,
            min: 0,
            title: { text: `${this.x_title} [${this.x_unit}]` },
            lineWidth: 1
          }
        ],
        yAxis: [
          {
            gridLineWidth: 0,
            min: 0,
            max: this.yAxisMax,
            title: { text: `${this.y_title} [${this.y_unit}]` }
          }
        ],
        exporting: this.isExportable(this.$t('om_dashboard.drilldown.export_menu.filenames.power_curve')),
        chart: {
          events: {
            load: function () {
              // Use load event to dynamically adjust height to maintain aspect ratio
              let w = this.chartWidth - 40 // width - left - right spacing
              this.setSize(w, w, false) // Set height equal to the adjusted width
            }
          }
        },
        spacingTop: 10,
        spacingBottom: 10,
        spacingLeft: 10,
        spacingRight: 10,
        plotBorderWidth: null,
        series: series
      }
    }
  }
}
</script>

<template lang="pug">
v-responsive(height="1100" width="900")
  div.power_curve(v-if="hasData")
    v-highcharts(:options="options")
    em(v-if="!hasTooltip") {{ $t('om_dashboard.drilldown.tooltip_hidden') }}
  div(v-else)
    v-progress-circular(indeterminate)
</template>
