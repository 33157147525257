<script>
// Small wrapper around v-select

export default {
  props: {
    id: String,
    name: String,
    label: String,
    value: Array,
    hint: String,
    persistentHint: Boolean,
    items: Array,
    errors: Boolean,
    errorMessages: Array
  },
  data() {
    return {
      selected_value: this.value
    }
  },
  watch: {
    value(value) {
      this.selected_value = this.value
    },
    selected_value(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template lang="pug">
.multi-select
  // Setting name=ignored_vuetify_default to get rid of the default input, which doesnt work handle arrays correctly
  v-select(v-model="selected_value" :multiple="true" :id="id" name="ignored_vuetify_default" :label="label" :hint="hint" :persistent-hint="persistentHint" :items="items" :errors="errors" :error-messages='errorMessages' :filled="true" :clearable="true" hide-details="auto")
  input(v-for="val in selected_value" type="hidden" :id="`${id}_${val}`" :name="`${name}[]`" :value="val")
</template>
