<script>
export default {
  props: {
    prices: Object,
    y_title: String,
    year: String,
    selected_res: {
      type: String,
      default: "M",
    },
    admin_user: String,
  },
  data() {
    return {
      selectedRes: this.selected_res,
      local_res: this.selected_res,
      resolutions: [
        { value: "H", text: this.$t("general.hourly") },
        { value: "D", text: this.$t("general.daily") },
        { value: "M", text: this.$t("general.monthly") },
      ],
      chart_options: null,
    }
  },
  computed: {
    isAdmin() {
      return this.admin_user === 'true'
    },
  },
  mounted() {
    this.initChartOptions()
  },
  watch: {
    selectedRes(newRes) {
      this.updateChartSeries(newRes)
    },
  },
  methods: {
    initChartOptions() {
      this.chart_options = {
        chart: {
          zoomType: "x",
        },
        title: {
          text: null,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          min: 0,
          title: {
            text: this.y_title,
            enabled: true,
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return this.chart.series.some((s) => (this === s ? !s.visible : s.visible))
              },
            },
          },
        },
        series: this.setSeries(this.selectedRes),
      }
    },
    setSeries(res) {
      if (!this.prices || !this.prices[res]) {
        return []
      }

      return Object.keys(this.prices[res]).map((key) => {
        return {
          name: key === "SP1" ? "SYS" : key,
          type: "line",
          data: this.prices[res][key],
          yAxis: 0,
          visible: key === "SP1",
          tooltip: {
            pointFormat: "{series.name}: {point.y} EUR/MWh",
            valueDecimals: 0,
            xDateFormat: "%b %e %Y %H:%M",
            shared: true,
          },
          marker: {
            radius: 0,
          },
        }
      })
    },
    updateChartSeries(res) {
      if (this.chart_options) {
        this.chart_options.series = this.setSeries(res)
        this.local_res = res
      }
    },
    exportData() {
      window.location.href = `/market_outlook/export.xlsx?resolution=${this.local_res}&year=${this.year}`
    },
  },
}
</script>

<template lang="pug">
span
  v-row
    v-col.justify-center.align-left
      v-btn.mr-2(
        color="green",
        class="white--text",
        @click="exportData",
        v-show="isAdmin"
      )
        v-icon(left='') mdi-file-excel
        | {{ $t('power_plants.export_xlsx') }}
    v-col.justify-center.align-center
      v-card-title
        span {{ $t('market_outlook.pfc') }}
    v-col.justify-center.align-center
      v-select.ml-auto(
        v-model="selectedRes",
        :items="resolutions",
        :label="$t('general.resolution')",
        outlined,
        return-object,
        @change="selectedRes = $event"
      )
  v-row
    v-col
      v-highcharts(:options="chart_options")
  v-row(v-if="isAdmin")
    v-col.d-flex.justify-center.align-center
      span {{ $t('market_outlook.info_text') }}
</template>
