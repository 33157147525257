<script>
export default {
  props: {
    data: Object,
    title: String,
    y_title: String,
    currency_code: String,
  },
  data() {
    return {
      chart_options: {
        title: {
          text: this.title,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          min: 0,
          title: {
            text: this.y_title,
            enabled: true,
          },
        },
        credits: {
          enabled: false,
        },

        series: Object.keys(this.data).map((key, index) => {
          return {
            name: key,
            type: "line",
            data: this.data[key],
            yAxis: 0,
            visible: key.startsWith("ENOFUTBLYR") ? false : true,
            tooltip: {
              pointFormat: `{series.name}: {point.y} ${this.currency_code}/MWh`,
              valueDecimals: 0,
              xDateFormat: "%b %e %Y",
              shared: true,
            },
            marker: {
              radius: 0,
            },
          };
        }),
      },
    };
  },
};
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>
