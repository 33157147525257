<script>
import axios from 'axios';
import PageHitsFilter from './page_hits_filter.vue';

export default {
  props: {
    pageHits: Array,
  },
  components: {
    PageHitsFilter
  },
  data() {
    return {
      pageHitsData: this.pageHits,
      usersPath: '/admin/users',
      pageHitsPath: '/admin/page_hits',
    };
  },
  methods: {
    async fetchFilteredData({ filters, dateRange }) {
      try {
        const response = await axios.get('/admin/page_hits', {
          params: {
            filters: filters,
            start_date: dateRange[0],
            end_date: dateRange[1]
          }
        });

        this.pageHitsData = response.data;
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    }
  }
};
</script>

<template lang="pug">
  div
    .custom-tabs
      a.custom-tab(
        :href="usersPath"
        id="users-tab"
      ) {{ $t('page_hits.admin.tabs.users') }}
      a.custom-tab.custom-tab--active(
        :href="pageHitsPath"
        id="page-hits-tab"
      ) {{ $t('page_hits.admin.tabs.page_hits') }}
    br
    PageHitsFilter(@filtersChanged="fetchFilteredData")
    v-simple-table(class="elevation-4")
      template(v-slot:default)
        thead
          tr
            th.nowrap {{ $t('page_hits.admin.table.controller') }}
            th.nowrap {{ $t('page_hits.admin.table.action') }}
            th.nowrap {{ $t('page_hits.admin.table.hit_count') }}
            th.nowrap {{ $t('page_hits.admin.table.percentage') }}
        tbody
          tr(v-for="line in pageHitsData" :key="line.controller_path + line.action_name")
            td {{ line.controller_path }}
            td {{ line.action_name }}
            td {{ line.count }}
            td(v-text="line.percentage !== undefined ? line.percentage.toFixed(2) + '%' : ''")
</template>
