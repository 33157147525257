<script>
import ProfileRisk from './profile_risk.vue'
import PortfolioReferenceProfile from './portfolio_reference_profile.vue'
import BuyBackCost from './buy_back_cost.vue'
import BuyBackVolume from './buy_back_volume.vue'
import Baseload from './baseload.vue'

export default {
  components: {
    ProfileRisk,
    PortfolioReferenceProfile,
    BuyBackCost,
    BuyBackVolume,
    Baseload,
  },
  props: {
    profile_risk: Array,
    
    p50_prod_percentages: Array,
    ref_price_zone_prod_percentages: Array,
    price: Array,

    buy_back_cost_annual: Array,
    buy_back_cost_quarterly: Array,
    buy_back_cost_monthly: Array,
    buy_back_cost_pay_as_produced: Array,

    buy_back_volume_annual: Array,
    buy_back_volume_quarterly: Array,
    buy_back_volume_monthly: Array,
    buy_back_volume_pay_as_produced: Array,

    risk_factors_price: Array,
    risk_factors_profile: Array,
    risk_factors_volume: Array,
    risk_factors_liquidity: Array,
    risk_factors_cash_flow: Array,
    risk_factors_cannibalism: Array,

    p50_prod: Array,
  },
  data() {
    return {
      ratio: "100%",
      strategy: "pay-as-produced",
      hedge_ratio: [],
    }
  },
  mounted() {
    this.fetch_hedge_ratio()
  },
  methods: {
    async fetch_hedge_ratio() {
      let url = `/ppa/prices/hedge_ratio?ratio=${this.ratio.replace('%', '')}&strategy=${this.strategy}`
      let response = await this.axios.get(url)
      this.hedge_ratio = response.data
    },
    colorSelector(value) {
      let strategy = this.strategy
      const result = value.find(({ name }) => name === strategy);
      let colors = ['red', 'orange', 'yellow', 'light-green', 'green']
      let thresholds = [0, 20, 40, 60, 80, 100]

      if(result === undefined)
        return "red"


      let count = thresholds.filter(obj => {
        return obj > result.value;
      }).length

      return colors[count]
    },
  },
  computed: {
    ratio_items() {
      return ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']
    },
    strategy_items() {
      return ['annual', 'quarterly', 'monthly', 'pay-as-produced']
    }
  },
  watch: {
    ratio() {
      this.fetch_hedge_ratio()
    },
    strategy() {
      this.fetch_hedge_ratio()
    }
  }
}
</script>

<template lang="pug">
v-container
  h1 {{$t('ppa_pricing.pricing')}}
  div
    v-row
      v-col
        v-card
          PortfolioReferenceProfile(:p50_prod="p50_prod_percentages", :ref_price_zone_prod="ref_price_zone_prod_percentages" :price="price")
      v-col
        v-card
          ProfileRisk(:profile_risk="profile_risk")
    v-row
      v-col
        v-card
          BuyBackVolume(:buy_back_volume_annual="buy_back_volume_annual", :buy_back_volume_quarterly="buy_back_volume_quarterly", :buy_back_volume_monthly="buy_back_volume_monthly", :buy_back_volume_pay_as_produced="buy_back_volume_pay_as_produced")
      v-col
        v-card
          BuyBackCost(:buy_back_cost_annual="buy_back_cost_annual", :buy_back_cost_quarterly="buy_back_cost_quarterly", :buy_back_cost_monthly="buy_back_cost_monthly", :buy_back_cost_pay_as_produced="buy_back_cost_pay_as_produced")

    v-card.mt-4
      v-card-text
        v-row
          v-col(cols="9")
            v-card-title
              | {{ $t('ppa_pricing.pricing_and_risk') }}
            div.d-flex
              v-select.mr-4(v-model="ratio" :items="ratio_items" label="Ratio" hideDetails="auto" dense outlined)
              v-select(v-model="strategy" :items="strategy_items" label="Strategy" hideDetails="auto" dense outlined)
            Baseload(:p50_prod="p50_prod", :hedge_ratio="hedge_ratio")
          v-col(cols="3")
            v-card-title
              | {{ $t('ppa_pricing.risk_factors') }}
            v-card-text
              v-row(align="center" justify="center")
                v-card-title
                  | {{ $t('ppa_pricing.risk_factors_mitigated') }}
              v-row
                v-col(align="center" justify="center")
                  v-row(align="center" justify="center")
                    v-avatar(:color="colorSelector(risk_factors_price)", :size=60)
                  v-row(align="center" justify="center")
                    | {{ $t('ppa_pricing.factors.price') }}
                v-col(align="center" justify="center")
                  v-row(align="center" justify="center")
                    v-avatar(:color="colorSelector(risk_factors_profile)", :size=60)
                  v-row(align="center" justify="center")
                    | {{ $t('ppa_pricing.factors.profile') }}
                v-col(align="center" justify="center")
                  v-row(align="center" justify="center")
                    v-avatar(:color="colorSelector(risk_factors_volume)", :size=60)
                  v-row(align="center" justify="center")
                    | {{ $t('ppa_pricing.factors.volume') }}
              v-row(align="center" justify="center")
                v-card-title
                  | {{ $t('ppa_pricing.risk_factors_additional') }}
              v-row
                v-col(align="center" justify="center")
                  v-row(align="center" justify="center")
                    v-avatar(:color="colorSelector(risk_factors_liquidity)", :size=60)
                  v-row(align="center" justify="center")
                    | {{ $t('ppa_pricing.factors.liquidity') }}
                v-col(align="center" justify="center")
                  v-row(align="center" justify="center")
                    v-avatar(:color="colorSelector(risk_factors_cash_flow)", :size=60)
                  v-row(align="center" justify="center")
                    | {{ $t('ppa_pricing.factors.cash_flow') }}
                v-col(align="center" justify="center")
                  v-row(align="center" justify="center")
                    v-avatar(:color="colorSelector(risk_factors_cannibalism)", :size=60)
                  v-row(align="center" justify="center")
                    | {{ $t('ppa_pricing.factors.cannibalism') }}
</template>
