<script>

export default {
  components: {
  },
  props: {
    year: Number,
    available_price_areas: Array,
    unavailable_price_areas: Array,
    company_id: Number
  },
  data() {
    return {
      price_area: "",
      budget_forecasts: this.budget_forecasts_array(),
      errors: [],
      loading: false,
      dialog: false,
    }
  },
  computed: {
  },
  methods: {
    budget_forecasts_array () {
      let budget_forecasts = _.range(1, 13).map(index => {
        let budget_forecast = {
            price_area: "",
            month: new Date(`${this.year}-${index}-01`).toString(),
            volume_percent: "",
            avg_price:"",
            company_id: this.company_id
        }
        return budget_forecast
      })
      return budget_forecasts
    },
    sharedProps (field, index) {
      return {
        hideDetails: 'auto',
        outlined: true,
        dense: true,
        label: this.label(field),
        name: `budget_forecast[${field}]`,
        id: `budget_forecast[${field}][${index}]`,
        errorMessages: this.errors[index] ? this.errors[index][field]?.join(", ") : '',
        chips: false,
        class: 'mt-2'
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.my_model.${field}`)
    },
    show_dialog() {
      this.dialog = true
    },
    async save (item) {
      let url = `/admin/budget_forecasts/batch_create`
      try {
        await this.axios.post(url, { budget_forecasts_batch_create_form: { budget_forecasts: this.budget_forecasts }})
        location.reload()
      } catch (error) {
        console.log(error.response)
        this.errorHandler(error)
      }

    },
    errorHandler(error) {
      console.log(error)
      if(error?.response?.data?.errors) {
        this.errors = error.response.data.errors
      } else {
        alert('An unexpected error occurred')
      }
    },
    setPriceArea(value) {
      this.budget_forecasts.forEach((budget_forecast) => budget_forecast.price_area = value)
    }
  },
}

</script>

<template lang="pug">
  div
    v-dialog(v-model="dialog" width="800" height="1000px")
      v-card
        v-card-title(class="text-h5 grey lighten-2")
          span {{ $t('dialog.new_budget_forecast') }}

        v-card-text
          v-row
            v-col
              div(v-if="unavailable_price_areas.length != 0")
                v-alert(type="info" dense) {{ $t('dialog.unavailable_price_areas', { price_areas: unavailable_price_areas.join(", ") })}}
              v-select(
                @change="setPriceArea($event)"
                prepend-icon="mdi-map-marker"
                :label="$t('general.price_area')"
                :auto-grow="true"
                :items="available_price_areas"
                v-bind="sharedProps('price_area', 0)"
              )
          v-row(dense v-for="(budget_forecast, index) in budget_forecasts" :key="budget_forecast.id")
            v-col(cols="3")
              v-subheader {{ new Date(budget_forecast.month).toLocaleDateString("en-US", { month: 'long', year: 'numeric' })}}
            v-col(cols="5")
              v-text-field(
                v-model="budget_forecast.volume_percent"
                prepend-icon="mdi-percent"
                :label="$t('general.volume_percent')"
                type="number"
                step=".1"
                :auto-grow="true"
                v-bind="sharedProps('volume_percent', index)"
                hide-details="auto"
              )
            v-col(cols="4")
              v-text-field(
                v-model="budget_forecast.avg_price"
                prepend-icon="mdi-currency-usd"
                :label="$t('general.avg_price')"
                type="number"
                :auto-grow="true"
                v-bind="sharedProps('avg_price', index)"
                hide-details="auto"
              )

        v-card-actions
          v-btn(
            color="primary"
            :loading="loading"
            @click="save"
          )
            | {{ $t('general.save') }}
          v-spacer
          v-btn(text @click="dialog = false") {{ $t('general.cancel') }}

    v-btn(@click="show_dialog()" id="show_dialog_budget_forecast" color="light-blue" title="Add budget forecasts" fixed dark fab right bottom)
      v-icon mdi-plus

</template>
