<script>
export default {
  name: 'Toggle',
  props: {
    visible: Boolean
  },
  data() {
    return {
      localVisible: this.visible
    }
  },
  watch: {
    localVisible() {
      this.$emit('changeVisible', this.localVisible)
    }
  }
}
</script>

<template lang="pug">
v-btn.toggle(@click="localVisible = !localVisible" icon small)
  v-icon(v-if="localVisible" small) mdi-eye-off
  v-icon(v-else small) mdi-eye
</template>
