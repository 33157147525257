<script>
import { debounce } from '../../lib/debounce.js'
import BooleanChip from '../common/boolean_chip.vue'
import PowerPlantForm from './form/index.vue'

const load_delay = process.env.NODE_ENV == 'test' ? 10 : 300

export default {
  components: {
    BooleanChip,
    PowerPlantForm
  },
  props: {
    can_create: { type: Boolean, default: false }
  },
  data() {
    return {
      power_plants: [],
      loading: true,
      page_count: -1,
      current_page: 1,
      sort_by: ['name'],
      sort_desc: false,
      total_count: 0,
      gwh_total: 0,
      query: new URL(window.location.href).searchParams.get('query') || '',
      editing_power_plant: null,
      headers: [
        { text: '', value: 'image_url', sortable: false },
        { text: 'Nve nr', value: 'nve_nr' },
        { text: 'Wiki', value: 'wikipedia_page_id' },
        { text: this.$t('general.name'), value: 'name' },
        { text: this.$t('general.area'), value: 'price_area' },
        { text: this.$t('general.metering_point'), value: 'metering_point_id', class: 'text-wrap' },
        {
          text: this.$t('activerecord.attributes.power_plant.datastream'),
          value: 'last_vol_at',
          align: 'center'
        },
        { text: this.$t('general.project'), value: 'project', align: 'center' },
        {
          text: this.$t('activerecord.attributes.power_plant.reservoir'),
          value: 'reservoir',
          align: 'center'
        },
        { text: this.$t('general.avail_analysis'), value: 'downtime_analysis', align: 'center', class: 'text-wrap' },
        { text: this.$t('general.budget'), value: 'this_year_budget', align: 'center' },
        { text: this.$t('general.ny_budget'), value: 'next_year_budget_exists', align: 'center' },
        { text: this.$t('general.elcert'), value: 'elcert', align: 'center' },
        { text: this.$t('general.intake'), value: 'intake_count', align: 'center' },
        { text: this.$t('general.mw'), value: 'rated_power_mw' },
        { text: this.$t('general.treshold_mw'), value: 'min_production_effect' },
        { text: this.$t('general.gwh'), value: 'yearly_production' },
        { text: this.$t('general.go_expiry_date'), value: 'go_date', class: 'text-wrap' },
        { text: '', value: 'menu', sortable: false }
      ]
    }
  },
  computed: {
    power_plant_id() {
      return new URLSearchParams(window.location.search).get('power_plant_id')
    }
  },
  methods: {
    mapUrl(item) {
      return `https://www.google.com/maps/search/?api=1\&query=${item.lat},${item.lng}`
    },
    async loadPowerPlants() {
      this.loading = true

      const parser = new URL(window.location)
      parser.searchParams.set('page', this.current_page)
      parser.searchParams.set('query', this.query)
      parser.searchParams.set('format', 'json')
      parser.searchParams.set('sort_by', this.sort_by[0])
      parser.searchParams.set('sort_desc', this.sort_desc)

      const { data } = await this.axios.get(parser.href)
      this.power_plants = data.power_plants
      this.page_count = data.meta.page_count
      this.gwh_total = data.meta.gwh_total
      this.total_count = data.meta.total_count
      this.loading = false
      if (this.power_plant_id)
        this.editing_power_plant = this.power_plants.find((p) => p.id === this.power_plant_id)
    },

    editComplete(persisted) {
      if (persisted) {
        window.location.reload()
      } else {
        // Possibly for a later iteration:
        // The problem with this approach is serializer fields like flag_url and owner_names
        // An idea would be to pass in the serializer we want the backend to use
        // const idx = this.power_plants.findIndex(p => p.id == this.editing_power_plant.id)
        // Object.assign(this.power_plants[idx], this.editing_power_plant)
        this.editing_power_plant = null
      }
    }
  },
  watch: {
    current_page() {
      this.loadPowerPlants()
    },
    query: debounce(function () {
      this.current_page = 1
      this.loadPowerPlants()
    }, load_delay),
    sort_by: debounce(function () {
      this.loadPowerPlants()
    }, load_delay),
    sort_desc: debounce(function () {
      this.loadPowerPlants()
    }, load_delay)
  },
  created() {
    this.loadPowerPlants()
  }
}
</script>

<template lang="pug">
#power-plants-table
  h1.d-flex.align-center
    | {{ total_count }} {{ $t('general.power_plants') }},
    | {{ gwh_total }} {{ $t('general.gwh') }}
    .pr-4
    slot
  v-text-field(v-model="query" append-icon="mdi-magnify" :label="$t('general.search')" single-line hide-details autofocus)
  v-data-table(
    :headers="headers" 
    :items="power_plants"
    :sort-by.sync="sort_by" 
    :sort-desc.sync="sort_desc" 
    :must-sort="true" 
    :loading="loading" 
    :server-items-length="page_count" 
    :search="query" 
    :hide-default-footer="true" 
    class="elevation-4" 
    dense show-expand
    )
    template(v-slot:item.image_url="{value}")
      v-list-item-avatar(height="30px", width="30px", min-width="30px")
        v-img(:src="value")
    template(v-slot:item.go_date="{value}")
      span.text-no-wrap {{ value }}
    template(v-slot:item.min_production_effect="{item, value}")
      v-chip.pr-1(v-if="value" color='green' dark x-small)
        | {{ value }}
        v-icon.ml-1(x-small) mdi-pencil
      v-chip(v-else color='blue' dark x-small) {{ item.default_min_production_effect }}  
    template(v-slot:item.nve_nr="{item, value}")
      a(v-if="value" :href="`/admin/nve_plants/${value}`") {{ value }}
      v-chip(v-else-if="item.project" color='orange' dark x-small) project
      v-chip(v-else color='error' dark x-small) missing
    template(v-slot:item.wikipedia_page_id="{item, value}")
      a(v-if="value" :href="item.wikipedia_page_url" target="_blank") {{ value }}
    template(v-slot:item.name="{item, value}")
      v-list-item-avatar(size="1em")
        v-img(:src="item.flag_url")
      | {{ value }}
      .small.weak {{ item.owner_names.join(', ') }}
    template(v-slot:item.project="{value}")
      boolean-chip(:value="value" true_color="red" false_color="green")
    template(v-slot:item.reservoir="{value}")
      boolean-chip(:value="value")
    template(v-slot:item.downtime_analysis="{value}")
      boolean-chip(:value="value")
    template(v-slot:item.this_year_budget="{value}")
      boolean-chip(:value="value")
    template(v-slot:item.next_year_budget_exists="{value}")
      boolean-chip(:value="value" true_color="orange")
    template(v-slot:item.elcert="{value}")
      boolean-chip(:value="value")
    template(v-slot:item.intake_count="{value}")
      boolean-chip(:value="value > 0")
    template(v-slot:item.last_vol_at="{item}")
      v-chip(:color='item.datastream_color' dark x-small) {{ item.datastream_hrs }}
    template(v-slot:item.menu="{item}")
      v-menu
        template('v-slot:activator'="{ on }")
          v-btn('v-on'="on" icon)
            v-icon mdi-dots-vertical
        v-list
          v-list-item(:href="`/power_plants/${item.id}`") {{ $t('general.show') }}
          v-list-item(@click="editing_power_plant = item") {{ $t('general.edit') }}
          v-list-item(:href="`/admin/power_plants/${item.id}/budgets`") {{ $t('general.budgets') }}
          v-list-item(:href="`/admin/power_plants/${item.id}/green_months`") {{ $t('general.elcert/GoO') }}
    template(v-slot:expanded-item="{ headers, item }" )
      td(:colspan="headers.length" class="py-2 grey lighten-3")
        div Power Plant ID: {{ item.id }}
        div Grid Owner: {{ item.grid_owner_name }}
        div Map Link: 
          v-btn(:href="mapUrl(item)" color="light-blue" x-small dark) Map
    template(v-slot:item.price_area="{value}")
      .price-area(:title="value") {{ value }}

  v-pagination.my-4(v-if="page_count > 1" v-model="current_page" :length="page_count" total-visible="6")

  power-plant-form(v-if="editing_power_plant" v-model="editing_power_plant" @dialog-closed="editComplete($event)")

  v-btn(v-if="can_create" color="light-blue" @click="editing_power_plant = {}" @dialog-closed="window.location.reload()" fixed dark fab right bottom)
    v-icon(large) mdi-plus
</template>
