<script>

export default {
  props: {
    headers: Array,
    rows: Array,
    highlight: Boolean,
  },
  data() {
    return {
      rules: {
          required: value => !!value || this.$t('general.validations.required'),
          min: value => Number(value) >= 0 || this.$t('general.validations.non_negative')
        }
    }
  },
  methods: {
    RowClass () {
      return this.highlight ? 'highlight' : ''
    },
    formatNumber (value) {
      if (value > 0) return Number(value).toLocaleString('no-NO')
      else return value
    },
    async save (item) {
      let url = `/admin/budget_forecasts/${item.id}`
      let response = await this.axios.patch(url, { volume_percent: item.volume_percent, avg_price: item.avg_price })

      location.reload()
    },
  },
}

</script>

<template lang="pug">
  div
    v-data-table(dense :headers="headers" :items="rows" :item-class="RowClass" :items-per-page="15")
      template(v-for="header in headers.slice(1, -1)" v-slot:[`item.${header.value}`]="{ header, value, item }")
        span(v-if="item['price_area'] !== $t('profile.total')")
          | {{ formatNumber(item[header.value].forecasted_revenue.toFixed(0)) }}
        span.font-weight-bold(v-if="item['price_area'] === $t('profile.total')") {{ formatNumber(item[header.value].forecasted_revenue.toFixed(0)) }}
      template(v-slot:item.price_area="{ item }")
        span.font-weight-bold {{ item.price_area }}
      template(v-slot:item.yearly_summary="{ item }")
        span.font-weight-bold {{ formatNumber(item.yearly_summary.forecasted_revenue.toFixed(0)) }}
</template>
