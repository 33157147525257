<script>
import FilterCheckbox from './filter_checkbox.vue'
import DateRangePicker from './date_range_picker.vue'

function formatDate(date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

export default {
  components: {
    FilterCheckbox,
    DateRangePicker
  },
  data() {
    return {
      filters: {
        matching_referrer_page: false,
        user_logged_in: true,
        hide_admin_pages: false
      },
      dateRange: [formatDate(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)), formatDate(new Date())],
      dateSelection: '7d'
    }
  },
  methods: {
    onFilterChange({ paramName, value }) {
      this.filters[paramName] = value
      this.$emit("filtersChanged", { filters: this.filters, dateRange: this.dateRange })
    },
    onDateRangeChange(dates) {
      this.dateRange = dates
      this.dateSelection = 'custom'
      this.$emit("filtersChanged", { filters: this.filters, dateRange: this.dateRange })
    },
    handleDateSelection() {
      if (this.dateSelection === '7d') {
        this.setWeek()
      } else if (this.dateSelection === 'all-time') {
        this.setAllTime()
      } else if (this.dateSelection === 'custom') {
      }
    },
    setAllTime() {
      const startDate = new Date('2019-01-01')
      const endDate = new Date()
      this.dateRange = [formatDate(startDate), formatDate(endDate)]
      this.$emit("filtersChanged", { filters: this.filters, dateRange: this.dateRange })
    },
    setWeek() {
      const startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
      const endDate = new Date()
      this.dateRange = [formatDate(startDate), formatDate(endDate)]
      this.$emit("filtersChanged", { filters: this.filters, dateRange: this.dateRange })
    }
  }
}
</script>

<template lang="pug">
  v-row(align="center" class="mb-4")
    v-col(cols="auto")
      FilterCheckbox(
        :label="$t('page_hits.admin.filters.matching_referrer_page')"
        paramName="matching_referrer_page"
        :isChecked="filters.matching_referrer_page"
        @change="onFilterChange"
      )

    v-col(cols="auto")
      FilterCheckbox(
        :label="$t('page_hits.admin.filters.user_logged_in')"
        paramName="user_logged_in"
        :isChecked="filters.user_logged_in"
        @change="onFilterChange"
      )

    v-col(cols="auto")
      FilterCheckbox(
        label="Hide admin pages"
        paramName="hide_admin_pages"
        :isChecked="filters.hide_admin_pages"
        @change="onFilterChange"
      )

    v-col(cols="auto")
      v-btn-toggle(v-model="dateSelection" @change="handleDateSelection" mandatory)
        v-btn(value="7d") {{$t('page_hits.admin.filters.week_ago')}}
        v-btn(value="all-time") {{$t('page_hits.admin.filters.all_time')}}
        v-btn(value="custom")
          DateRangePicker(
            class="date-picker"
            @change="onDateRangeChange"
            :dateRange="dateRange"
          )
</template>
