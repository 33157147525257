<script>

export default {
  props: {
    headers: Array,
    rows: Array,
    highlight: Boolean,
  },
  data() {
    return {
      rules: {
          required: value => !!value || this.$t('general.validations.required'),
          min: value => Number(value) >= 0 || this.$t('general.validations.non_negative')
        }
    }
  },
  methods: {
    RowClass () {
      return this.highlight ? 'highlight' : ''
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    formatNumber (value) {
      if (this.isNumeric(value)) return Number(value).toLocaleString('no-NO')
      else return value
    },
    async save (item) {
      let url = `/admin/budget_forecasts/${item.id}`
      try {
        await this.axios.patch(url, { volume_percent: item.volume_percent, avg_price: item.avg_price })
        location.reload()
      } catch(error) {
        alert(error.response.data.errors.join())
      }
    },
  },
}

</script>

<template lang="pug">
  div
    v-data-table(dense :headers="headers" :items="rows" :item-class="RowClass" :items-per-page="15")
      template(v-for="header in headers.slice(1, -1)" v-slot:[`item.${header.value}`]="{ header, value, item }")
        v-edit-dialog(
          :return-value.sync="item[header.value].volume_percent"
          large
          @save="save(item[header.value])"
          v-if="item['price_area'] !== $t('profile.total')"
        )
          span(style="cursor: pointer") {{ formatNumber(item[header.value].forecasted_volume.toFixed(0)) }}
          template(v-slot:input)
            div.mt-4.text-h6 Update
            v-text-field(
              v-model="item[header.value].volume_percent"
              :rules="[rules.required, rules.min]"
              :label="$t('activerecord.attributes.budget_forecast.volume_percent')"
              suffix="%"
              type="number"
              autofocus
            )
            v-text-field(
              v-model="item[header.value].avg_price"
              :rules="[rules.required, rules.min]"
              :label="$t('activerecord.attributes.budget_forecast.avg_price')"
              type="number"
              autofocus
            )
        span.font-weight-bold(v-if="item['price_area'] === $t('profile.total')") {{ formatNumber(item[header.value].forecasted_volume.toFixed(0)) }}
      template(v-slot:item.price_area="{ item }")
        span.font-weight-bold {{ item.price_area }}
      template(v-slot:item.yearly_summary="{ item }")
        span.font-weight-bold {{ formatNumber(item.yearly_summary.forecasted_volume.toFixed(0)) }}
</template>
