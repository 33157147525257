<script>
import HumanizeNumberMixin from '../../mixins/humanize_number_mixin.js'

export default {
  mixins: [HumanizeNumberMixin],
  props: {
    y_axis_title: String,
    unit: String,
    min_max_values: { type: Array, default: () => ([]) },
    values: { type: Array, default: () => ([]) },
    mean_historical: { type: Array, default: () => ([]) },
    p50: { type: Array, default: () => ([]) }
  },
  computed: {
    calculated_mean_historical_values() {
      let field_to_base_on = (this.values?.length > this.p50?.length) ? this.values : this.p50

      return field_to_base_on.map(value => {
        const month = new Date(value[0]).getMonth()
        return [value[0], this.mean_historical[month]]
      })
    }
  },
  methods: {
    chart_options() {
      return {
        title: { text: ""},
        xAxis: [ 
          { type: 'datetime' },
          { visible: false }
        ],
        yAxis: [
          {
            title: { text: `${this.y_axis_title} [${this.unit}]` },
            labels: {
              formatter: (value) => {
                return this.humanizeNumber(value.value)
              }
            },
          }
        ],
        credits: { enabled: false },
        tooltip: { split: true },
        series: [
          {
            visible: this.min_max_values.length != 0,
            showInLegend: this.min_max_values.length != 0,
            name: this.$t('liquidity.variation'),
            type: 'areasplinerange',
            data: this.min_max_values,
            yAxis: 0,
            marker: { radius: 0 },
            tooltip: {
              pointFormat: `${this.$t('liquidity.sim_low')}: {point.low} ${this.unit}<br>${this.$t('liquidity.sim_high')}: {point.high} ${this.unit}`,
              valueDecimals: 0,
            }
          },
          {
            visible: this.values.length != 0,
            showInLegend: this.values.length != 0,
            name: this.$t('liquidity.actual'),
            type: 'spline',
            data: this.values,
            yAxis: 0,
            marker: { radius: 0 },
            color: "#00bb00",
            tooltip: {
              pointFormat: `{series.name}: {point.y} ${this.unit}`,
              valueDecimals: 0,
            },
          },
          {
            visible: this.p50.length != 0,
            showInLegend: this.p50.length != 0,
            name: `${this.$t('liquidity.base')}`,
            type: 'spline',
            dashStyle: 'shortdash',
            data: this.p50,
            yAxis: 0,
            marker: { radius: 0 },
            color: "#000000",
            tooltip: {
              pointFormat: `{series.name}: {point.y} ${this.unit}`,
              valueDecimals: 0,
            }
          },
          {
            showInLegend: this.mean_historical.length != 0,
            name: this.$t('liquidity.mean_historical'),
            visible: false,
            type: 'spline',
            dashStyle: 'shortdash',
            data: this.calculated_mean_historical_values,
            yAxis: 0,
            marker: { radius: 0 },
            color: "#FF7276",
            tooltip: {
              pointFormat: `{series.name}: {point.y} ${this.unit}`,
              valueDecimals: 0,
            },
          }
        ],
      }
    }
  }
}
</script>

<template lang="pug">
div(v-if="this.values.length == 0 && this.p50.length == 0 && this.min_max_values.length == 0")
  h4 {{ $t('liquidity.no_data_for_chart') }}
div(v-else)
  v-highcharts(:options="chart_options()")
</template>
