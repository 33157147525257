<script>
import SensorRow from './sensor_row.vue'

export default {
  components: {
    SensorRow
  },
  props: {
    power_plant_id: String,
    sensor_types: Array
  },
  data() {
    return {
      dialog: false,
      loading: true,
      sensors: [],
      query: '',
      dirty: false
    }
  },
  computed: {
    filtered_sensors() {
      return this.sensors.filter((s) => s.name && s.name.toLowerCase().includes(this.query.toLowerCase()))
    }
  },
  methods: {
    async load_sensors() {
      try {
        this.sensors = []
        this.loading = true
        const url = `/admin/power_plants/${this.power_plant_id}/tyde_sensors`
        const { data } = await this.axios.get(url)
        this.sensors = data
        this.loading = false
      } catch (error) {
        alert((error.response || {}).statusText || 'Unexpected error')
      }
    },
    showCloseButton(value) {
      this.dirty = value
    },
    closeAndReload() {
      this.dialog = false
      this.$emit('refresh', true)
    }
  },
  watch: {
    dialog() {
      if (this.dialog) this.load_sensors()
    }
  }
}
</script>
<template lang="pug">
v-list-item(@click.stop="dialog = true") Sensor configuration
  v-dialog(v-model="dialog" content-class="sensor-mapping-dialog")
    v-card(class="sensor_mapping")
      v-card-title.grey.lighten-2
        | Tyde sensor mapping
      v-card-text
        div(v-if="loading")
          | Loading Tyde sensors
          v-progress-linear(indeterminate)

        div(v-if="sensors.length > 0")
          .d-inline-block {{ sensors.length }} sensors
          v-text-field.d-inline-block.ml-2(v-model="query" placeholder="Name search" dense outlined autofocus)
          .d-inline-block(style="float: right;" v-if="dirty")
            v-btn.btn(@click="closeAndReload") Close and reload
          sensor-row(v-for="(sensor, index) in filtered_sensors" :key="sensor.id" v-model="filtered_sensors[index]" :sensor_types="sensor_types" @markDirty="showCloseButton" :position="`sensor_row_${index %2 }`")
  </template>
