<script>
export default {
  props: {
    id: String,
    name: String,
    label: String,
    value: null,
    items: Array,
    readonly: Boolean,
    filled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    errorMessages: Array,
    hideDetails: { type: String, default: 'auto' }
  },
  data() {
    return {
      selected_item: this.items.find(item => item.value == this.value)
    }
  },
  computed: {
    filtered_items() { return this.items.filter(item => item.value > 0) },
    input_value() { return this.selected_item ? this.selected_item.value : null }
  },
  methods: {
    changeHandler() {
      // Prevent new items from being added
      this.$nextTick(function () {
        if(this.selected_item && this.selected_item.value) return
        this.selected_item = undefined
      })
    }
  },
  watch: {
    input_value() {
      this.$emit('input', this.input_value)
    },
    selected_item(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<template lang="pug">
div
  v-combobox(
    v-model="selected_item"
    :items="filtered_items"
    :multiple="false"
    :chips="true"
    :small-chips="dense"
    :clearable="true"
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    :readonly="readonly"
    :label='label'
    :filled='filled'
    :dense='dense'
    :outlined='outlined'
    @change="changeHandler"
    )
  input(
    :value="input_value"
    type="hidden"
    :name="name"
  )
</template>
