<script>
import GenericChart from './components/generic.vue'
import Toggle from './components/toggle.vue'
export default {
  components: { GenericChart, Toggle },
  props: {
    sensor: {
      type: Object,
      required: true
    },
    drilldownFacade: {
      type: Object,
      required: true
    },
    currentRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      customChart: this.sensor,
      data: null,
      visible: true
    }
  },
  methods: {
    async fetchData() {
      try {
        if (this.customChart.tyde_id) {
          const response = await this.axios.get(
            `/om_dashboard/sensor_data?sensor_name=${this.customChart.name}&start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
          )
          this.data = response.data
        } else {
          console.warn(`${this.customChart.description} sensor is not available`)
        }
      } catch (error) {
        console.error(`Error fetching ${this.customChart.description} data:`, error)
      }
    },
    toggleVisible(value) {
      this.visible = value
    }
  },
  async mounted() {
    await this.fetchData()
  }
}
</script>

<template lang="pug">
div
  .heading
    Toggle(:visible="visible" @changeVisible="toggleVisible")
    span.header-text {{ customChart.description }}
    span.grey--text.pr-4 ({{ drilldownFacade?.chart_range_description }})
  v-row(no-gutters v-show="visible")
    v-col(cols="10").pr-16
      GenericChart(v-if="data" :data="data" :name="customChart.description" :unit="customChart.unit" :upperThreshold="customChart.custom_upper_limit" :lowerThreshold="customChart.custom_lower_limit")
</template>
