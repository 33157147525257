<script>
import InsightsTable from './insights_table.vue'
import HumanizeNumberMixin from '../../mixins/humanize_number_mixin.js'


const TABS = Object.freeze({
  Overview: "Overview",
  Revenues: "Revenues",
  Risk: "Risk",
  Consumption: "Consumption"
})

// The colors that will be used for the chart series, in order defined
const COLORS = [
  "#CFD8DC",
  "#78909C ",
  "#37474F",
]

export default {
  components: {
    InsightsTable
  },
  mixins: [HumanizeNumberMixin],
  props: {

  },
  data() {
    return {
      TABS: TABS,
      selected_type: TABS.Overview,
      loading: true,
      ppa_overview_data: null,
      ppa_sales_data: null,
      ppa_merchant_risk_data: null,
      consumption_data: null,
    }
  },
  async mounted() {
    const popState = () => {
      const url = new URL(window.location)
      const previous_type = url.searchParams.get("selected_type")

      if(previous_type == null || previous_type === '')
        this.selected_type = TABS.Overview
      else
        this.selected_type = previous_type
    }

    popState()

    window.addEventListener('popstate', _ => {
      popState()
    })

    try {
      const data = (await this.axios.get(window.location)).data
      this.ppa_overview_data = data.ppa_overview_data
      this.ppa_sales_data = data.ppa_sales_data
      this.ppa_merchant_risk_data = data.ppa_merchant_risk_data
      this.consumption_data = data.consumption_data
      this.loading = false
    }
    catch(ex) {
      
    }
  },
  methods: {
    set_type(type) {
      this.selected_type = type
      const url = new URL(window.location)
      url.searchParams.set("selected_type", type)
      window.history.pushState(null, '', url)
    },
    extend_serie(originalSeries) {
      const extendedSeries = [];

      for (let i = 0; i <= 11; i++) {
        if (originalSeries.length <= i) {
          extendedSeries.push(null);
        } else {
          extendedSeries.push(originalSeries[i]);
        }
      }
      return extendedSeries;
    },
    create_chart(title, unit, stacking_mode, series, additionalYAxis = [], tickAmount = undefined) {
      series = series.map((serie, i) => ({...serie, data: this.extend_serie(serie["data"]), color: COLORS[i]}))

      return {
        title: {
          text: title
        },
        legend: {
          enabled: true
        },
        credits: {
          enabled: false
        },
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function() {
            const header = `<table><tr><th style="padding-bottom: 1px; border-bottom: 1px solid silver;" colspan="2">${this.x}</th></tr>`

            let rows = ""
            this.points.forEach(point => {
              rows += `<tr><td style="padding-right: 5px; text-align: right;">${point.series.name}</td><td>${point.y}</td></tr>`
            });

            return header + rows + "</table>"
          }
        },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yAxis: [{
          title: { text: unit },
          tickAmount: tickAmount,
          labels: {
            formatter: (value) => {
              return this.humanizeNumber(value.value)
            }
          }
        }, ...additionalYAxis],
        plotOptions: {
          series: {
            stacking: stacking_mode
          }
        },
        series: series
      }
    },
    make_cumulative(data_values) {
      let sum = 0
      let cumulative_data = []

      data_values.forEach(value => {
        sum += value
        cumulative_data.push(sum)
      })
      return cumulative_data
    },
  },
  computed: {
    data_for_selected_type() {
      switch (this.selected_type) {
        case TABS.Overview:
          return this.ppa_overview_data
        case TABS.Revenues:
          return this.ppa_sales_data
        case TABS.Risk:
          return this.ppa_merchant_risk_data
        case TABS.Consumption:
          return this.consumption_data
        default:
          return { headers: [], prepend: {}, values: []}
      }
    },
    table_data() {
      return this.data_for_selected_type.values
    },
    table_headers() {
      return this.data_for_selected_type.headers
    },
    grouping_headers() {
      if(!this.data_for_selected_type.grouping_headers)
        return []

      return this.data_for_selected_type.grouping_headers
    },
    no_data() {
      return this.ppa_overview_data.values.length == 0 && this.ppa_sales_data.values.length == 0 && this.ppa_merchant_risk_data.values.length == 0 && this.consumption_data.values.length == 0
    },
    ppa_follow_up_types() {
      return Object.values(TABS)
    },
    table_prepend() {
      return this.data_for_selected_type.prepend
    },

    production_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.production"), "MWh", undefined, [
        {
          name: this.$t('follow_up.actual'),
          type: 'column',
          data: this.ppa_overview_data.values.slice(0, -1).map(v => v.prod.raw),
        },
        {
          name: this.$t('follow_up.index'),
          type: 'column',
          data: this.ppa_overview_data.values.slice(0, -1).map(v => v.ref.raw),
        },
        {
          name: this.$t('follow_up.p50'),
          type: 'line',
          data: this.ppa_overview_data.values.slice(0, -1).map(v => v.p50.raw),
        }
      ]);
    },
    full_load_factor_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.full_load_factor"), "%", undefined, [
        {
          name: this.$t('follow_up.actual'),
          type: 'column',
          data: this.ppa_overview_data.values.slice(0, -1).map(v => v.prod_cap_factor_perc.raw),
        },
        {
          name: this.$t('follow_up.p50'),
          type: 'line',
          data: this.ppa_overview_data.values.slice(0, -1).map(v => v.cap_factor_perc.raw),
        },
      ]);
    },
    spot_price_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.spot_price"), "EUR", undefined, [
        {
          name: this.$t('follow_up.spot_price'),
          type: 'line',
          data: this.ppa_overview_data.values.slice(0, -1).map(v => v.spot_price.raw)
        }
      ])
    },
    cumulative_production_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.cumulative_production"), "MWh", undefined, [
        {
          name: this.$t('follow_up.actual'),
          type: 'line',
          data: this.make_cumulative(this.ppa_overview_data.values.slice(0, -1).map(v => v.prod.raw))
        },
        {
          name: this.$t('follow_up.index'),
          type: 'line',
          data: this.make_cumulative(this.ppa_overview_data.values.slice(0, -1).map(v => v.ref.raw))
        },
        {
          name: this.$t('follow_up.p50'),
          type: 'line',
          data: this.make_cumulative(this.ppa_overview_data.values.slice(0, -1).map(v => v.p50.raw))
        }
      ])
    },
    cumulative_total_eur_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.cumulative_total_revenue"), "EUR", undefined, [
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          data: this.make_cumulative(this.ppa_overview_data.values.slice(0, -1).map(v => v.total_eur.raw))
        },
      ])
    },
    capture_rates_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.capture_rates"), "%", undefined, [
        {
          name: this.$t('follow_up.physical'),
          type: 'line',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.captured_ratio.raw),
        },
        {
          name: this.$t('follow_up.contracted'),
          type: 'line',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.hedge_ratio.raw),
        },
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.total_captured_ratio.raw),
        },
      ]);
    },
    revenue_1_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.revenues_phyiscal_financial"), "EUR", undefined, [
        {
          name: this.$t('follow_up.physical'),
          type: 'column',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.revenue.raw),
        },
        {
          name: this.$t('follow_up.financial'),
          type: 'column',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.mark_to_market.raw),
        },
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.total_eur.raw),
        },
      ]);
    },
    revenue_2_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.revenues_variable_fixed"), "EUR", undefined, [
        {
          name: this.$t('follow_up.fixed'),
          type: 'column',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.hedge_revenue.raw),
        },
        {
          name: this.$t('follow_up.variable'),
          type: 'column',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.net_residual_eur.raw),
        },
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          data: this.ppa_sales_data.values.slice(0, -1).map(v => v.total_eur.raw),
        },
      ]);
    },
    merchant_imbalance_volume_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.volume"), "MWh", 'normal', [
        {
          name: this.$t('follow_up.shortfall'),
          type: 'column',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.deficit_volume_mwh.raw),
        },
        {
          name: this.$t('follow_up.overproduction'),
          type: 'column',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.surplus_volume_mwh.raw),
        },
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.net_residual_mwh.raw),
        },
      ]);
    },
    merchant_imbalance_revenue_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.revenue"), "EUR", 'normal',[
        {
          name: this.$t('follow_up.shortfall'),
          type: 'column',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.deficit_volume_eur.raw),
        },
        {
          name: this.$t('follow_up.overproduction'),
          type: 'column',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.surplus_volume_eur.raw),
        },
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.net_residual_eur.raw),
        },
      ]);
    },
    merchant_captured_prices_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.captured_prices"), "%", undefined, [
        {
          name: this.$t('follow_up.shortfall'),
          type: 'line',
          maxPointWidth: 20,
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.deficit_volume_ratio.raw),
        },
        {
          name: this.$t('follow_up.overproduction'),
          type: 'line',
          maxPointWidth: 20,
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.surplus_volume_ratio.raw),
        }
      ]);
    },
    merchant_risk_and_cannibalism_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.risk_and_cannibalism"), "EUR", 'normal', [
        {
          name: this.$t('follow_up.cannibalism'),
          type: 'column',
          maxPointWidth: 20,
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.revenue_profile.raw),
        },
        {
          name: this.$t('follow_up.merchant_base_value'),
          type: 'column',
          maxPointWidth: 20,
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.revenue_spot.raw),
        },
        {
          name: this.$t('follow_up.total'),
          type: 'line',
          maxPointWidth: 20,
          data: this.ppa_merchant_risk_data.values.slice(0, -1).map(v => v.net_residual_eur.raw),
        },
      ]);
    },
    consumption_chart_options() {
      return this.create_chart(this.$t("follow_up.charts.consumption"), "EUR/MWh", 'normal', [
        {
          name: this.$t('follow_up.volume'),
          type: 'line',
          maxPointWidth: 20,
          data: this.consumption_data.values.slice(0, -1).map(v => v.consumption.raw),
          yAxis: 1,
        },
        {
          name: this.$t('follow_up.achived_price'),
          type: 'line',
          maxPointWidth: 20,
          data: this.consumption_data.values.slice(0, -1).map(v => v.paid_eur_mwh.raw),
          yAxis: 0,
        },
      ],
      [{
        opposite: true,
        tickAmount: 10,
        title: { text: "MWh" },
        labels: {
          formatter: (value) => {
            return this.humanizeNumber(value.value)
          }
        }
      }], 10);
    },
  },
}
</script>

<template lang="pug">
v-container
  h1.d-flex.align-center
    | {{$t('follow_up.header')}}
    v-chip.ml-2(color="purple" dark) Beta
  h2(v-if="ppa_overview_data == null && loading")
    v-row
      v-col(cols="12")
        v-card(height="553" class="d-flex justify-center align-center")
          v-progress-circular(indeterminate color="primary" :size="70" :width="7")
    v-row
      v-col(cols="6" xl="4")
        v-card(height="400" class="d-flex justify-center align-center")
          v-progress-circular(indeterminate color="primary" :size="70" :width="7")
      v-col(cols="6" xl="4")
        v-card(height="400" class="d-flex justify-center align-center")
          v-progress-circular(indeterminate color="primary" :size="70" :width="7")
      v-col(cols="6" xl="4")
        v-card(height="400" class="d-flex justify-center align-center")
          v-progress-circular(indeterminate color="primary" :size="70" :width="7")
  h2(v-else-if="no_data")
    | No Data
  div(v-else)
    v-row
      v-col(cols="12")
        v-card
          div(style="border-bottom: 2px solid #78909C;")
            div.d-flex
              div(v-for="type in ppa_follow_up_types")
                div.pointer.mr-2.pa-2.rounded-tl.rounded-tr(@click="set_type(type)" :style="selected_type == type ? 'background-color: #78909c; color: white;' : 'background-color: #b9c5cb'") {{ type }}
              div
                div.pointer.mr-2.pa-2.rounded-tl.rounded-tr(style="cursor: not-allowed; background-color: #CCCCCC; color: #898989; box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.23) inset;") Invoice Control
          insights-table(:values="table_data" :column_names="table_headers" :prepend="table_prepend" :grouping_headers="grouping_headers")
    div(v-if="selected_type === TABS.Overview")
      v-row#follow_up_charts
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="production_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="full_load_factor_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="spot_price_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="cumulative_production_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="cumulative_total_eur_chart_options")
    div(v-if="selected_type === TABS.Revenues")
      v-row
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="capture_rates_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="revenue_1_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="revenue_2_chart_options")

    div(v-if="selected_type === TABS.Risk")
      v-row
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="merchant_imbalance_volume_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="merchant_imbalance_revenue_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="merchant_captured_prices_chart_options")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="merchant_risk_and_cannibalism_chart_options")
    div(v-if="selected_type === TABS.Consumption")
        v-col(cols="6" xl="4")
          v-card
            v-card-text
              v-highcharts(:options="consumption_chart_options")

</template>
