<script>

export default {

    name: "portal-radio-buttons",

    props: {
        choices: {
            type: Array,
            required: true
        },
        formatter: {
            type: Function,
            required: false,
            default: () => (value) => value
        }
    },

    data() {
        return {
            activeChoice: 0,
        }
    },

    methods: {
        handle_button_click(index) {
            this.activeChoice = index
            const value = this.choices[index]
            this.$emit("radio-buttons-change", value)
        }
    }
}
</script>

<template lang="pug">
.captiva-radio-buttons
    div(v-for="(choice, index) in choices", :key="index", @click="handle_button_click(index)", :active="activeChoice === index")
        | {{ formatter(choice) }}
</template>
