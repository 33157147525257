<script>
import { DateTime } from 'luxon'
import ValueMixin from './value_mixins.vue'
export default {
  props: {
    current_contract: Object,
    fall_leases: Array,
    admin_user: Boolean
  },
  data() {
    return {
      local_current_contract: this.current_contract,
      local_fall_leases: this.fall_leases,
      statusConfig: {
        computing: {
          color: 'yellow',
          icon: 'mdi-progress-clock'
        },
        computation_succeeded: {
          color: 'green',
          icon: 'mdi-check-circle'
        },
        locked: {
          color: 'green',
          icon: 'mdi-lock'
        },
        unlocked: {
          color: 'white',
          icon: null
        },
        computation_failed: {
          color: 'red',
          icon: 'mdi-alert-circle'
        },
        computable: {
          color: 'white',
          icon: null
        },
        default: {
          color: 'grey',
          icon: null
        }
      }
    }
  },
  mixins: [ValueMixin],
  computed: {
    isAdmin() {
      return this.admin_user
    },
    ordered_power_plant_fall_leases() {
      return _.orderBy(this.local_fall_leases, 'start_date')
    }
  },
  methods: {
    getNotes(notes) {
      if (this.isAdmin) {
        return notes || "Admin Message: No notes available"
      } else {
        return ""
      }
    },
    getYear(date) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').year
    },
    fallLeaseStatus(contract) {
      if (contract.fall_lease.computing) {
        return 'computing'
      }

      if (contract.fall_lease.locked) {
        return 'locked'
      } else if (contract.fall_lease.unlocked) {
        return 'unlocked'
      }

      if (contract.fall_lease.computation_succeeded) {
        return 'computation_succeeded'
      } else if (contract.fall_lease.computation_failed) {
        return 'computation_failed'
      }

      if (contract.fall_lease.computable) {
        return 'computable'
      }

      return 'default'
    },
    isCurrentYear(fall_lease, contract) {
      return fall_lease.id === contract.id
    },
    stateMessage(fall_lease) {
      if (fall_lease.computation_failed) {
        return this.$t('fall_lease.computable_false')
      } else if (fall_lease.computation_succeeded) {
        return this.$t('fall_lease.computable_true')
      } else if (fall_lease.computing) {
        return this.$t('fall_lease.computable_computing')
      }

      return this.$t('fall_lease.computable_unknown')
    }
  },
  watch: {
    current_contract(value) {
      this.local_current_contract = value
    },
    fall_leases(value) {
      this.local_fall_leases = value
    }
  }
}
</script>

<template lang="pug">
v-chip-group.chip-container(class="px-4")
  v-tooltip(
    v-for="fl in ordered_power_plant_fall_leases" :key="fl.id"
    bottom
  )
    template(v-slot:activator="{ on, attrs }")
      v-chip(
        v-bind="attrs"
        v-on="on"
        :href="`/admin/fall_leases/${fl.id}`"
        :color="statusConfig[fallLeaseStatus(fl)].color"
        :small="!isCurrentYear(fl, local_current_contract)"
        class="bordered-chip"
        :style="isCurrentYear(fl, local_current_contract) ? 'border: 2px solid black !important; font-weight: 800' : ''"
      )
        v-icon(
          v-if="statusConfig[fallLeaseStatus(fl)].icon"
          left
          small
        ) {{ statusConfig[fallLeaseStatus(fl)].icon }}
        | {{ getYear(fl.start_date) }}
    span
      | {{ $t('fall_lease.computation_state') }} : {{ stateMessage(fl.fall_lease) }}
      div(v-if="fl.fall_lease.computation_failed") 
        br
        | {{ getNotes(fl.fall_lease.notes) }}
      br
      | {{ $t('fall_lease.total_fall_leases') }} : {{ noDecimal(fl.fall_lease.fall_lease) }}
</template>
