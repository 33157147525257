<script>
export default {
  props: {
    type: String,
    value: Object,
    tags: Array
  },
  data() {
    return {
      errors: [],
      product: {...this.value},
      tags_data: this.tags,
      empty_product: {
        title: "",
        body: "",
        supplier_name: "",
        supplier_contact_phone: "",
        supplier_contact_email: "",
        summary: "",
        description: "",
        card_description: "",
        image_url: "",
        tags: [],
      }
    }
  },
  computed: {
    file_name() {
      const url_split = this.product.image_url.split('/')
      const length = url_split.length
      return url_split[length -1]
    }
  },
  methods: {
    async submit (event) {
      event.preventDefault()
      const formData = new FormData(event.target);
      const tags = this.product.tags

      if(tags.length > 0) {
        tags.forEach(tag => {
          formData.append('tags[][name]', tag.name)
        })
      } else {
        formData.append('tags[]', [null])
      }
      try {
        const response = await this.request(formData)
        if(this.type == 'create') {
          this.product = this.empty_product
        } else if(this.type == 'edit') {
          this.product = {...response.data}
        }
        this.errors = []
        this.$refs.fileupload.reset()
        this.$emit('input', {...response.data})
      } catch (error) {
        error.response
        this.errorHandler(error)
      }
    },
    async request(payload) {
      switch(this.type) {
        case 'edit':
          return await this.axios.put(`/admin/marketplace_products/${this.product.id}`, payload)
        case 'create':
          return await this.axios.post("/admin/marketplace_products", payload)
      }
    },
    errorHandler(error) {
      if(error?.response?.data?.errors) {
        console.log(error.response.data.errors)
        this.errors = error.response.data.errors
      } else {
        alert(this.$t('marketplace.unexpected_error'))
      }
    },
    sharedProps (field, index) {
      return {
        hideDetails: 'auto',
        outlined: true,
        dense: true,
        label: this.label(field),
        name: field,
        id: field,
        errorMessages: this.errors[field],
        chips: false,
        class: 'mt-2',
        autoGrow: true
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.my_model.${field}`)
    },
    remove (item) {
        this.product.tags.splice(this.product.tags.indexOf(item), 1)
    },
    changeTag (tags) {
      const new_tags = tags.map(tag => {
        if(typeof(tag) == "string") {
          const existing_tag = this.tags_data.find(item => item.name == tag)
          if(existing_tag)
            return existing_tag
          else
            return {name: tag}
        } else
          return tag
      })
      this.product = {...this.product, tags: new_tags}
    },
  },
}
</script>
<template lang="pug">
  v-card(class="mx-auto")
    form(@submit.prevent="submit")
      v-card-text
        v-row
          v-card-title {{ $t("marketplace.body") }}
        v-row
          v-container
            markdown-editor(text-area-name="body", v-model="product.body", :errors="errors['body']")
        v-row
          v-container
            v-file-input(
              ref="fileupload"
              name="image"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              :label="$t('general.image')"
              v-bind="sharedProps('image')"
            )
            a(:href="product.image_url") {{file_name}}
        v-row
          v-container(id="tags_container")
            v-combobox(
              :value="product.tags"
              @input="changeTag"
              :items="tags_data"
              item-value="name"
              item-text="name"
              chips
              clearable
              :label="$t('marketplace.add_tags')"
              multiple
              solo
            )
              template( v-slot:selection="{ attrs, item, select, selected }")
                v-chip(
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                )
                  strong {{ item.name }} &nbsp;
        v-row(dense)
          v-col(cols="6")
            v-col(cols="11")
              v-row(dense)
                v-card-title {{ $t("marketplace.card_info") }}
              v-row(dense)
                v-text-field(
                  v-model="product.title"
                  :label="$t('general.title')"
                  v-bind="sharedProps('title')"
                )
              v-row(dense)
                v-textarea(
                  v-model="product.card_description"
                  :label="$t('general.card_description')"
                  v-bind="sharedProps('card_description')"
                )
          v-col(cols="6")
            v-col(cols="11")
              v-row(dense)
                v-card-title {{ $t("marketplace.contact_info") }}
              v-row(dense)
                v-text-field(
                  v-model="product.supplier_name"
                  :label="$t('general.supplier_name')"
                  v-bind="sharedProps('supplier_name')"
                )
              v-row(dense)
                v-text-field(
                  v-model="product.supplier_contact_phone"
                  :label="$t('general.supplier_contact_phone')"
                  v-bind="sharedProps('supplier_contact_phone')"
                )
              v-row(dense)
                v-text-field(
                  v-model="product.supplier_contact_email"
                  :label="$t('general.supplier_contact_email')"
                  v-bind="sharedProps('supplier_contact_email')"
                )
        v-btn(class="mt-4" type="submit") {{ $t('general.save') }}
</template>
