<script>
export default {
  props: {
    value: Array,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      availability_mappings:
        this.value.length == 0 ? this.form_data.default_availability_mapping : this.value,
      mapping_names: [
        {
          value: 'R',
          text: this.$t('activerecord.attributes.wind/availability_mapping.mapping_types.ready')
        },
        {
          value: 'U',
          text: this.$t('activerecord.attributes.wind/availability_mapping.mapping_types.unavailable')
        },
        {
          value: 'N',
          text: this.$t('activerecord.attributes.wind/availability_mapping.mapping_types.neglected')
        }
      ]
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes
        .find((a) => a.asset_class_attributes)
        .asset_class_attributes.find((a) => a.wind_availability_mappings_attributes)
        .wind_availability_mappings_attributes
    }
  },
  methods: {
    sharedProps(field) {
      return {
        errorMessages: this.errors[`asset_class.wind_availability_mappings.${field.replace('_id', '')}`],
        readonly: false,
        dense: true,
        outlined: true,
        hideDetails: 'auto',
        class: this.writable.includes(field) ? '' : 'd-none'
      }
    },
    alarmCategoryType(id) {
      return this.$t(
        `activerecord.attributes.wind/availability_mapping.alarm_category_types.${
          this.form_data.alarm_category_types.find((a) => a.value == id)?.text
        }`
      )
    }
  },
  watch: {
    availability_mappings: {
      handler() {
        this.$emit('input', this.availability_mappings)
      },
      deep: true
    }
  }
}
</script>

<template lang="pug">
div
  v-simple-table
    template(v-slot:default)
      thead
        tr
          th.text-left {{ $t('activerecord.attributes.wind/availability_mapping.alarm_category') }}
          th.text-left {{ $t('activerecord.attributes.wind/availability_mapping.total_availability') }}
          th.text-left {{ $t('activerecord.attributes.wind/availability_mapping.contractual_availability') }}
      tbody
        tr(v-for="mapping in availability_mappings" :style="mapping._destroy ? 'background-color: red' : ''")
          td
            | {{ alarmCategoryType(mapping.alarm_category_type_id) }}
          td
            v-select(:items="mapping_names" v-model="mapping.av_total" v-bind="sharedProps('av_total')")
          td
            v-select(:items="mapping_names" v-model="mapping.av_contract" v-bind="sharedProps('av_contract')")
  p {{  $t('help_texts.power_plants.wind_availability_recalculation') }}
</template>
