<script>
export default {
  props: {
    powerPlantId: {
      type: Number,
      required: true,
    },
    assetType: {
      type: String,
      required: true,
    },
    form_data: Object,
    errors: Object,
  },
  data() {
    return {
      localModelParams: {},
    }
  },
  computed: {
    modelParamKey() {
      switch (this.assetType) {
        case 'Hydro::Asset':
          return 'hydro_model_param'
        case 'Wind::Asset':
          return 'wind_model_param'
        case 'Solar::Asset':
          return 'solar_model_param'
        default:
          return 'model_param'
      }
    },
  },
  methods: {
    async fetchModelParams() {
      try {
        const response = await this.axios.get(`/power_plants/${this.powerPlantId}/model_params`)
        const allParams = response.data.model_params || {}
        this.localModelParams = allParams[this.modelParamKey] || allParams || {}
      } catch (error) {
        console.error('Error fetching model params:', error)
      }
    },
    destroy(key) {
      this.$delete(this.localModelParams, key)
    },
    sharedProps(field) {
      const modelParamAttributesKey = `${this.modelParamKey}_attributes`
      const errorKey = `asset_class.simulation_parameter.${this.modelParamKey}.${field}`

      return {
        name: `power_plant[asset_class_attributes][simulation_parameter_attributes][${modelParamAttributesKey}][${field}]`,
        id: `power_plant_asset_class_attributes_simulation_parameter_attributes_${modelParamAttributesKey}_${field}`,
        errorMessages: this.errors?.[errorKey],
        readonly: false,
        label: this.humanizeKey(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
      }
    },
    humanizeKey(key) {
      return key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
    },
    getSuffix(key) {
      const suffixes = {
        production_min: 'MW',
        production_max: 'MW',
        discharge_min: 'm³/s',
        discharge_max: 'm³/s',
        reservoir_target_percentage: '%',
        bypass_min: 'm³/s',
        bypass_max: 'm³/s',
        net_head_masl_bound_min: 'm',
        net_head_masl_bound_max: 'm',
        production_sensitivity_min: '%',
        production_sensitivity_max: '%',
      }
      return suffixes[key] || ''
    },
  },
  watch: {
    localModelParams: {
      deep: true,
      handler(newParams) {
        this.$emit('update:modelParams', newParams)
      },
    },
  },
  mounted() {
    this.fetchModelParams()
  },
}
</script>

<template lang="pug">
div#model-params
  .overline.d-inline-block Model Parameters
  v-form
    v-row(v-if="Object.keys(localModelParams).length > 0")
      v-col.pb-0(v-for="(value, key) in localModelParams" :key="key" cols="12" class="d-flex align-items-center")
        v-text-field(
          v-model="localModelParams[key]"
          v-bind="sharedProps(key)"
          :suffix="getSuffix(key)"
        )
        v-icon.ml-2(color="error" @click="destroy(key)") mdi-delete
    div(v-else)
      p No model parameters available.
</template>
