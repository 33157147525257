<script>
import KPI from '../elements/kpi.vue'
import CandybarChart from '../candybar_chart.vue'
import EnergyPerformance from '../energy_performance.vue'
import SolarProductionChart from '../solar_production_chart.vue'
import PowerCurve from '../power_curve.vue'
import KpiLosses from '../elements/kpi_losses.vue'

export default {
  name: 'OmDrilldownSolar',
  components: { KPI, CandybarChart, EnergyPerformance, SolarProductionChart, PowerCurve, KpiLosses },
  props: {
    currency_code: String,
    powerPlant: Object,
    current_market_prices: Object,
    years: Array,
    currentRange: Array,
    kpis: Object
  },
  data() {
    return {
      energyPerformance: [],
      powerPlantProduction: [],
      productionStatus: [],
      powerCurve: {}
    }
  },
  async mounted() {
    this.reset()
    await this.loadEnergyPerformance()
    await this.loadPowerPlantProduction()
    await this.loadProductionStatus()
    await this.loadPowerCurve()
  },
  methods: {
    reset() {
      this.energyPerformance = []
      this.powerPlantProduction = []
      this.productionStatus = []
      this.powerCurve = {}
    },
    loadPercentage(plant) {
      if (plant.current_effect) {
        return parseInt((plant.current_effect / plant.rated_power_mw) * 100)
      }
    },
    async loadProductionStatus() {
      let response = await this.axios.get(
        `/om_dashboard/operational_status/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.productionStatus = response.data
    },
    async loadEnergyPerformance() {
      let response = await this.axios.get(
        `/om_dashboard/energy_performance/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.energyPerformance = response.data
    },
    async loadPowerPlantProduction() {
      let response = await this.axios.get(
        `/om_dashboard/power_plant_production/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.powerPlantProduction = response.data
    },
    async loadPowerCurve() {
      let response = await this.axios.get(
        `/om_dashboard/power_curve_for_power_plant/${this.powerPlant.id}?start_time=${this.currentRange[0]}&end_time=${this.currentRange[1]}`
      )
      this.powerCurve = response.data
    }
  },
  computed: {
    lastEnergyPerformance() {
      for (let i = this.energyPerformance.length - 1; i >= 0; i--) {
        if (this.energyPerformance[i].performance_ratio !== null) {
          return this.energyPerformance[i]
        }
      }
      return { timestamp: 0, performance_ratio: 0 }
    },
    lastOutageStatus() {
      let latestOutage = { timestamp: 0, value: 0 }
      //return different message if no data based on month/week. in a different method

      for (let inverter of this.productionStatus) {
        for (let record of inverter.values) {
          if (record.value === 60 && record.timestamp > latestOutage.timestamp) {
            latestOutage = {
              ...record,
              inverter: inverter.title
            }
          }
        }
      }

      return latestOutage
    }
  },
  watch: {
    async currentRange(newValue, oldValue) {
      if (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1]) {
        this.reset()
        await Promise.all([
          this.loadEnergyPerformance(),
          this.loadPowerPlantProduction(),
          this.loadProductionStatus(),
          this.loadPowerCurve()
        ])
      }
    }
  }
}
</script>

<template lang="pug">
div.asset_wrapper
  v-container
    .kpi_wrapper 
      v-row
        v-col.graph_title {{ $t('om_dashboard.drilldown.key_performance') }}
      v-row 
        v-col 
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_produced')" :kpis="kpis.production_in_mw_for_plant" field="eac_meas_mwh" :showPercentage="true" unit=" MWh")
        v-col 
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_losses')" :kpis="kpis.losses_for_plant" field="eac_loss_mwh" unit=" MWh" :invertArrows="true")
        v-col 
          KPI(:kpiLabel="$t('om_dashboard.drilldown.time_based_availability')" :kpis="kpis.time_based_availability" field="weighted_technical_av" :tooltips="['expected_operating_hours', 'downtime_hours']" unit="%")
        v-col 
          KPI(:kpiLabel="$t('om_dashboard.drilldown.energy_based_availability')" :kpis="kpis.energy_based_availability" field="energy_based_av" unit="%")
  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.performance') }}
    v-row
      v-col.graph_subtitle {{ $t('om_dashboard.drilldown.performance_subtitle', { amount: lastEnergyPerformance.performance_ratio, timeframe: new Date(lastEnergyPerformance.timestamp).toDateString() }) }}
    v-row
      v-col
    EnergyPerformance(:values="energyPerformance")
  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.production') }}
    //-v-row
      v-col.graph_subtitle {{ $t('om_dashboard.drilldown.production_subtitle', { amount: lastEnergyPerformance.performance_ratio, timeframe: new Date(lastEnergyPerformance.timestamp).toDateString() }) }}
    v-row
      v-col
    SolarProductionChart(v-if="powerPlantProduction && powerPlantProduction.length > 0" :values="powerPlantProduction" :additionalNames="[]" :unit="'kW'")
  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.production_status_per_inverter') }}
    v-row
      v-col.graph_subtitle {{ lastOutageStatus.timestamp > 0 ? $t('om_dashboard.drilldown.last_outage', { date: new Date(lastOutageStatus.timestamp).toDateString() }): $t('om_dashboard.drilldown.no_new_outages') }}
    v-row
      v-col
    CandybarChart(:title="$t('om_dashboard.drilldown.inverter')" :values="productionStatus" tech="solar")

  v-container
    v-row
      v-col.graph_title {{ $t('om_dashboard.drilldown.power_curve') }}
    v-row
      KpiLosses.graph_subtitle(:losses="kpis.losses_for_plant.requested.eac_loss_mwh")
    v-row
      v-col
    PowerCurve(
      :values="powerCurve"
      :x_title="$t('om_dashboard.drilldown.power_curve_x_title')"
      :y_title="$t('om_dashboard.drilldown.power_curve_y_title')"
      :unit="$t('om_dashboard.drilldown.power_curve_unit_solar')"
      tech="solar"
    )
</template>
