<script>
export default {
  props: {
    value: Object,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      solar_asset: this.value
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find((a) => a.asset_class_attributes).asset_class_attributes
    }
  },
  filters: {
    pretty: (val, indent = 2) => {
      if (val === 'null') return ''
      else if (typeof val !== 'object') {
        try {
          val = JSON.parse(val)
        } catch (err) {
          console.warn('value is not JSON')
          return val
        }

        return JSON.stringify(val, null, indent)
      }
    }
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_${field}`,
        errorMessages: this.errors[`asset_class.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.solar/asset.${field}`)
    }
  }
}
</script>
<template lang="pug">
#solar-asset
  v-textarea(@input="value => solar_asset.data_source_settings_raw = value" :value="solar_asset.data_source_settings_raw | pretty(2)" v-bind="sharedProps('data_source_settings_raw')")
  div
    p(v-html="$t('activerecord.attributes.solar/asset.data_source_settings_help')")
    pre
      | {
      |   "type":"solarlog_ftp",
      |   "ftp_host":"hostname",
      |   "ftp_user":"username",
      |   "ftp_pwd":"secret",
      |   "ftp_directory":"/owner/plant_name"
      | }
    br

  v-textarea(@input="value => solar_asset.ml_learning_parameters_raw = value"  :value="solar_asset.ml_learning_parameters_raw | pretty(2)" v-bind="sharedProps('ml_learning_parameters_raw')")
  div
    p(v-html="$t('activerecord.attributes.solar/asset.ml_learning_parameters_help')")
    pre
      | {
      |   "timestamp_from":"2023-01-01T00:00:00Z",
      |   "timestamp_to":"2024-01-01T00:00:00Z",
      |   "inv_replacements":[{"replaced_by":59,"id_inv_original":60}],
      |   "mppt_replacements":[{"replaced_by":303,"id_mppt_original":308}]
      | }
    br

  v-textarea(@input="value => solar_asset.anomaly_thresholds_raw = value"  :value="solar_asset.anomaly_thresholds_raw | pretty(2)" v-bind="sharedProps('anomaly_thresholds_raw')")
  div
    p(v-html="$t('activerecord.attributes.solar/asset.anomaly_thresholds_help')")
    pre
      | {
      |   "idc": {
      |      "validity_15m_min_gpoa": 100.0,
      |      "validity_15m_min_idc": 0.2,
      |      "validity_15m_min_zerocurrent_idc": 0.1,
      |      "validity_min_1d_avail_samples_ratio": 0.8,
      |      "validity_min_lowcurrent_samples": {"1d": 8, "1w": 5},
      |      "thres_lowcurrent': {"1d": [-0.2, -0.1], "1w": [-0.08, -0.05]},
      |      "validity_min_zerocurrent_samples': {"1d": 2},
      |      "thres_zerocurrent': {"15m": [0.005, 0.02], "1d": [2, 2]},
      |      "thres_lowcurrent_field': {"1d": [-0.65, -0.65]}
      |  }
      |   "udc": {
      |     "validity_15m_min_gpoa": 100.0,
      |     "validity_15m_min_pdc_lowvoltage": 0.1,
      |     "validity_15m_min_pdc_highvoltage": 0.2,
      |     "validity_15m_min_udc": 0.7,
      |     "validity_min_1d_avail_samples_ratio": 0.8,
      |     "validity_min_lowvoltage_samples": {"1d": 8, "1w": 5},
      |     "thres_lowvoltage": {"1d": [-0.05, -0.04], "1w": [-0.04, -0.035]},
      |     "thres_highvoltage": {"15m": [0.07, 0.06], "1d": [5, 5]}
      | }
      |   "fuac": {
      |     "validity_15m_min_pac_predict": 0.05,
      |     "thres_freq_low": {"15m": [47.5, 49.8], "1d": [1, 1]},
      |     "thres_freq_high": {"15m": [51.5, 50.2], "1d": [1, 1]},
      |     "thres_voltage_low": {"15m": [184, 195],"1d": [4, 4]},
      |     "thres_voltage_high": {"15m": [253, 253],"1d": [1, 1]}     
      |   },
      |   "min_gpoa_availability_wm2": 60.0
      | }
    br

</template>
