<script>
export default {
  props: {
    data: Array,
    title: String,
    currency_code: String,
  },
  data() {
    return {
      point_width: this.data.length > 100 ? 14 : 20
    }
  },
  methods: {
  },
  computed: {
    chart_options () {
      return {
        chart: {
          type: 'areaspline',
          zoomType: 'x'
        },
        title: {
          text: this.title,
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: '%H:%M'
          },
        },
        yAxis: [{
          title: {
            text: null
          },
          labels: {
            formatter() {
              if (this.value > 1000000)
                return `${(this.value/1000000)}m${this.chart.series[0].userOptions.unit}`
              else if(this.value > 1000)
                return `${(this.value/1000)}k${this.chart.series[0].userOptions.unit}`
              else
                return `${(this.value)}${this.chart.series[0].userOptions.unit}`
            }
          },
        }],
        credits: {
          enabled: false
        },
        rangeSelector: {
          enabled: true,
          inputEnabled: false
        },
        scrollbar: {
          enabled: false
        },
        navigator: {
          enabled: true
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.75,
            fillColor: 'rgba(68, 170, 213, .2)'
          }
        },
        series: [
          {
            name: this.$t('portfolio_stats.hourly_revenue'),
            data: this.data,
            unit: this.currency_code,
            tooltip: {
              valueDecimals: 0
            }
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
