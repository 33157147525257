<script>
export default {
  props: {
    value: Boolean,
    true_color: { type: String, default: 'green' },
    false_color: { type: String, default: 'error' },
    true_label: { type: String },
    false_label: { type: String },
  },
  computed: {
    color () {
      return this.value == true ? this.true_color : this.false_color
    },
    chip_text () {
      return this.value == true ? (this.true_label || this.$t('general.yes')) : (this.false_label || this.$t('general.no'))
    },
  },
}
</script>
<template lang="pug">
v-chip(:color="color" dark x-small) {{ chip_text }}
</template>
