<script>
import Vue from 'vue'
import MinMaxExpectedChart from './min_max_expected_chart.vue'
export default {
  props: {
    min_max_revenue: Array,
    revenue: Array,
    current_revenue: Array,
    min_max_price: Array,
    expected_price: Array,
    current_spot_price: Array,
    min_max_prod: Array,
    expected_prod: Array,
    mean_historical_prod: Array,
    current_prod: Array,
    power_plants: Array,
    data_sources: Array,
  },
  components: {
    MinMaxExpectedChart
  },
  computed: {
    auth_token() {
      return Vue.prototype.csrf_token
    }
  },
  data() {
    return {
      showImport: false,
    }
  }
}

</script>

<template lang="pug">
v-container
  div.d-flex.align-center.mb-2
    h1.mr-2 {{ $t('liquidity.liquidity') }}
    v-btn(color="green" @click="showImport = !showImport" dark small)
      | Import data
    
  v-row(v-if="showImport")
    v-col
      v-card
        form(action='/ppa/liquidity' method='post' enctype='multipart/form-data')
          input(type="hidden" name="authenticity_token" :value="auth_token" autocomplete="off")
          v-card-title
            | {{$t("csv_import.ppa.title")}}
          v-card-text
            v-file-input(append-icon='mdi-paperclip' prepend-icon name='file' :label="$t('csv_import.file_label')" dense filled)
            v-searchable-select(:label="$t('csv_import.power_plant_id')" name="power_plant_id" :items="power_plants" dense filled)
            v-select.mt-4(:label="$t('general.data_source')" name="data_source" :items="data_sources" dense filled)
          v-card-actions
            v-btn(color="primary" type='submit')
              | {{$t("csv_import.import")}}
  v-row
    v-col
      v-card
        v-card-title
          | {{ $t('liquidity.simulated_revenue_development') }}
        v-card-text
          MinMaxExpectedChart(:min_max_values="min_max_revenue" :values="current_revenue" :p50="revenue" :y_axis_title="$t('liquidity.revenue')" unit="kEUR")
  v-row
    v-col
      v-card
        v-card-title
          | {{ $t('liquidity.production') }}
        v-card-text
          MinMaxExpectedChart(:min_max_values="min_max_prod" :values="current_prod" :mean_historical="mean_historical_prod" :p50="expected_prod" :y_axis_title="$t('liquidity.production')" unit="MWh")
  v-row
    v-col
      v-card
        v-card-title
          | {{ $t('liquidity.spot_price') }}
        v-card-text
          MinMaxExpectedChart(:min_max_values="min_max_price" :values="current_spot_price" :p50="expected_price" :y_axis_title="$t('liquidity.spot_price')" unit="EUR/MWh")
</template>
