<script>
export default {
  props: {
    show_all_option: Boolean,
    current: String,
    preloaded_power_plants: { type: Array, default() { return [] } },
  },
  data() {
    return {
      power_plants: this.preloaded_power_plants,
      query: '',
      menuOpened: null,
      current_power_plant_key: 0,
    }
  },
  computed: {
    selectedPriceArea() {
      const searchParams = new URLSearchParams(window.location.search)
      const price_area = searchParams.get("price_area")
      return price_area == 'ALL' ? null : price_area
    },
    filteredPowerPlants() {
      const power_plants = this.power_plants.slice()
      const option_all = { name: this.$t('general.all'), id: 'ALL' }
      if(this.show_all_option &&  power_plants.findIndex(itm => itm.id == option_all.id) == -1) {
        power_plants.unshift(option_all)
      }

      return power_plants.filter((powerPlant) => {
        if(this.selectedPriceArea && this.selectedPriceArea != powerPlant.price_area)
          return false

        return powerPlant.name.toLowerCase().includes(this.query.toLowerCase())
      })
    },
    selected_power_plant() {
      // We need this key to force an update of the list group, seems like some kind of bug
      // ref: https://github.com/vuetifyjs/vuetify/issues/11405#issuecomment-917656780
      this.current_power_plant_key++
      return this.filteredPowerPlants.findIndex(power_plant => power_plant.name == this.current)
    },

    filterable() { return this.power_plants.length > 10 },
  },
  methods: {
    focusInput() {
      setTimeout(()=>{
        this.$refs.query.focus()
      },300);
    },
    merge_link(power_plant_id) {
      if(window.location.href.includes('/power_plants/')) {
        return window.location.href.replace(/\/power_plants\/\d+/, `/power_plants/${power_plant_id}`)
      } else if (window.location.href.includes('/om_dashboard\/drilldown/')) {
        return window.location.href.replace(/\/drilldown\/\d+/, `/drilldown/${power_plant_id}`)
       }
      else {
        const url = new URL(window.location.href);
        url.searchParams.set('power_plant_id', power_plant_id);
        return url.href;
      }
    },
    async loadPowerPlants() {
      if (this.power_plants.length > 0) return
      const { data } = await this.axios.get('/power_plants')
      this.power_plants = data
    },
  },
  watch: {
    async menuOpened() {
      await this.loadPowerPlants()
      if(this.menuOpened && this.filterable) {
        this.focusInput()
      } else {
        this.query = ''
      }
    }
  }
}
</script>

<template lang="pug">
v-menu(v-model="menuOpened")
  template(v-slot:activator="{ on }")
    a(v-on="on" id="power-plant-nav")
      v-chip.pointer(class="ma-2" color="light-blue" text-color="white")
        | {{$t('general.power_plant')}}: {{ current }}
  v-card(style="max-height: 90vh;")
    v-list(dense)
      v-list-item(v-if="filterable" @click.stop="")
        v-list-item-title
          v-text-field(v-model="query" type="text" ref="query" :placeholder="$t('general.search')" dense)
      v-list-item-group(:key="current_power_plant_key", :value="selected_power_plant")
        v-list-item(v-for="power_plant in filteredPowerPlants" :key="power_plant.id" :href="merge_link(power_plant.id)")
          v-list-item-title {{ power_plant.name }}
</template>
