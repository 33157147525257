<script>

export default {
  props: {
    wind_turbine_type_id: Number
  },
  data() {
    return {
      alarmCategoryTypes: [],
      eventMappings: [
        {
          wind_turbine_type_id: this.wind_turbine_type_id,
          turbine_event_code: 'Loading...',
          turbine_event_name: '',
          alarm_category_type_id: null,
        },
      ],
      headers: [
        { value: "changed", text: "", sortable: false, class: "pa-0", cellClass: "pa-0" },
        { value: "turbine_event_code", text: this.$t('activerecord.attributes.wind/event_mappings.turbine_event_code') },
        { value: "turbine_event_name", text: this.$t('activerecord.attributes.wind/event_mappings.turbine_event_name') },
        { value: "alarm_category_type_id", text: this.$t('activerecord.attributes.wind/event_mappings.alarm_category_type'), sort: this.sortAlarmCategoryType },
        { value: "actions", text: "", sortable: false, class: "pa-0", cellClass: "pa-0" },
      ],
      file: null,
      showHelp: false,
      uploadErrors: {},
      saving: false,
      importing: false,
      search: null,
      pagination: {},
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const alarmCategoryTypes = await this.axios.get('/admin/wind/event_mappings/alarm_category_types')
      if (alarmCategoryTypes.data) this.alarmCategoryTypes = alarmCategoryTypes.data

      const eventMappings = await this.axios.get(`/admin/wind/event_mappings/?wind_turbine_type_id=${this.wind_turbine_type_id}`)
      if (eventMappings.data) this.eventMappings = eventMappings.data
    },
    addNew() {
      this.eventMappings.push({ wind_turbine_type_id: this.wind_turbine_type_id, changed: true })

      // Set page to last and clear search
      this.search = null
      this.pagination.page = this.pagination.pageCount
    },
    async save() {
      if(!window.confirm(this.$t("general.are_you_sure"))) {
        return
      }

      this.saving = true

      for(const eventMapping of this.eventMappings.filter(filter => filter.delete)) {
        try {
          await this.axios.delete(`/admin/wind/event_mappings/${eventMapping.id}`)
          eventMapping.errors = {}
        } catch(exception) {
          eventMapping.errors = exception.response.data
        }
      }

      for(const eventMapping of this.eventMappings.filter(filter => !filter.delete && filter.changed)) {
        try {
          if(eventMapping.id) {
            await this.axios.patch(`/admin/wind/event_mappings/${eventMapping.id}`, { wind_event_mapping: eventMapping })
          }
          else {
            const response = await this.axios.post('/admin/wind/event_mappings', { wind_event_mapping: eventMapping })
            eventMapping.id = response.data.id
          }

          eventMapping.errors = {}
          eventMapping.changed = false
        } catch(exception) {
          eventMapping.errors = exception.response.data
        }
      }

      this.eventMappings = [...this.eventMappings.filter(filter => !filter.delete)]

      this.saving = false
    },
    destroy(eventMapping) {
      if(eventMapping.id) {
        eventMapping.delete = eventMapping.delete ? false : true
        this.eventMappings = [...this.eventMappings]
      }
      else {
        this.eventMappings.splice(this.eventMappings.indexOf(eventMapping), 1)
      }
    },
    sharedProps(field, mapping) {
      return {
        name: `wind_event_mapping[${field}]`,
        id: `wind_event_mapping_attributes_${field}`,
        dense: true,
        outlined: true,
        hideDetails: 'auto',
        errorMessages: mapping?.errors ? mapping.errors[field] : '',
      }
    },
    async uploadEventMappings() {
      if(!window.confirm(this.$t('event_mapping.destroy_warning'))) {
        return
      }
      this.uploadErrors = {}
      this.importing = true
      
      let data = new FormData()
      data.append('file', this.file)
      try {
        await this.axios.post(`/admin/wind/event_mappings/upload?wind_turbine_type_id=${this.wind_turbine_type_id}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        this.loadData()
      } catch (error) {
        this.uploadErrors = error.response.data
        this.importing = false
      }

      this.importing = false
    },
    changeField(eventMapping, field, value) {
      eventMapping[field] = value
      eventMapping.changed = true
    },
    sortAlarmCategoryType(a, b) {
      const nameA = this.alarmCategoryTypes.find(filter => filter.id === a)?.name
      const nameB = this.alarmCategoryTypes.find(filter => filter.id === b)?.name

      if(nameA < nameB) return -1
      if(nameA > nameB) return 1
      return 0
    },
  },
}
</script>


<template lang="pug">

div
  v-card.mb-2
    v-card-title
      | {{ $t('event_mapping.import') }}
    v-card-text
      v-file-input(
        v-model="file"
        v-bind="sharedProps('file')"
        :label="$t('event_mapping.xlsx_file_input')"
        accept=".xlsx"
        append-icon="mdi-help-circle-outline"
        @click:append="showHelp = !showHelp"
        :error-messages="uploadErrors.file"
        dense
      )
    v-card-actions
      v-btn(@click="uploadEventMappings()" color="primary")
        div(v-if="!importing")
          | {{ $t('csv_import.import') }}
        div(v-else)
          v-progress-circular(indeterminate)

  v-card
    v-card-title
      div {{ $t('event_mapping.title') }}
      v-spacer
      v-btn(color="green" :href="`/admin/wind/event_mappings.xlsx?wind_turbine_type_id=${this.wind_turbine_type_id}`" small dark)
        v-icon(left) mdi-file-excel
        | {{ $t('power_plants.export_xlsx') }}
    v-card-text
      v-text-field(
        v-model="search"
        :label="$t('general.search')"
        append-icon="mdi-magnify"
        hideDetails='auto'
        single-line
        dense
        outlined
      )
      v-data-table(:items="eventMappings" :headers="headers" :search="search" :page="pagination.page" @pagination="p => pagination = p")
        template(v-slot:item.changed="{ item }")
          div.text-center(v-if="item.changed")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-icon(v-bind="attrs", v-on="on") mdi-alert-circle
              span Field changed
        template(v-slot:item.turbine_event_code="{ item }")
          v-text-field(
            v-model="item.turbine_event_code"
            @input="a => changeField(item, 'turbine_event_code', a)"
            v-bind="sharedProps('turbine_event_code', item)"
          )
        template(v-slot:item.turbine_event_name="{ item }")
          v-text-field(
            v-model="item.turbine_event_name"
            @input="a => changeField(item, 'turbine_event_name', a)"
            v-bind="sharedProps('turbine_event_name', item)"
          )
        template(v-slot:item.alarm_category_type_id="{ item }")
          v-select(
            :items="alarmCategoryTypes"
            item-text="name"
            item-value="id"
            :value="item.alarm_category_type_id"
            @input="a => changeField(item, 'alarm_category_type_id', a)"
            v-bind="sharedProps('alarm_category_type', item)"
          )
        template(v-slot:item.actions="{ item }")
          v-btn(@click="destroy(item)" :color="!item.delete ? 'error' : 'primary'" elevation="1" small fab dark)
              v-icon(dark) {{ !item.delete ? 'mdi-delete' : 'mdi-undo' }}
        template(v-slot:body.append)
          tr
            td(@click="addNew" colspan="5" style="cursor: pointer")
              v-icon mdi-plus
   
    v-card-actions
      div.d-flex
        div.mr-4
          v-btn(@click="save" color="primary")
            div(v-if="!saving")
              | {{ $t('general.save') }}
            div(v-else)
              v-progress-circular(indeterminate)

  v-dialog(v-model="showHelp" max-width="500px")
    v-card
      v-card-title
        | {{ $t('csv_import.example_xlsx') }}
      v-card-text
        v-row(class="text-right bold")
          v-col(style="font-weight: bold") turbine_event_code
          v-col(style="font-weight: bold") turbine_event_name
          v-col(style="font-weight: bold") alarm_category_type
        v-row(class="text-right")
          v-col(cols="4") 3684
          v-col(cols="4") Grid Voltage Low
          v-col(cols="4") grid
        v-row(class="text-right")
          v-col(cols="4") 0
          v-col(cols="4") Turbine ok
          v-col(cols="4") turbine_ok
        v-row(class="text-right")
          v-col(cols="4") 42
          v-col(cols="4") Aviation light error
          v-col(cols="4") owner
        v-row
          v-col(cols="12")
            div(style="padding-top: 16px;") <b>Valid alarm_category_types;</b> no_data, turbine_ok, manufacturer, <br> grid, environmental, owner, force_majeure
      v-card-actions
        v-btn(@click="showHelp = false")
          | {{ $t('general.close') }}
</template>
