<script>
import draggable from "vuedraggable";
import EditWorkItemDialog from './edit_work_item.vue'
import LabelSelector from './label_selector.vue'

export default {
  name: 'Cards',
  components: {
    draggable,
    EditWorkItemDialog,
    LabelSelector
  },
  props: {
    labels: Array,
    name_query: String,
    current_labels: Array,
    current_responsibles: Array,
    components: Array,
    power_plants: Array,
    allow_label_edit: Boolean,
    responsibles: Array,
    currency_code: String,
  },
  data () {
    return {
      dragChange: {},
      showEditWorkItemDialog: false,
      currentWorkItem: {},
      expandedColumns: {},
      screenWidth: window.innerWidth,
      columnData: []
    }
  },
  async mounted() {
    await this.fetchWorkItems()
    
    const urlParams = new URLSearchParams(window.location.search)
    window.addEventListener('resize', this.updateScreenWidth)
    const workItemId = urlParams.get('work_item_id')
    if(workItemId) {
      const workItem = this.findCard(workItemId)
      if(!workItem) return
      this.currentWorkItem = workItem
      this.showEditWorkItemDialog = true
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },  
  created() {
    window.onpopstate = () => {
      const urlParams = new URLSearchParams(window.location.search)
      const workItemId = urlParams.get('work_item_id')

      if(!workItemId) {
        this.currentWorkItem = {}
        this.showEditWorkItemDialog = false
      } else {
        const workItem = this.findCard(workItemId)
        if(!workItem) return
        this.currentWorkItem = workItem
        this.showEditWorkItemDialog = true
      }
    }
  },
  methods: {
    findCard(id) {
      let result = null

      this.columnData.forEach(column => {
        const card = column.children.find(card => card.id == id)
        if(card) result = card
      })

      return result;
    },
    async fetchWorkItems() {
      const response = await this.axios.get(window.location)
      this.columnData = response.data
    },
    toggleColumn(columnId) {
      this.$set(this.expandedColumns, columnId, !this.expandedColumns[columnId])
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    newWorkItem() {
      this.currentWorkItem = {}
      this.showEditWorkItemDialog = true
    },
    editWorkItem(workItem) {
      this.currentWorkItem = workItem
      this.showEditWorkItemDialog = true
    },
    async onDragEnd(result) {
      const { oldIndex, newIndex, to, from } = result
      const movedFrom = from.id
      const movedTo = to.id

      if (oldIndex == newIndex && movedFrom == movedTo) return // there is no actual change in position

      const movedFromColumn = this.columnData.find(column => column.id == movedFrom).children
      const movedToColumn = this.columnData.find(column => column.id == movedTo).children

      const item = movedToColumn[newIndex]
      const nextItem = movedToColumn[newIndex + 1] || null

      if(movedFrom == "closed" && movedTo != "closed") {
        // Show a confirm dialog
        if(!confirm(this.$t('general.are_you_sure'))) {
          this.fetchWorkItems()
          return
        }
      }
      await this.axios.patch(`/work_items/${item.id}/move_item`, { nextItem: nextItem?.id, newStatus: movedTo })
    }
  }
}
</script>

<template lang="pug">
div
  .work-items-container
    .columns
      div(class="column" v-for="column in this.columnData")
        .header(@click="screenWidth < 600 ? toggleColumn(column.id) : null")
          p.column-name {{ column.name }}
          v-spacer
          v-avatar.mr-2(color="#00acf1" size="32")
            span.white--text {{ column.children.length }}
          div.arrow-icons(v-if="screenWidth < 600")
            v-icon(v-if="expandedColumns[column.id]" @click="toggleColumn(column.id)") mdi-chevron-up
            v-icon(v-else="" @click="toggleColumn(column.id)") mdi-chevron-down 
        .cards(v-show="screenWidth >= 600 || expandedColumns[column.id]")
          draggable(class="cards" :id="column.id" :list="column.children" group="workItem" @end="onDragEnd" :scroll-sensitivity="250")
            div(v-for="(item, index) in column.children" :key="item.id" :data-item-id="item.id")
              .card.d-flex.flex-column(v-on:click="editWorkItem(item)")
                .d-flex
                  p.flex-grow-1(style="font-size: 16px") 
                    |{{ item.title }}
                  v-avatar(size="36" class="mr-2")
                    img(alt="Avatar" :src="item.responsible.avatar")
                .label-container.d-flex
                  .label-container.flex-grow-1
                    v-chip( :key="label.id" v-for="(label, index) in [{name: item.power_plant, color: 'green'}, ...item.work_item_labels]" :color="label.color" small class="white--text mr-2 mb-2")
                      span.v-chip__content
                        | {{ label.name }}

    v-card.sidebar
      LabelSelector(:currentLabels="current_labels" :currentResponsibles="current_responsibles" :query="name_query" :labels="labels" @change="fetchWorkItems()" :responsibles="responsibles" )

    EditWorkItemDialog(
      v-model="showEditWorkItemDialog"
      :workItem="currentWorkItem"
      :components="components"
      :powerPlants="power_plants"
      :labels="this.labels"
      :allow_label_edit="allow_label_edit"
      :currency_code="this.currency_code"
      returnUrl="/work_items"
      )

    v-btn(@click="newWorkItem" color="light-blue" title="Add new workItem" fixed dark fab right bottom)
      v-icon mdi-plus
</template>
