<script>
import BooleanChip from '../common/boolean_chip.vue'
import PowerPlantForm from './form/index.vue'
import Responsible from '../responsibles/responsible.vue'

export default {
  components: {
    BooleanChip,
    PowerPlantForm,
    Responsible
  },
  props: {
    plants: Array,
    powerplant_types: Array,
    building_types: Array
  },
  data() {
    return {
      power_plants: this.plants,
      query: '',
      editing_power_plant: null,
      footerProps: {
        'items-per-page-options': [200]
      },
      headers: [
        { text: '#', value: 'id', default: false },
        { text: 'Nve nr', value: 'nve_nr', default: false },
        { text: this.$t('activerecord.attributes.power_plant.name'), value: 'name' },
        {
          text: this.$t('activerecord.attributes.power_plant.rated_power_mw'),
          value: 'rated_power_mw',
          sortable: false
        },
        {
          text: this.$t('activerecord.attributes.power_plant.generator_capacity_mva'),
          value: 'generator_capacity_mva',
          sortable: false
        },
        { text: this.$t('activerecord.attributes.power_plant.max_effect'), value: 'max_effect' },
        { text: this.$t('activerecord.attributes.power_plant.site_address'), value: 'site_address' },
        { text: this.$t('activerecord.attributes.power_plant.grid_owner'), value: 'grid_owner_name' },
        { text: this.$t('activerecord.attributes.power_plant.go_date'), value: 'go_date' },

        { header: this.$t('responsible.responsibles') },
        { divider: true },
        {
          text: this.$t('activerecord.attributes.power_plant_responsible.role_values.ceo'),
          value: 'ceo',
          sortable: false
        },
        {
          text: this.$t('activerecord.attributes.power_plant_responsible.role_values.operator'),
          value: 'operator',
          sortable: false
        },
        {
          text: this.$t('activerecord.attributes.power_plant_responsible.role_values.plant_supervisor'),
          value: 'plant_supervisor',
          sortable: false
        },
        {
          text: this.$t('activerecord.attributes.power_plant_responsible.role_values.vta'),
          value: 'vta',
          sortable: false
        },
        {
          text: this.$t('activerecord.attributes.power_plant_responsible.role_values.hv_leader'),
          value: 'hv_leader',
          sortable: false
        },

        { header: this.$t('power_plants.asset_class_types.hydro/asset') },
        { divider: true },
        {
          text: this.$t('activerecord.attributes.power_plant.reservoir'),
          sortable: false,
          value: 'reservoir'
        },
        {
          text: this.$t('activerecord.attributes.hydro/reservoir.reservoir_name'),
          sortable: false,
          value: 'reservoir_name',
          default: true
        },
        {
          text: this.$t('activerecord.attributes.hydro/intake.intake_type'),
          value: 'intake_type',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.power_plant.turbine_count'),
          value: 'turbine_count',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.hydro/turbine.turbine_type'),
          value: 'turbine_type',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.hydro/turbine.masl'),
          value: 'turbine_level_masl',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.hydro/intake.trash_rack_cleaner'),
          value: 'trash_rack_cleaner',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.hydro/asset.pipe_rupture_valve'),
          value: 'pipe_rupture_valve',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.hydro/asset.bypass_valve'),
          value: 'bypass_valve',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.hydro/asset.winter_closed'),
          value: 'winter_closed',
          sortable: false,
          default: false
        },
        {
          text: `${this.$t('activerecord.attributes.hydro/generator.effect_limitation')} [MW]`,
          sortable: false,
          value: 'effect_limitation'
        },

        { header: this.$t('power_plants.asset_class_types.wind/asset') },
        { divider: true },

        { header: this.$t('power_plants.asset_class_types.solar/asset') },
        { divider: true },
        {
          text: this.$t('activerecord.attributes.solar/inverter.modules_count'),
          value: 'modules_count',
          sortable: false,
          default: false
        },

        { header: 'NVE metadata' },
        { divider: true },
        {
          text: this.$t('activerecord.attributes.nve_metadata.date_oldest_power_producing_part'),
          value: 'nve_metadata_attributes.date_oldest_power_producing_part',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.date_inital_utilization_of_waterfall'),
          value: 'nve_metadata_attributes.date_inital_utilization_of_waterfall',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.regine_nr'),
          value: 'nve_metadata_attributes.regine_nr',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.precipitation_area'),
          value: 'nve_metadata_attributes.precipitation_area',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.sp_nr_watercourse'),
          value: 'nve_metadata_attributes.sp_nr_watercourse',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.sp_nr_point_reference'),
          value: 'nve_metadata_attributes.sp_nr_point_reference',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.min_throughput_unit'),
          value: 'nve_metadata_attributes.min_throughput',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.nve_production_downstream_powerplant'),
          value: 'nve_metadata_attributes.production_downstream_powerplant_name',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.nve_floodwater_downstream_powerplant'),
          value: 'nve_metadata_attributes.floodwater_downstream_powerplant_name',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.nve_bypasswater_downstream_powerplant'),
          value: 'nve_metadata_attributes.bypasswater_downstream_powerplant_name',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.building_type'),
          value: 'nve_metadata_attributes.building_type',
          sortable: false,
          default: false
        },
        {
          text: this.$t('activerecord.attributes.nve_metadata.powerplant_type'),
          value: 'nve_metadata_attributes.powerplant_type',
          sortable: false,
          default: false
        },

        { divider: true },
        { text: 'Show/Edit', value: 'menu' }
      ],
      selectedHeadersValues: [],
      selectedHeaders: []
    }
  },
  methods: {
    mounted() {
      this.$refs.power_plant_query.focus()
    },
    loadLocalStorageHeaders() {
      let header_values = JSON.parse(localStorage.getItem('meta_data_table_headers'))
      if (header_values && header_values.length > 0) {
        this.selectedHeadersValues = header_values
      } else {
        this.selectedHeadersValues = this.defaultHeaders
      }
      this.selectedHeaders = this.headers.filter((h) =>
        this.selectedHeadersValues.some((sh) => h.value === sh)
      )
    },
    editComplete() {
      const idx = this.power_plants.findIndex((p) => p.id == this.editing_power_plant.id)
      Object.assign(this.power_plants[idx], this.editing_power_plant)
      this.editing_power_plant = null
    },
    filterUnpersisted(collection) {
      return collection ? collection.filter((c) => !c._destroy) : []
    },
    atLeastOneEnabled(collection, field) {
      return this.filterUnpersisted(collection)
        .map((c) => c[field])
        .includes(true)
    },
    sumOrBlank(collection, field, decimals = 2) {
      const values = this.filterUnpersisted(collection)
        .map((c) => c[field])
        .filter((x) => ![null, ''].includes(x))
      if (values.length == 0) return null
      return values.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(decimals)
    },
    uniqueValues(collection, field) {
      const values = this.filterUnpersisted(collection).map((i) => i[field])
      return [...new Set(values)].join(', ')
    },
    itemClass(item) {
      return `plant-row plant-id-${item.id}`;
    },
  },
  created() {
    this.loadLocalStorageHeaders()
  },
  computed: {
    defaultHeaders() {
      return this.headers.filter((h) => h.default != false).map((h) => h.value)
    },
    showHeaders: function () {
      return this.headers.filter((h) => this.selectedHeadersValues.some((sh) => h.value === sh))
    }
  },
  watch: {
    selectedHeaders: function (newState, oldState) {
      this.selectedHeadersValues = this.selectedHeaders.map((h) => h.value)
      localStorage.setItem('meta_data_table_headers', JSON.stringify(this.selectedHeadersValues))
    }
  }
}
</script>

<template lang="pug">
div
  .d-flex.align-end.mb-1
    v-text-field.mr-2.mt-0.pt-0(v-model="query" ref="power_plant_query" append-icon="mdi-magnify" :label="$t('general.filter_data')" single-line hide-details)
    v-select.flex-grow-0(v-model="selectedHeaders" :items="headers" :label="$t('general.select_columns')" :menu-props="{maxHeight: '70vh', minWidth: 325, offsetOverflow: true}" multiple outlined dense return-object hide-details)
      template(v-slot:selection="{item, index}")

  v-data-table(
    :headers="showHeaders"
    :items="power_plants"
    :search="query"
    :footer-props="footerProps"
    class="elevation-4"
    dense
    :item-class="itemClass"
  )

    template(v-slot:item.nve_nr="{item, value}")
      span(v-if="value") {{ value }}
      v-chip(v-else-if="item.project" color='orange' dark x-small) project
      v-chip(v-else color='error' dark x-small) missing

    // Responsible values
    template(v-slot:item.ceo="{item}")
      div(v-for="responsible in filterUnpersisted(item.power_plant_responsibles)" v-if="responsible.roles.includes('ceo')" )
        Responsible(:key="responsible.id" :value="responsible.responsible")
        br
    template(v-slot:item.operator="{item}")
      div(v-for="responsible in filterUnpersisted(item.power_plant_responsibles)" v-if="responsible.roles.includes('operator')" )
        Responsible(:key="responsible.id" :value="responsible.responsible")
        br
    template(v-slot:item.plant_supervisor="{item}")
      div(v-for="responsible in filterUnpersisted(item.power_plant_responsibles)" v-if="responsible.roles.includes('plant_supervisor')" )
        Responsible(:key="responsible.id" :value="responsible.responsible")
        br
    template(v-slot:item.hv_leader="{item}")
      div(v-for="responsible in filterUnpersisted(item.power_plant_responsibles)" v-if="responsible.roles.includes('hv_leader')" )
        Responsible(:key="responsible.id" :value="responsible.responsible")
        br
    template(v-slot:item.vta="{item}")
      div(v-for="responsible in filterUnpersisted(item.power_plant_responsibles)" v-if="responsible.roles.includes('vta')")
        Responsible(:key="responsible.id" :value="responsible.responsible")
        br

    // NveMetadata values
    template(v-slot:item.nve_metadata_attributes.production_downstream_powerplant_name="{value}")
      v-chip(v-if="value" color='primary' dark small) {{ value }}
    template(v-slot:item.nve_metadata_attributes.floodwater_downstream_powerplant_name="{value}")
      v-chip(v-if="value" color='primary' dark small) {{ value }}
    template(v-slot:item.nve_metadata_attributes.bypasswater_downstream_powerplant_name="{value}")
      v-chip(v-if="value" color='primary' dark small) {{ value }}
    template(v-slot:item.nve_metadata_attributes.building_type="{item, value}")
      div(v-if="item.nve_metadata_attributes && item.nve_metadata_attributes.building_type") {{ $t(`activerecord.attributes.nve_metadata.building_types.${value}`) }}
    template(v-slot:item.nve_metadata_attributes.powerplant_type="{item, value}")
      div(v-if="item.nve_metadata_attributes && item.nve_metadata_attributes.powerplant_type") {{ $t(`activerecord.attributes.nve_metadata.powerplant_types.${value}`) }}

    // Hydro::Asset values
    template(v-slot:item.pipe_rupture_valve="{item}")
      boolean-chip(:value="item.asset_class_attributes.pipe_rupture_valve")
    template(v-slot:item.bypass_valve="{item}")
      boolean-chip(:value="item.asset_class_attributes.bypass_valve")
    template(v-slot:item.winter_closed="{item}")
      boolean-chip(:value="item.asset_class_attributes.winter_closed")

    // Hydro::Reservoir values
    template(v-slot:item.reservoir="{item}")
      boolean-chip(:value="filterUnpersisted(item.asset_class_attributes.reservoirs_attributes).length > 0")
    template(v-slot:item.reservoir_name="{item}")
      | {{ filterUnpersisted(item.asset_class_attributes.reservoirs_attributes).map(i => i.name).join(", ") }}

    // Hydro::Intake values
    template(v-slot:item.trash_rack_cleaner="{item}")
      boolean-chip(:value="atLeastOneEnabled(item.asset_class_attributes.intakes_attributes, 'trash_rack_cleaner')")
    template(v-slot:item.intake_type="{item}")
      .text-capitalize {{ uniqueValues(item.asset_class_attributes.intakes_attributes, 'intake_type') }}

    // Hydro::Turbine values
    template(v-slot:item.turbine_count="{item}")
      span(v-if="item.asset_class_humanized == 'hydro'") {{ filterUnpersisted(item.asset_class_attributes.turbines_attributes).length }}
    template(v-slot:item.rated_power_mw="{item}")
      span(v-if="item.asset_class_humanized == 'hydro'") {{ sumOrBlank(item.asset_class_attributes.turbines_attributes, 'installed_effect_mw') }}
      span(v-else-if="item.asset_class_humanized == 'wind'") {{ sumOrBlank(item.asset_class_attributes.wind_turbines_attributes, 'installed_effect_mw') }}
      span(v-else-if="item.asset_class_humanized == 'solar'") {{ sumOrBlank(item.asset_class_attributes.solar_inverters_attributes, 'installed_power_kwp') / 1_000 }}
    template(v-slot:item.generator_capacity_mva="{item}")
      span(v-if="item.asset_class_humanized == 'hydro'") {{ sumOrBlank(item.asset_class_attributes.generators_attributes, 'installed_capacity_mva') }}
    template(v-slot:item.turbine_type="{item}")
      .text-capitalize {{ uniqueValues(item.asset_class_attributes.turbines_attributes, 'turbine_type') }}
    template(v-slot:item.turbine_level_masl="{item}")
      | {{ filterUnpersisted(item.asset_class_attributes.turbines_attributes).map(i => i.masl).join(", ") }}

    // Hydro::Generator values
    template(v-slot:item.effect_limitation="{item}")
      | {{ sumOrBlank(item.asset_class_attributes.generators_attributes, 'effect_limitation') }}



    // Solar::Inverters values
    template(v-slot:item.modules_count="{item}")
      span(if="item.asset_class_humanized == 'solar'") {{ sumOrBlank(item.asset_class_attributes.solar_inverters_attributes, 'modules_count', 0) }}

    template(v-slot:item.menu="{ item }")
      .nowrap
        v-btn(:href="`/power_plants/${item.id}`" x-small) {{ $t('general.show') }}
        v-btn(
          @click="editing_power_plant = item"
          x-small
          :id="`edit-plant-${item.id}`"
        ) {{ $t('general.edit') }}

  power-plant-form(v-if="editing_power_plant" v-model="editing_power_plant" @dialog-closed="editComplete")
</template>
