<script>
export default {
  props: {
    power_plants: Array,
    price_areas: Array,
  },
  data() {
    return {
      file: null,
      data_type: "",
      timezone: "UTC",
      timezones: ['UTC', 'CET'],
      field_values: {},
      power_plant_id: '',
      options_data_type: [
        { text: this.$t('csv_import.time_series.heading'), value: "production_period" },
        { text: this.$t('market_prices.heading'), value: "market_price" }
      ],
      options_volume_type: [
        { text: this.$t('csv_import.time_series.settled_volume'), value: "settled_volume" },
        { text: this.$t('csv_import.time_series.unsettled_volume'), value: "unsettled_volume" },
      ],
      options_currency: [
        { text: "NOK", value: "elspot_nok" },
        { text: "EUR", value: "elspot_eur" }
      ],
      selected_unit: null,
    };
  },
  computed: {
    productionPeriodSelected() { return this.data_type === 'production_period' },
    marketPriceSelected() { return this.data_type === 'market_price' },
    stringified_field_values() { return JSON.stringify(this.field_values) },
    validForm() {
      if (this.data_type === 'production_period') {
          return this.file && this.power_plant_id && this.field_values.volume_type_key;
      } else if (this.data_type === 'market_price') {
          return this.file && this.field_values.price_area && this.field_values.currency_key;
      }
      return false;
    }
  },
  methods: {
    submitForm() {
      event.preventDefault();
      this.$refs.form.submit();
    }
  }
}
</script>
<template lang="pug">
  div
    form(ref="form" method="post" action="/admin/import" enctype="multipart/form-data")
      input(type="hidden" value="<%= form_authenticity_token %>" name="authenticity_token")
      input(type="hidden" v-model="data_type" name="data_type")
      input(type="hidden" v-model="timezone" name="timezone")
      input(type="hidden" :value="stringified_field_values" name="field_values")
      v-card-title
        | {{ this.$t('csv_import.time_series.title') }}
      v-card-text
        v-file-input(
          name='file'
          append-icon='mdi-paperclip',
          prepend-icon=''
          :filled='true',
          :dense='true',
          v-model='file',
          :label="$t('csv_import.file_label')"
        )
        v-select(
          :dense='true',
          :filled='true',
          :items='timezones',
          :label="$t('csv_import.time_series.label_timezone')"
          v-model='timezone'
        )
        v-select(
          :dense='true',
          :filled='true',
          :items='options_data_type',
          :label="$t('csv_import.time_series.label_time_series')"
          v-model='data_type'
        )
        div(v-if='productionPeriodSelected')
          v-select(
            name='power_plant_id'
            :dense='true',
            :filled='true',
            :items='power_plants',
            :label="$t('csv_import.power_plant_id')"
            v-model='power_plant_id'
          )
          v-select(
            :dense='true',
            :filled='true',
            :items='options_volume_type',
            :label="$t('csv_import.time_series.label_volume_type')"
            v-model='field_values.volume_type_key'
          )
        div(v-if='marketPriceSelected')
          v-select(
            :dense='true',
            :filled='true',
            :items='price_areas',
            :label="$t('csv_import.time_series.label_price_area')"
            v-model='field_values.price_area'
          )
          v-select(
            :dense='true',
            :filled='true',
            :items='options_currency',
            :label="$t('csv_import.time_series.label_currency')"
            v-model='field_values.currency_key'
          )

        div
          p
            strong {{ $t('csv_import.time_series.description') }}
          pre
            |
            |Timestamp;Value;
            |2022-01-20 12:00;1.5;
            |2022-01-20 13:00;1.7;
          br
          p(v-html="$t('csv_import.time_series.formatting_warning')")

      v-card-actions
        v-btn(color='primary', type='submit' :disabled='!validForm') {{ this.$t('csv_import.import') }}
</template>
