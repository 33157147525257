<script>
export default {
  props: {
    responsible: Object,
    company_responsibles: Array,
    existing_responsibles: Array,
    users: Array,
    company_id: Number,
    value: Boolean
  },
  data() {
    return {
      responsibles: this.company_responsibles,
      dialog: false,
      create_new: this.existing_responsibles.length === 0,
      selected_responsibles: [],
      show_dialog: this.value,
      responsible_to_edit: this.responsible,
      errors: {}
    }
  },
  watch: {
    value(new_value) {
      this.show_dialog = new_value
    },
    responsible(new_value) {
      this.responsible_to_edit = new_value
    },
    show_dialog(new_value) {
      this.$emit("input", new_value)
    },
    responsibles(responsibles) {
      this.$emit("responsibles", responsibles)
    },
    'responsible_to_edit.email'(new_value) {
      if(!new_value)
        return

      const user = this.users.find(u => u.value === new_value)

      if(user == null)
        return

      this.responsible_to_edit.user = { phone: user.phone }
    }
  },
  computed: {
    existingResponsiblesItems() {
      return this.existing_responsibles.filter(responsible => !this.responsibles.find(r2 => r2.responsible.id === responsible.id)).map(responsible => {
        return {
          value: responsible.id,
          text: responsible.display_name
        }
      })
    }
  },
  methods: {
    closeEditDialog() {
      this.selected_responsibles = []
      this.show_dialog = false
    },
    async saveResponsible() {
      if(this.create_new || this.responsible_to_edit.id) {
        await this.createResponsible()
      }
      else {
        await this.setResponsible()
      }
    },
    async createResponsible() {
      let method = "post"
      let url = "/admin/responsibles"

      if(this.responsible_to_edit.id) {
        method = "patch"
        url = `/admin/responsibles/${this.responsible_to_edit.id}`
      }

      try {
        if(this.responsible_to_edit.user) {
          this.responsible_to_edit.user_attributes = { phone: this.responsible_to_edit.user.phone }
        }

        let result = await this.axios[method](url, this.responsible_to_edit)
        this.existing_responsibles.push(result.data)
        if(this.responsible_to_edit.id) {
          let idx = this.responsibles.findIndex(company_responsible => (company_responsible.responsible.id === result.data.id))
          this.responsibles[idx].responsible = result.data
          this.$emit('responsibles', this.responsibles)
          this.closeEditDialog()
        } else {
          this.selected_responsibles.push({text: result.data.display_name, value: result.data.id})
          this.create_new = false
          this.responsible_to_edit = { email: null }
        }
      } catch(error) {
        console.log(error)
        if(error?.response?.data)
          this.errors = error.response.data
      }
    },
    async setResponsible() {
      let url = `/admin/company_responsibles`

      const formData = new FormData()
      if(this.selected_responsibles.length === 0) {
        formData.append('company[company_responsibles_attributes][][responsible_id]', null)
      }
      else {
        this.selected_responsibles.forEach(responsibleId => {
          formData.append('company[company_responsibles_attributes][][responsible_id]', responsibleId.value)
        })
      }

      formData.append('company_id', this.company_id)

      try {
        let result = await this.axios.post(url, formData)
        this.responsibles = result.data
        this.closeEditDialog()
      } catch(error) {
        console.log(error.response)
        if(error?.response?.data)
          this.errors = error.response.data
      }
    },
    label(field) {
      // We have to change to singular form here, as i18n uses singular but error messages uses plural
      if(field.includes('responsibles'))
        return this.$t(`activerecord.attributes.company_responsible.responsible`)
      return this.$t(`activerecord.attributes.responsible.${field.replace('responsibles.', '')}`)
    },
    sharedProps(field) {
      return {
        filled: '',
        label: this.label(field),
        name: `responsible[${field}]`,
        id: `responsible_${field}`,
        errorMessages: this.errors[field],
        readonly: false
      }
    },
  }
}
</script>

<template lang="pug">
v-dialog(v-model="show_dialog" max-width="500px")
  v-card(v-if="responsible_to_edit")
    v-card-title.headline.gray.lighten-2
      div(v-if="responsible_to_edit.id")
        | {{ $t('responsible.edit') }}
      div(v-else)
        | {{ $t('responsible.add') }}

    v-card-text
      v-checkbox(v-if="!responsible_to_edit.id && existing_responsibles.length !== 0" v-model="create_new" :label="$t('responsible.create_new')")
      div(v-if="create_new || responsible_to_edit.id")
        v-checkbox(v-model="responsible_to_edit.has_user" :label="$t('responsible.link_to_user')")
        div(v-if="responsible_to_edit.has_user")
          v-autocomplete(v-model="responsible_to_edit.email" v-bind="sharedProps('email')" :items="users")
          v-text-field(v-if="responsible_to_edit.user" v-model="responsible_to_edit.user.phone" v-bind="sharedProps('phone')")
        div(v-else)
          v-text-field(v-model="responsible_to_edit.email" v-bind="sharedProps('email')")
          v-text-field(v-model="responsible_to_edit.name" v-bind="sharedProps('name')")
          v-text-field(v-model="responsible_to_edit.phone" v-bind="sharedProps('phone')")
      div(v-else)
        v-combobox(v-model="selected_responsibles" v-bind="sharedProps('responsibles')" :items="existingResponsiblesItems" multiple)

    v-card-actions
      v-btn(@click="saveResponsible()" color="primary")
        | {{ $t('general.save') }}
      v-spacer
      v-btn(@click="closeEditDialog()")
        | {{ $t('general.cancel') }}
</template>
