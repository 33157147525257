<script>
import ByStatusByMonthChart from './charts/by_status_by_month.vue'
import ByComponentChart from './charts/items_by_component.vue'
import TotalCostByMonthChart from './charts/total_cost_by_month.vue'
import StatisticsTable from './table.vue'
import StatusChart from './charts/status.vue'
import humanizeNumberMixin from '../../../mixins/humanize_number_mixin.js'

export default {
  components: {
    ByStatusByMonthChart,
    TotalCostByMonthChart,
    ByComponentChart,
    StatisticsTable,
    StatusChart,
  },
  mixins: [humanizeNumberMixin],
  props: {
    work_items: Array,
    work_items_aggregated_by_status_month: Array,
    work_items_percentage_by_component: Array,
    total_cost_by_month: Array,
    sum_total_cost: Number,
    average_cost_per_work_item: Number,
    average_work_item_open_days: Number,
    labels: Array,
    components: Array,
    power_plants: Array,
    users: Array,
    allow_label_edit: Boolean,
    currency_code: String,
  },
  data() {
    return {
      selectedPowerplant: '',
      selectedAreaFrom: null,
      selectedAreaTo: null,
      zoomFrom: null,
      zoomTo: null,
    }
  },
  computed: {
    power_plants_count() {
      return new Set(this.work_items.map(w => (w.power_plant))).size
    },
    closed_tasks_count() {
      return this.work_items.filter(item => item.status === 'closed').length;
    },
    sum_total_closed_cost() {
      return this.work_items.filter(item => item.status === 'closed').reduce((total, item) => {
        return total + (parseFloat(item.budget_cost) || 0);
      }, 0);
    },
    sum_total_open_cost() {
      return this.work_items.filter(item => item.status === 'open').reduce((total, item) => {
        return total + (parseFloat(item.budget_cost) || 0);
      }, 0);
    },
    sum_total_in_progress_cost() {
      return this.work_items.filter(item => item.status === 'in_progress').reduce((total, item) => {
        return total + (parseFloat(item.budget_cost) || 0);
      }, 0);
    }
  }
}
</script>

<template lang="pug">
v-container#work_items
  v-row
    v-col(cols="12" sm="4")
      v-card
        ByStatusByMonthChart(:series="work_items_aggregated_by_status_month")

    v-col(cols="12" sm="4")
      v-card
        TotalCostByMonthChart(:series="total_cost_by_month" :currency_code="currency_code")

    v-col(cols="12" sm="4")
      v-card
        ByComponentChart(:work_items_percentage_by_component="work_items_percentage_by_component")
  v-row
    v-col(cols="12")
      v-card
        v-card-text
          v-row
            v-col.text-center(cols="8" sm="8")
              v-row.pt-10
                v-col(cols="12" sm="4")
                  h1 {{ `${average_work_item_open_days} ${this.$t('general.days')}` }}
                  | {{ this.$t('work_items.average_days_to_close_work_item') }}
                v-col(cols="12" sm="4")
                  h1 {{ `${ this.closed_tasks_count }` }}
                  | {{ this.$t('work_items.closed_tasks') }}
                v-col(cols="12" sm="4")
                  h1 {{ `${this.humanizeNumber(this.average_cost_per_work_item.toFixed(0))} ${currency_code}` }}
                  | {{ this.$t('work_items.average_cost_per_closed_work_item') }}
              v-row.pt-10.pb-10
                v-col(cols="12" sm="4")
                  h1 {{ `${ this.humanizeNumber(this.sum_total_open_cost.toFixed(0))} ${currency_code}` }}
                  | {{ this.$t('work_items.total_open_cost') }}
                v-col(cols="12" sm="4")
                  h1 {{ `${ this.humanizeNumber(this.sum_total_in_progress_cost.toFixed(0))} ${currency_code}` }}
                  | {{ this.$t('work_items.total_in_progress_cost') }}
                v-col(cols="12" sm="4")
                  h1 {{ `${ this.humanizeNumber(this.sum_total_closed_cost.toFixed(0))} ${currency_code}` }}
                  | {{ this.$t('work_items.total_closed_cost') }}

            v-col(cols="4" sm="4")
              StatusChart(:work_items="work_items")
  v-row
    v-col(cols="12")
      v-card
        StatisticsTable(:work_items="work_items", :power_plants="power_plants" :labels="labels", :components="components", :users="users", :allow_label_edit="allow_label_edit")
</template>
