<script>
import {chunk} from 'lodash'

export default {
  props: {
    selectedPeriodRange: {
      type: String,
      default: 'year',
    },
    minYear: {
      type: Number,
      required: true,
    },
    maxYear: {
      type: Number,
      required: true,
    },
    current: String,
    hideYear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      monthWasSelected: false,
      isMonthSelected: null,
      readyForConfirmation: false,
      isMonthDropdownOpen: false,
      isYearDropdownOpen: false,
      selectedYear: new Date().getFullYear(),
      year_format: '%Y',
      month_year_format: '%b %Y',
      current_date: null,
      years: [],
      months: Array.from({ length: 12 }, (_, i) => i + 1),
    }
  },
  computed: {
    chunkedMonths() {
      return chunk(this.months, 6)
    },

    format() {
      return this.selectedPeriodRange === 'year' ? this.year_format : this.month_year_format
    },

    displayedYear() {
      return this.selectedYear
    },

    yearsForDropdown() {
      const startYear = Math.max(this.selectedYear - 2, this.minYear)
      const endYear = Math.min(this.selectedYear + 2, this.maxYear)
      return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)
    }
  },
  methods: {
    formatDate(date, format) {
      if (date) {
        const year = date.getFullYear()
        const monthIndex = date.getMonth()
        const monthName = this.$t('date.abbr_month_names')[monthIndex + 1]

        if (format === '%b %Y') {
          return `${monthName} ${year}`
        } else if (format === '%Y') {
          return `${year}`
        }
      }
    },
    createPeriodUrl(date, powerPlantId) {
      const formattedDate = this.selectedPeriodRange === 'year' ?
        `y-${date.getFullYear()}` :
        `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01`

      let url = `/downtime_periods?page=1&period=${formattedDate}`
      if (powerPlantId) {
        url += `&power_plant_id=${powerPlantId}`
      }
      return url
    },

    navigateToYear(year) {
      this.selectedYear = year
      this.checkReadyForConfirmation()
    },

    getPowerPlantId() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('power_plant_id')
    },
    formatMonth(month) {
      return this.$t('date.abbr_month_names')[month]
    },
    getYearUrl(year) {
      const powerPlantId = this.getPowerPlantId()
      let url = `/downtime_periods?page=1&period=y-${year}`
      if (powerPlantId) {
        url += `&power_plant_id=${powerPlantId}`
      }
      return url
    },
    getMonthUrl(month) {
      if (this.selectedYear) {
        const formattedMonth = month.toString().padStart(2, '0')
        const powerPlantId = this.getPowerPlantId()
        let url = `/downtime_periods?page=1&period=${this.selectedYear}-${formattedMonth}-01`
        if (powerPlantId) {
          url += `&power_plant_id=${powerPlantId}`
        }
        return url
      }
    },
    getClassForMonth(month) {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth() + 1
      const isSelectedMonth = this.monthWasSelected && this.isMonthSelected === month
      const isCurrentMonth = !this.monthWasSelected && !this.isYearOnlySelected && this.current_date && this.current_date.getMonth() + 1 === month
      let classes = isSelectedMonth || isCurrentMonth ? 'selected-month-class light-blue' : ''
      let isClickable = true
      let styles = ''

      if (this.selectedYear === currentYear && month > currentMonth) {
        styles = 'color: #c7c7c7;'
        isClickable = false
      }
      return { classes, styles, isClickable }
    },

    selectYear(year) {
      this.selectedYear = year
      this.checkReadyForConfirmation()
    },

    closeDropdowns() {
      this.isMonthDropdownOpen = false
      this.isYearDropdownOpen = false
    },

    checkReadyForConfirmation() {
      this.readyForConfirmation = this.selectedYear !== null || this.isMonthSelected !== null
    },

    selectMonth(month, event) {
      event.stopPropagation()
      this.isMonthSelected = month
      this.monthWasSelected = true
      this.isMonthDropdownOpen = true
      this.checkReadyForConfirmation()
      this.$forceUpdate()
    },
    confirmSelection() {
      let url
      if (this.selectedYear && this.isMonthSelected) {
        url = this.getMonthUrl(this.isMonthSelected)
      } else if (this.selectedYear) {
        url = this.getYearUrl(this.selectedYear)
      } else if (this.isMonthSelected) {
        url = this.getMonthUrl(this.isMonthSelected, this.selectedYear || new Date().getFullYear())
      }
      if (url && this.readyForConfirmation) {
        window.location.href = url
      }
      this.closeDropdowns()
    },

    navigate(direction, type) {
      if (!this.current_date) {
        return
      }

      const powerPlantId = this.getPowerPlantId()
      const newDate = new Date(this.current_date)

      if (type === 'year') {
        newDate.setFullYear(newDate.getFullYear() + (direction === 'forward' ? 1 : -1))
      } else if (type === 'month') {
        newDate.setMonth(newDate.getMonth() + (direction === 'forward' ? 1 : -1))
      }

      return this.createPeriodUrl(newDate, powerPlantId)
    }
  },
  mounted() {
    this.current_date = new Date(this.current) || new Date()
    const currentYear = new Date().getFullYear()
    this.years = Array.from({ length: 10 }, (_, i) => currentYear - i).reverse()
    this.isYearDropdownOpen = false

    const urlParams = new URLSearchParams(window.location.search)
    const periodFromUrl = urlParams.get('period')

    if (periodFromUrl) {
      if (periodFromUrl.startsWith('y-')) {
        this.isYearOnlySelected = true
        this.selectedYear = parseInt(periodFromUrl.substring(2), 10)
      } else {
        this.isYearOnlySelected = false
        this.current_date = new Date(periodFromUrl)
      }
    } else {
      this.selectedYear = currentYear
      this.isYearOnlySelected = true
    }
  },
  watch: {
    current_date(newDate) {
      this.selectedYear = newDate.getFullYear()
    }
  },
}
</script>

<template lang="pug">
#navigation
  v-menu
    template(v-slot:activator="{ on }")
      a(:href="navigate('backward', selectedPeriodRange)", class="nostyle")
        v-chip(pointer, class="ml-2 my-2", color="light-blue", text-color="white")
          v-icon mdi-arrow-left-bold
      a(:href="navigate('forward', selectedPeriodRange)", class="nostyle")
        v-chip(pointer, class="my-2", color="light-blue", text-color="white")
          v-icon mdi-arrow-right-bold
      a(v-on="on")
        v-chip(pointer, class="my-2 mx-1", color="light-blue", text-color="white")
          | {{ formatDate(current_date, format) }}

    v-col(style="background-color: white;")
      v-row
        v-col.text-center
          v-chip(pointer, class="ml-2 my-2", color="light-blue", text-color="white", @click.stop="navigateToYear(displayedYear - 1)", :disabled="displayedYear <= minYear")
            v-icon mdi-arrow-left-bold

        v-col.year-item
          v-menu(
            v-model="isYearDropdownOpen"
            :close-on-content-click="true"
            offset-y
          )
            template(v-slot:activator="{ on, attrs }")
              v-list-item(
                v-bind="attrs"
                v-on="on"
                class="v-list-item--active light-blue white--text rounded-xl"
                @click="isYearDropdownOpen = !isYearDropdownOpen"
              )
                v-list-item-title.text-center {{ displayedYear }}
            v-list
              v-list-item(
                v-for="year in yearsForDropdown"
                :key="year"
                @click="selectYear(year)"
                :class="{'light-blue': year === selectedYear, 'text-center': true}"
              )
                v-list-item-title {{ year }}

        v-col.text-center
          v-chip(pointer, class="my-2", color="light-blue", text-color="white", @click.stop="navigateToYear(displayedYear + 1)", :disabled="displayedYear >= maxYear")
            v-icon mdi-arrow-right-bold


      v-row(v-for="(monthChunk, monthIndex) in chunkedMonths", :key="monthIndex")
        v-col(class="month-item", v-for="month_num in monthChunk", :key="`month-${month_num}`")
          v-list-item(:class="getClassForMonth(month_num).classes", :style="getClassForMonth(month_num).styles", @click="getClassForMonth(month_num).isClickable && selectMonth(month_num, $event)")
            v-list-item-title(class="text-right") {{ formatMonth(month_num) }}

      v-row
        v-col.text-center
          v-btn(@click="confirmSelection", :disabled="!readyForConfirmation")
            | OK
</template>
