<script>
import HumanizeNumberMixin from '../../mixins/humanize_number_mixin.js'
export default {
  props: {
    fall_lease_landowners: Array,
  },
  mixins: [HumanizeNumberMixin],
  data() {
    return {
      expand: false
    }
  },
}
</script>

<template lang="pug">
.expandable-info.mt-2
  v-btn(@click="expand = !expand" :color="expand ? 'primary' : 'white'" small) {{ this.$t('fall_lease.counterpart_fields.show_landowner_info') }}

  v-expand-transition
    .expandable-content(v-if="expand")
      v-row.px-3.pt-12.pb-5 {{ this.$t('fall_lease.counterpart_fields.informational_text_test') }}
        br
        | {{ this.$t('fall_lease.counterpart_fields.informational_text_accuracy') }}
      v-row
        v-col.px-3.py-1
          b {{ this.$t('fall_lease.counterpart_fields.landowner') }}
        v-col.pl-3.pr-13.py-1(style="text-align: right")
          b {{ this.$t('fall_lease.counterpart_fields.fall_lease') }}
      v-row(v-for="landowner in fall_lease_landowners" :key="landowner.id")
        v-col.px-3.py-1 {{ `${landowner.name} (${landowner.share_percentage}%)` }}
        v-col.pl-3.pr-13.py-1(style="text-align: right") {{ `${humanizeNumber(landowner.share_cost.toFixed(0))} ${landowner.currency_code}` }}

</template>