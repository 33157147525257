<script>

export default {
  props: {
    power_plant_id: String,
  },
  data() {
    return {
      loading: false,
      response_code: null,
      response_message: null,
    }
  },
  computed: {
  },
  methods: {
    async click() {
      this.loading = true

      const response = await this.axios['patch'](window.location.href)
      const result = response.data

      if(result.response_code == 200) {
        window.location.reload()
      } else {
        alert(`Error executing Production Simulation Plan query.\nCode: ${result.response_code}.\nMessage: ${result.response_message}\nBacktrace: ${result.backtrace}`)
      }

      this.loading = false
    }
  },
}
</script>

<template lang="pug">
div
  v-btn(class="ma-2 v-chip white--text text-none" color="light-blue" :loading="loading" :disabled="loading" @click="click")
    | {{ this.$t('navigation.production_simulation_plan_query') }}
</template>

