<script>
import humanizeNumberMixin from '../../../../mixins/humanize_number_mixin.js'

export default {
  props: {
    series: Array,
    currency_code: String,
  },
  data() {
    return {}
  },
  computed: {
    chart_options() {
      const categories = this.series[0].data.map(item => item.date)

      const currencyCode = this.currency_code

      const highchartsSeries = this.series.map(serie => ({
        type: serie.type,
        name: serie.name,
        visible: serie.visible,
        color: serie.color,
        data: serie.data.map(item => item.value)
      }))

      return {
        chart: {
          type: 'column'
        },
        title: {
          text: this.$t('work_items.work_item_total_cost_by_month')
        },
        yAxis: {
          title: {
            text: this.$t('work_items.total_cost_by_month')
          }
        },
        tooltip: {
          pointFormat: `{series.name}: {point.y} ${currencyCode}`,
          formatter() {
            return `${this.series.name}: ${humanizeNumberMixin.methods.humanizeNumber(this.point.y.toFixed(0))} ${currencyCode}`
          }
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          },
          line: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: true
          }
        },
        xAxis: {
          categories: categories,
          crosshair: true
        },
        credits: {
          enabled: false
        },
        series: highchartsSeries
      }
    }
  }
}
</script>

<template lang="pug">
div
  v-highcharts(:options="chart_options")
</template>
