<script>
import { debounce } from '../../lib/debounce.js'
import {chunk} from 'lodash'

const loadDelay = process.env.NODE_ENV === "test" ? 10 : 300

export default {
  data() {
    const searchParams = new URLSearchParams(window.location.search)
    return {
      groupsData: [],
      query: searchParams.get('query'),
      groupPage: {}
    }
  },
  async mounted() {
    await this.submitQuery()
  },
  methods: {
    async submitQuery() {
      const query = this.query ? this.query : ""

      var result = await this.axios.get(`/admin/responsibles.json?query=${query}`)
      this.groupsData = result.data
      this.groupsData.forEach(g => {
        this.groupPage[g.group_name] = 1
      })
    },
    editCompany(filterField, value) {
      let filter = ""
      if(filterField)
        filter = filterField + `=${value.name}`

      window.location=`/admin/responsibles/${value.company_id}?${filter}`
    },
    setPage(groupName, page) {
      this.$set(this.groupPage, groupName, page)
      // Vue is not detecting the change above, so we have to force update
      this.$forceUpdate()
    }
  },
  computed: {
    pagedGroups() {
      const pagedGroups = []

      this.groupsData.forEach(group => {
        pagedGroups.push({group_name: group.group_name, filter_field: group.filter_field, values: chunk(group.values, 20)})
      });
 
      return pagedGroups
    }
  },
  watch: {
    query: debounce(async function (query) {
      this.submitQuery()

      const url = new URL(window.location)
      url.searchParams.set('query', query || '')
      window.history.pushState(null, '', url)
    }, loadDelay)
  }
}
</script>

<template lang="pug">
div
  .d-flex.d-flex-row.mb-3
    v-text-field.ma-0.pa-0.mr-3(v-model="query" :placeholder="$t('general.search_by_name')" hide-details='auto' clearable=true @keydown.enter="submitQuery()")
    v-btn.align-self-end(@click="submitQuery()" small)
      | {{ $t('general.search') }}

  div(v-if="groupsData.length === 0")
    h3
      | No results...

  div(v-for="group in pagedGroups")
    h3.mt-4 {{ group.group_name }}

    v-simple-table.elevation-4
      template(v-slot:default)
        thead
          tr
            th Name
            th
        tbody
          tr(v-for="value in group.values[groupPage[group.group_name] - 1]" style="cursor: pointer;" @click="editCompany(group.filter_field, value)")
            td
              h3
                | {{ value.name }}
            td.text-right
              v-btn.elevation-1(@click="editCompany(group.filter_field, value)" fab x-small)
                v-icon
                  | mdi-pencil
    v-pagination.mt-2(v-if="group.values.length > 1" :value="groupPage[group.group_name]" @input="v => setPage(group.group_name, v)" :length="group.values.length")
</template>
