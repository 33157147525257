<script>
import FeatureRequestWidget from './request_widget.vue'

export default {
  components: {
    FeatureRequestWidget
  },
  props: {
    requests: Array,
  },
  data() {
    return {
      headers: [
        { value: "created_at", text: this.$t('activerecord.attributes.feature_request.date'), sort: (a, b) => b - a },
        { value: "title", text: this.$t('activerecord.attributes.feature_request.title') },
        { value: "upvotes", text: this.$t('activerecord.attributes.feature_request.upvotes'), align: "center", sort: (a, b) => b - a },
        { value: "category", text: this.$t('activerecord.attributes.feature_request.category') },
        { value: "status", text: this.$t('activerecord.attributes.feature_request.status') },
        { value: "author", text: this.$t('activerecord.attributes.feature_request.author'), sort: (a, b) => a.short_name.localeCompare(b.short_name) },
        { value: "show_edit", sortable: false},
      ],
      show_translation: this.$t('general.show'),
      edit_translation: this.$t('general.edit'),
      delete_translation: this.$t('general.delete'),
    }
  },
  methods: {
    editUrl(request) {
      return `/feature_requests/${request.id}/edit`
    },
    destroyUrl(request) {
      return `/feature_requests/${request.id}`
    },
    votesChanged(request, amount, has_user_upvoted) {
      const request_in_table = this.requests.find(f => f.id === request.id)
      request_in_table.upvotes = amount
      request_in_table.has_user_upvoted = has_user_upvoted
    }
  }
}
</script>

<template lang="pug">
  v-data-table(:headers="headers" :items="requests")
    template(v-slot:item.author="{ item }")
      | {{ item.author.short_name }}
    template(v-slot:item.created_at="{ item }")
      | {{ new Date(item.created_at * 1000).toLocaleString("nb-NO", {day: 'numeric', month: 'short', year: 'numeric' }) }}
    template(v-slot:item.status="{ item }")
      v-chip(:color="item.status_color" :dark="item.status_color != 'yellow'") {{ item.status }}
    template(v-slot:item.show_edit="{ item }")
      div(class="text-right text-no-wrap")
        div(class="d-inline-block")
          feature-request-widget(:request="item" v-on:votesChanged="votesChanged")
            v-btn(color="primary" :title="show_translation" x-small fab)
              v-icon mdi-eye
        | &nbsp
        a(v-if="item.can_current_user_edit" :href="editUrl(item)" class="nostyle")
          v-btn(color="primary" :title="edit_translation" x-small fab)
            v-icon mdi-pencil
        | &nbsp
        a(v-if="item.can_current_user_destroy" :href="destroyUrl(item)" data-method="delete" rel="nofollow" data-confirm="Are you sure?" class="nostyle")
          v-btn(:title="delete_translation" x-small fab)
              v-icon mdi-delete

</template>
