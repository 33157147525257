<script>
export default {
  props: {
    value: Object
  },
  data() {
    return {
      copiedSnackbar: false
    }
  },
  methods: {
    async copy(value) {
      await navigator.clipboard.writeText(value)
      this.copiedSnackbar = true
    }
  }
}
</script>

<template lang="pug">
span(v-if="value !== null && value !== ''")
  | {{value.display_name}}
  v-tooltip(v-if="value.email != '' && value.email != null" bottom)
    template(v-slot:activator="{on, attrs}")
      v-icon.pl-2(v-bind="attrs" v-on="on" @click="copy(value.email)" small) mdi-email
    | {{value.email}}
  v-tooltip(v-if="value.phone != '' && value.phone != null" bottom)
    template(v-slot:activator="{on, attrs}")
      v-icon(v-bind="attrs" v-on="on" @click="copy(value.phone)" small) mdi-phone
    | {{value.phone}}

  v-snackbar(v-model="copiedSnackbar")
    | {{ $t('general.copied') }}
</template>
