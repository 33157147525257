<script>
export default {
  props: {
    work_items: Array
  },
  computed: {
    categories() {
      return {
        open: this.$t('work_items.status.open'),
        in_progress: this.$t('work_items.status.in_progress'),
        closed: this.$t('work_items.status.closed')
      }
    },
    serie_data() { return Object.keys(this.categories).map(status => this.work_items.filter(work_item => work_item.status === status).length); },
    chart_options() {
      return {
        chart: {
          height: 216
        },
        title: {
          text: this.$t('work_items.project_status')
        },
        credits: {
          enabled: false
        },
        yAxis: {
          title: {text: this.$t('work_items.number_of_projects') }
        },
        xAxis: {
          categories: Object.values(this.categories)
        },
        series: [{
          type: 'column',
          data: this.serie_data,
          showInLegend: false
        }]
      }
    }
  }
}
</script>

<template lang="pug">
div
  v-highcharts(:options="chart_options")
</template>
