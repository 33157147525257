<script>
export default {
  props: {
    value: Object,
    form_data: Object,
    errors: Object
  },
  data() {
    return {
      wind_asset: this.value
    }
  },
  computed: {
    writable() {
      return this.form_data.permitted_attributes.find((a) => a.asset_class_attributes).asset_class_attributes
    }
  },
  methods: {
    sharedProps(field) {
      return {
        name: `power_plant[asset_class_attributes][${field}]`,
        id: `power_plant_asset_class_attributes_${field}`,
        errorMessages: this.errors[`asset_class.${field}`],
        readonly: false,
        label: this.label(field),
        dense: true,
        outlined: true,
        persistentHint: true,
        hideDetails: 'auto',
        class: `mb-2 ${this.writable.includes(field) ? '' : 'd-none'}`
      }
    },
    label(field) {
      return this.$t(`activerecord.attributes.wind/asset.${field}`)
    }
  }
}
</script>
<template lang="pug">
#wind-asset
  v-text-field(v-model="wind_asset.substation_lat" v-bind="sharedProps('substation_lat')")
  v-text-field(v-model="wind_asset.substation_lng" v-bind="sharedProps('substation_lng')")
  v-text-field(v-model="wind_asset.substation_masl" v-bind="sharedProps('substation_masl')")
  v-text-field(v-model="wind_asset.full_operating_hours" v-bind="sharedProps('full_operating_hours')")
  v-text-field(v-model="wind_asset.avg_yearly_wind_speed" v-bind="sharedProps('avg_yearly_wind_speed')" suffix="m/s")
  v-text-field(v-model="wind_asset.activity" v-bind="sharedProps('activity')")
  v-text-field(v-model="wind_asset.recycle_share_percent" v-bind="sharedProps('recycle_share_percent')" suffix="%" type="number" step=".1")
</template>
