<script>
export default {
  props: {
    buy_back_cost_annual: Array,
    buy_back_cost_quarterly: Array,
    buy_back_cost_monthly: Array,
    buy_back_cost_pay_as_produced: Array,
  },
  methods: {
    chart_options() {
      return {
        title: { text: this.$t('ppa_pricing.charts.buy_back_cost_title') },
        credits: {
          enabled: false
        },
        xAxis: { 
          opposite: true, 
          title: { text: "Hedging ratio" },
          labels: {
            formatter: function() {
              return this.value + '%'
            }
          },
        },
        yAxis: [
          {
            title: { text: 'EUR' }
          },
        ],
        plotOptions: {
          series: {
            marker: {
              radius: 1
            }
          }
        },
        series: [
          {
            name: 'Baseload annual',
            type: 'line',
            data: this.buy_back_cost_annual,
            yAxis: 0,
            color: 'darkblue',
            tooltip: {
              pointFormat: '{series.name} {point.y} EUR',
              valueDecimals: 0,
            },
          },
          {
            name: 'Baseload quarterly',
            type: 'line',
            data: this.buy_back_cost_quarterly,
            yAxis: 0,
            color: 'blue',
            tooltip: {
              pointFormat: '{series.name} {point.y} EUR',
              valueDecimals: 0,
            },
          },
          {
            name: 'Baseload montly',
            type: 'line',
            data: this.buy_back_cost_monthly,
            yAxis: 0,
            color: 'lightblue',
            tooltip: {
              pointFormat: '{series.name} {point.y} EUR',
              valueDecimals: 0,
            },
          },
          {
            name: 'Pay-as-produced',
            type: 'line',
            data: this.buy_back_cost_pay_as_produced,
            yAxis: 0,
            color: 'red',
            tooltip: {
              pointFormat: '{series.name} {point.y} EUR',
              valueDecimals: 0,
            },
          },
        ]
      }
    }
  }
}
</script>

<template lang="pug">
v-highcharts(:options="chart_options()")
</template>
