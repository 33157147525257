<script>
import PowerPlantForm from './form/index.vue'

export default {
  components: {
    PowerPlantForm,
  },
  props: {
    id: Number,
    image_url: String,
  },
  data () {
    return {
      edit: false,
      fake_model: { id: this.id, image_url: this.image_url },
    }
  },
  methods: {
    reload () {
      window.location.reload()
    },
  },
}
</script>

<template lang="pug">
span
  v-btn(@click="edit = true" x-small) Edit
  power-plant-form(v-if="edit" v-model="fake_model" @dialog-closed="$event ? reload() : edit = false")
</template>
