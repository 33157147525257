<script>
export default {
    name: 'PortalPlantSelectors',

    props: {
        plants: {
            type: [Array],
            required: true
        },
        default_plant_id: {
            type: Number,
            required: false
        }
    },

    mounted() {
        const plant_id = this.default_plant_id || this.plant_ids[0]
        this.handle_plant_click(null, plant_id)
    },

    data() {
        return {
            selected_plants: []
        }
    },

    computed: {
        plant_ids() {
            return this.plants.map(plant => plant.id)
        }
    },

    methods: {
        handle_plant_click(event, plant_id) {
            if (event && (event.metaKey || event.ctrlKey)) {
                const new_selected_plants = this.selected_plants.includes(plant_id)
                    ? this.selected_plants.filter(r => r !== plant_id)
                    : [...this.selected_plants, plant_id]
                this.selected_plants = new_selected_plants
            }
            else {
                const new_selected_plants = [plant_id]
                this.selected_plants = new_selected_plants
            }
            this.$emit("selected-plants-change", this.selected_plants)
        }
    },
}
</script>

<template lang="pug">

div(style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; gap: 0.5rem; width: 90%; margin: auto; padding-top: 1rem; padding-bottom: 1rem;")
    div(v-for="plant in plants", :key="plant.id", @click="handle_plant_click($event, plant.id)", style="width: 10rem; height: 3rem; border: 0.1rem solid rgba(135, 181, 231, 1.0); border-radius: 0.75rem; display: flex; justify-content: center; align-items: center; cursor: pointer; box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);", :style="{ 'background-color': selected_plants.includes(plant.id) ? 'rgba(135, 181, 231, 1.0)' : 'white' }")
        div(style="user-select: none;", :style="{ 'color': selected_plants.includes(plant.id) ? 'white' : 'rgba(135, 181, 231, 1.0)' }", :title="plant.name")
            | {{ plant.name.length < 15 ? plant.name : `${plant.name.slice(0, 15)}...` }}

</template>
