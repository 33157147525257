<script>
export default {
  props: {
    series: Array,
    title: String,
    units: Array,
  },
  data() {
    return {
    }
  },
  computed: {
    left_y_unit () {
      if(this.left_divider == 1) {
        return this.units[0]
      } else if(this.left_divider == 1000) {
        return this.units[1]
      } else {
        return this.units[2]
      }
    },
    left_divider () {
      if(this.left_max > 1000000) {
        return 1000000
      } else if(this.left_max > 1000) {
        return 1000
      } else {
        return 1
      }
    },
    left_max () {
      const merged = this.series.filter(s => s.yAxis == 0).map(s => s.data).flat()
      return Math.max(...merged)
    },

    chart_options () {
      return {
        chart: {
          type: 'column'
        },
        title: {
          text: this.title
        },
        xAxis: {
          categories: this.$t("date.abbr_month_names").slice(1),
          crosshair: true,
        },
        yAxis: [{
          min: 0,
          title: {
            text: null
          },
          labels: {
            format: `${this.left_y_unit},${this.left_divider}`,
            formatter() {
              const format = this.axis.defaultLabelFormatter.call(this).split(",")
              const unit = format[0]
              const divider = Number(format[1])
              return `${this.value/divider}${unit}`
            }
          },
        }, {
          min: 0,
          title: {
            text: null
          },
          labels: {
            format: `${this.right_y_unit},${this.right_divider}`,
            formatter() {
              const format = this.axis.defaultLabelFormatter.call(this).split(",")
              const unit = format[0]
              const divider = Number(format[1])
              return `${this.value/divider}${unit}`
            }
          },
          opposite: true
        }],
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              "<td style='padding:0'><b>{point.y} " + this.units[0] + "</b></td></tr>",
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          series: {
            animation: false
          },
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        credits: {
          enabled: false
        },
        series: this.series
      }
    }
  }
}
</script>

<template lang="pug">
  v-highcharts(:options="chart_options")
</template>
