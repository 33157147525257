<template>
    <vhighcharts :options="chart_data" />
</template>

<script>

import vhighcharts from '../../v_highcharts.vue';

const chart_options = (series_data, is_positive) => {
    return {
        name: "PortalMetricChart",
        series: [{
            data: series_data,
        }],
        chart: {
            backgroundColor: null,
            borderWidth: 0,
            animation: false,
            type: 'areaspline',
            // height: "100%",
            // width: "100%",
            margin: [0, 0, 0, 0],
            style: {
                overflow: 'visible'
            },
            skipClone: true
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            visible: false,
            gridLineWidth: 0,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            // categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            startOnTick: false,
            lineWidth: 0,
            lineColor: 'transparent',
            endOnTick: false,
            minorTickLength: 0,
            tickLength: 0,
            tickPositions: []
        },
        yAxis: {
            gridLineWidth: 0,
            endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false
            },
            title: {
                text: null
            },
            tickPositions: [0]
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            areaspline: {
                animation: false,
                enableMouseTracking: false,
                zIndex: 1,
                fillOpacity: 1,
                shadow: false,
                lineWidth: 3,
                color: is_positive ? '#7CB5EC' : '#FFA500', // Remove the line for this areaspline series
                zoneAxis: 'y',
                marker: {
                    enabled: false,
                },
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: false
                    }
                },
                threshold: Math.min(...series_data) - 0.5,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, is_positive ? 'rgba(124, 181, 236, 0.2)' : 'rgba(255, 165, 0, 0.2)'],
                        [0.95, 'rgba(255, 255, 255, 1)']
                    ]
                }
            },
        }
    }
}

export default {
    props: {
        series_data: Array,
        is_positive: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    components: {
        vhighcharts,
    },
    computed: {
        chart_data() {
            const options = chart_options(this.series_data, this.is_positive);
            // const chart_data = { series: this.series, ...options };
            return options;
        }
    },
}

</script>